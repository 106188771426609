import React from 'react'

import Control from '../Control'

export default React.forwardRef(
  ({ label, getValidationError, ...props }, ref) => {
    const error = getValidationError && getValidationError(props.name)

    return (
      <Control label={label}>
        <div className={`select is-fullwidth ${error ? 'is-danger' : ''}`}>
          <select {...props} ref={ref}></select>
        </div>
        {error && <p className='help is-danger'>{error}</p>}
      </Control>
    )
  }
)
