import React from 'react'
import { useNavigate } from 'react-router-dom'

import { useApi, useError, useApiRequest, useForm } from '../../hooks'
import TextField from '../Form/TextField'
import Button from '../Button'
import Columns from '../Columns'
import ContextMenu from '../ContextMenu'
import Spinner from '../Spinner'

export default function ({ organizationId }) {
  const navigate = useNavigate()
  const [client] = useApi()
  const [organization] = useApiRequest(`admin/organizations/${organizationId}`)
  const setError = useError()

  const [getFieldProps, formData, formError] = useForm()

  if (!organization) {
    return <Spinner />
  }

  async function handleSubmit(e) {
    e.preventDefault()

    let { name } = formData

    try {
      const userRole = await client(
        `admin/organizations/${organizationId}/user-roles/create`,
        {
          name
        }
      )

      navigate(`/user-roles/${userRole.id}?organizationId=${organization.id}`)
    } catch (err) {
      if (err.message === 'validation_error') {
        formError(err.props)
      } else {
        setError(err)
      }
    }
  }

  return (
    <div className='box'>
      <form onSubmit={handleSubmit}>
        <Columns>
          <TextField
            label='Organization'
            value={organization.name}
            readOnly={true}
          />
          <TextField
            label='Role name'
            name='name'
            type='Name'
            placeholder='Name'
            required={true}
            {...getFieldProps()}
          />
        </Columns>
        <ContextMenu>
          <Button type='submit'>Create</Button>
        </ContextMenu>
      </form>
    </div>
  )
}
