import React from 'react'
import { useParams } from 'react-router-dom'
import CreateUserGroupBinding from '../../../components/CreateUserGroupBinding'
import { useApiRequest } from '../../../hooks'
import Spinner from '../../../components/Spinner'

export default function () {
  const { id } = useParams()
  const [user] = useApiRequest(`admin/users/${id}`)
  if (!user) {
    return <Spinner />
  }

  return (
    <div className='container'>
      <h1 className='title'>Add to group</h1>
      <CreateUserGroupBinding user={user} />
    </div>
  )
}
