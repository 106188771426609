import React, { useCallback, useMemo, useState } from 'react'
import DropdownMenu, { DropdownItem } from '../../../DropdownMenu'
import AddDispatchOrganization from '../../../AddDispatchOrganization'
import GoogleMaps, { Marker } from '../../../GoogleMaps'
import Button from '../../../Button'
import Info from '../../../Info'
import UserDetails from '../../../UserDetails'
import DateTime from '../../../DateTime'
import ModalDialog from '../../../ModalDialog'
import TextField from '../../../Form/TextField'
import { IconPickerItem } from '../../../Form/IconField'
import { formatAddress } from '../../../LocationRenderer'
import { useApi, useNotifications, useForm } from '../../../../hooks'
import { useDispatchData } from '../../DispatchDataWrapper'

import styles from './styles.module.scss'

function UserRow({ user, timestamp, openDetails }) {
  const displayName = useMemo(() => {
    if (!user || !user.personalData) {
      return null
    }

    if (user.personalData.displayName) {
      return user.personalData.displayName.value
    }

    return `User #${user.id}`
  }, [user])

  return (
    <div className={styles['user-row']}>
      {user && user.userType && (
        <div className={styles.icon}>
          <IconPickerItem
            icon={user.userType.icon}
            size={20}
            color={user.userType.color}
          />
        </div>
      )}
      <div onClick={() => openDetails(user)} style={{ cursor: 'pointer' }}>
        {displayName}
      </div>
      {timestamp && (
        <div className={styles.timestamp}>
          <DateTime value={timestamp} />
        </div>
      )}
    </div>
  )
}

function SetCaseNumberForm({ incident, onClose }) {
  const [getFieldProps, formData] = useForm()
  const [client] = useApi()

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault()

      const { caseNumber } = formData

      try {
        const customData = await client(
          `/admin/incidents/${incident.id}/custom-data/create`,
          {
            type: 'caseNumber',
            value: caseNumber
          }
        )

        onClose(customData)
      } catch (err) {
        console.error(err)
        onClose()
      }
    },
    [formData, onClose, client, incident]
  )

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        label='Please enter case number if applicable'
        name='caseNumber'
        placeholder='Case Number'
        maxLength={30}
        required={true}
        {...getFieldProps()}
      />
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <Button
          type='button'
          className='is-secondary'
          onClick={() => onClose(true)}>
          Not applicable
        </Button>
        <div style={{ marginLeft: 'auto' }}>
          <Button type='submit'>Submit</Button>
        </div>
      </div>
    </form>
  )
}

export default function IncidentDetailsPanel({
  incident,
  dispatchBindings,
  openSecondaryPanel,
  profile,
  setCustomActiveChannel,
  setActivePanel,
  setDirectionsDestination,
  userTypes
}) {
  const [client] = useApi()
  const addNotification = useNotifications()
  const { incidents } = useDispatchData()
  const [caseNumberModalActive, setCaseNumberModalActive] = useState(false)

  const currentDispatch = useMemo(() => {
    if (
      !incident ||
      !incidents[incident.id] ||
      !incidents[incident.id].dispatch ||
      !profile
    ) {
      return null
    }

    const dispatches = Object.values(incidents[incident.id].dispatch)
    return dispatches.filter((x) => x).find(({ id }) => id === profile.id)
  }, [incident, incidents, profile])

  const addDispatchOrganization = useCallback(
    () =>
      openSecondaryPanel(
        <AddDispatchOrganization
          incident={incident}
          dispatchBindings={dispatchBindings}
          onDispatchOrganizationCreated={() => openSecondaryPanel(null)}
          userTypes={userTypes}
          profile={profile}
        />,
        'Add responders'
      ),
    [openSecondaryPanel, dispatchBindings, incident, userTypes, profile]
  )

  const setActiveDispatch = useCallback(async () => {
    try {
      await client(`client/incident/${incident.id}/dispatch`, {})
    } catch (err) {
      console.error(err)
    }
  }, [client, incident])

  const cancelActiveDispatch = useCallback(async () => {
    try {
      await client(`client/incident/${incident.id}/cancel-dispatch`, {})
    } catch (err) {
      console.error(err)
    }
  }, [client, incident])

  const endIncident = useCallback(async () => {
    try {
      await client(`admin/incidents/${incident.id}/status`, {
        status: 'ended'
      })
    } catch (err) {
      addNotification(
        <div>
          <div>Failed to set incident status to "ended"!</div>
        </div>,
        { className: 'is-danger' }
      )
      return
    }

    addNotification(
      <div>
        <div>Incident status set to "ended".</div>
      </div>,
      { className: 'is-success' }
    )
  }, [client, incident, addNotification])

  const address = incident.locations.find((loc) => loc.format === 'us-address')
  const loc = incident.locations.find((loc) => loc.format === 'lat-long')

  const openUserDetails = useCallback(
    (user) =>
      openSecondaryPanel(
        <UserDetails id={user.id} />,
        user.personalData?.displayName?.value || `User #${user.id}`
      ),
    [openSecondaryPanel]
  )

  const respondingUnits = useMemo(
    () =>
      incidents[incident.id]?.dispatch
        ? Object.values(incidents[incident.id].dispatch).filter((x) => x)
        : [],
    [incident, incidents]
  )

  const openChat = useCallback(() => {
    setActivePanel('chat')
    setCustomActiveChannel(`incident_${incident.id}`)
  }, [setActivePanel, setCustomActiveChannel, incident])

  /*const showDirections = useCallback(() => {
    if (loc) {
      setDirectionsDestination([loc.latitude, loc.longitude])
    }
  }, [setDirectionsDestination, loc])*/

  return (
    <>
      {caseNumberModalActive && (
        <ModalDialog
          title='Case Number'
          active={true}
          onClose={() => setCaseNumberModalActive(false)}>
          <SetCaseNumberForm
            incident={incident}
            onClose={(success) => {
              setCaseNumberModalActive(false)

              if (success) {
                endIncident()
              }
            }}
          />
        </ModalDialog>
      )}
      <div style={{ width: '100%', position: 'relative' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            padding: '1rem'
          }}>
          <div style={{ fontSize: '1.2rem' }}>Incident Details</div>
          <div style={{ marginLeft: 'auto' }}>
            <DropdownMenu label={<i className='fa fa-cog'></i>}>
              <DropdownItem onClick={() => addDispatchOrganization()}>
                Add responders
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  const caseNumber = incident.customData.find(
                    ({ type: { typeName } }) => typeName === 'caseNumber'
                  )

                  if (!caseNumber) {
                    setCaseNumberModalActive(true)
                  } else {
                    if (
                      window.confirm(
                        'Are you sure you wish to end this incident?'
                      )
                    ) {
                      endIncident()
                    }
                  }
                }}>
                End incident
              </DropdownItem>
            </DropdownMenu>
          </div>
        </div>
        <div className='flex flex-col' style={{ padding: '1rem' }}>
          {incident.description && (
            <Info label='Description'>{incident.description}</Info>
          )}
          {address && <Info label='Address'>{formatAddress(address)}</Info>}
          {loc && (
            <Info label='Location'>
              {loc.latitude.toFixed(8)}, {loc.longitude.toFixed(8)} (Lat., Lon.)
            </Info>
          )}
        </div>
        {loc && (
          <div
            style={{
              marginTop: '2rem',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}>
            <GoogleMaps
              center={{ lng: loc.longitude, lat: loc.latitude }}
              zoom={17}
              containerStyle={{
                width: '90%',
                height: '250px',
                borderRadius: '32px',
                border: '1px solid #e7e7e7'
              }}>
              <Marker position={{ lng: loc.longitude, lat: loc.latitude }} />
            </GoogleMaps>
          </div>
        )}
        <div className='flex flex-col' style={{ padding: '1rem' }}>
          <div className={styles['open-chat']} onClick={openChat}>
            <i className='fa fa-comments' /> Open chat
          </div>
        </div>
        <div className='flex flex-col' style={{ padding: '1rem' }}>
          <Info label='Created by'>
            <UserRow
              user={incident.user}
              timestamp={incident.createdAt}
              openDetails={openUserDetails}
            />
          </Info>
          {respondingUnits.length !== 0 && (
            <Info label='Responding units'>
              <div>
                {respondingUnits.map((user, index) => (
                  <UserRow
                    key={index}
                    user={user}
                    timestamp={user.createdAt}
                    openDetails={openUserDetails}
                  />
                ))}
              </div>
            </Info>
          )}
          <Info label='Incident ID'>{incident.id}</Info>
        </div>
        <div className={styles.actions}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              width: '100%'
            }}>
            <Button
              style={{ width: '100%', textTransform: 'uppercase' }}
              onClick={() => setActiveDispatch()}
              icon={!!currentDispatch && 'fa fa-check'}
              disabled={!!currentDispatch}>
              {currentDispatch ? 'Accepted' : 'Accept'}
            </Button>
            <Button
              className='is-danger'
              style={{ width: '100%', textTransform: 'uppercase' }}
              onClick={() => cancelActiveDispatch()}
              disabled={!currentDispatch}>
              Leave
            </Button>
          </div>
          {/*<div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              width: '100%'
            }}>
            <Button
              className='is-link'
              style={{ width: '100%', textTransform: 'uppercase' }}
              disabled={!setDirectionsDestination || !loc}
              onClick={showDirections}>
              Directions
            </Button>
          </div>*/}
        </div>
      </div>
    </>
  )
}
