import React from 'react'
import { useParams } from 'react-router-dom'
import CCTVEventDetails from '../../../components/CCTVEventDetails'

export default function () {
  const { id, eventId } = useParams()
  return (
    <div className='container'>
      <CCTVEventDetails eventId={eventId} incidentId={id} />
    </div>
  )
}
