import React from 'react'
import { useParams } from 'react-router-dom'
import OrganizationDetails from '../../../components/OrganizationDetails'

export default function () {
  const { id } = useParams()
  return (
    <div className='container'>
      <OrganizationDetails id={id} />
    </div>
  )
}
