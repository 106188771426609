import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { useApi, useApiRequest, useForm, useError } from '../../hooks'
import DropdownField from '../Form/DropdownField'
import Button from '../Button'
import TextField from '../Form/TextField'
import ContextMenu from '../ContextMenu'
import Spinner from '../Spinner'

export default function () {
  const navigate = useNavigate()
  const [client] = useApi()
  const setError = useError()
  let [response] = useApiRequest('admin/user-types?limit=100')
  const userTypes = response?.items
  const [timezones] = useApiRequest('v2/organizations/time-zones')
  const [getFieldProps, formData, formError] = useForm()

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault()

      const { name, defaultUserTypeId, timezone } = formData

      try {
        const organization = await client('v2/organizations', {
          name,
          defaultUserTypeId,
          timezone: timezone || 'America/Chicago'
        })
        navigate(`/organizations/${organization.id}`)
      } catch (err) {
        if (err.message === 'validation_error') {
          formError(err.props)
        } else {
          setError(err)
        }
      }
    },
    [client, navigate, formError, setError, formData]
  )

  if (!userTypes || !timezones) {
    return <Spinner />
  }

  return (
    <div className='box'>
      <form onSubmit={handleSubmit}>
        <TextField
          label='Name'
          name='name'
          placeholder='Name'
          required={true}
          {...getFieldProps()}
        />
        <DropdownField
          name='defaultUserTypeId'
          label='Default User Type'
          required={true}
          {...getFieldProps()}>
          <option />
          {userTypes.map((userType) => (
            <option key={userType.id} value={userType.id}>
              {userType.name}
            </option>
          ))}
        </DropdownField>
        <DropdownField
          name='timezone'
          label='Time Zone'
          defaultValue='America/Chicago'
          required={true}
          {...getFieldProps()}>
          {timezones.map((tz, index) => (
            <option key={index} value={tz.name}>
              {tz.name} ({tz.alternativeName})
            </option>
          ))}
        </DropdownField>
        <ContextMenu>
          <Button type='submit'>Create</Button>
        </ContextMenu>
      </form>
    </div>
  )
}
