import React from 'react'
import { useLocation } from 'react-router-dom'

import CreateUserRole from '../../../components/CreateUserRole'

export default function () {
  const query = new URLSearchParams(useLocation().search)
  const organizationId = parseInt(query.get('organizationId'))

  return (
    <div className='container'>
      <h1 className='title'>Create a new user role</h1>
      <CreateUserRole organizationId={organizationId} />
    </div>
  )
}
