import React from 'react'
import { useParams } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

import CopyUserRole from '../../../components/CopyUserRole'

export default function () {
  const { id } = useParams()
  const query = new URLSearchParams(useLocation().search)
  const organizationId = parseInt(query.get('organizationId'))

  return (
    <div className='container'>
      <CopyUserRole id={parseInt(id)} organizationId={organizationId} />
    </div>
  )
}
