import React from 'react'
import { useNavigate } from 'react-router-dom'
import { usePagination } from '../../hooks'
import { IconPickerItem } from '../Form/IconField'
import PaginatedTable from '../PaginatedTable'

export default function ({ organizationId }) {
  const navigate = useNavigate()

  const [rows, currentPage, totalCount, loading, setPage, setOpts, opts] =
    usePagination({
      endpoint: `admin/organizations/${organizationId}/users`,
      itemsPerPage: 10,
      sortBy: 'id',
      sortOrder: 'asc',
      persistent: true,
      uid: `users-list-${organizationId}`
    })

  const columns = [
    {
      name: 'ID',
      sortKey: 'id',
      render: (item) => item.id
    },
    {
      name: 'Email',
      sortKey: 'email',
      render: (item) => item.email
    },
    {
      name: 'User Type',
      render: (item) =>
        (item.userType && (
          <IconPickerItem
            icon={item.userType.icon}
            size={24}
            color={item.userType.color}
          />
        )) ||
        'N/A'
    }
  ]

  return (
    <PaginatedTable
      className='is-fullwidth is-hoverable'
      columns={columns}
      rows={rows}
      currentPage={currentPage}
      totalCount={totalCount}
      loading={loading}
      setPage={setPage}
      opts={opts}
      setOpts={setOpts}
      onRowClick={(item) => navigate(`/users/${item.id}`)}
    />
  )
}
