import React, { useCallback, useEffect } from 'react'
import { Marker } from '@react-google-maps/api'
import useSound from 'use-sound'

import Columns from '../../Columns'
import DateTime from '../../DateTime'
import ModalDialog from '../../ModalDialog'
import GoogleMaps from '../../GoogleMaps'
import { formatAddress } from '../../LocationRenderer'
import { useApi, usePersistentState } from '../../../hooks'

import alarmSound from './alarm.mp3'

function capitalize(s) {
  return s[0].toUpperCase() + s.slice(1)
}

export default function DispatchIncidentAlert({ incidents }) {
  const [client] = useApi()
  const [ignored, setIgnored] = usePersistentState(
    'dispatch-ignored-incidents',
    {}
  )

  const [playAlarmSound, alarmSoundData] = useSound(alarmSound, {
    onend: () => playAlarmSound()
  })

  const unacknowledged = Object.values(incidents)
    .filter(
      ({ id, type, status, acknowledgements }) =>
        (type === 'automated' || type === 'emergency') &&
        status === 'in-progress' &&
        acknowledgements.length === 0 &&
        !ignored[id]
    )
    .map((incident) => {
      incident.address = incident.locations.find(
        (loc) => loc.format === 'us-address'
      )
      incident.loc = incident.locations.find((loc) => loc.format === 'lat-long')
      return incident
    })

  useEffect(() => {
    if (unacknowledged.length !== 0) {
      playAlarmSound()
    }
    return () => alarmSoundData.stop()
  }, [playAlarmSound, alarmSoundData, unacknowledged, incidents])

  const acknowledgeIncident = useCallback(
    async (incidentId) => {
      await client(`admin/incidents/${incidentId}/acknowledge`, {})
    },
    [client]
  )

  const ignoreIncident = useCallback(
    (incidentId) => setIgnored({ [incidentId]: true }),
    [setIgnored]
  )

  return (
    <ModalDialog active={unacknowledged.length !== 0}>
      {unacknowledged.map(({ id, name, type, address, loc, createdAt }) => (
        <div key={id} className='card' style={{ marginBottom: '1rem' }}>
          <header className='card-header'>
            <p className='card-header-title'>
              <i
                className='fa fa-exclamation-circle'
                style={{ marginRight: '0.25rem' }}
              />
              New incident alert
            </p>
          </header>
          <div className='card-content'>
            <div className='content'>
              <Columns slice={2}>
                <div>
                  Name <strong>{name}</strong>
                  <br />
                  Type <strong>{capitalize(type)}</strong>
                  <br />
                  Created on{' '}
                  <strong>
                    <DateTime value={createdAt} />
                  </strong>
                  <br />
                  {address && formatAddress(address)}
                </div>
                {loc && (
                  <div>
                    <GoogleMaps
                      center={{ lng: loc.longitude, lat: loc.latitude }}
                      zoom={17}
                      containerStyle={{
                        width: '100%',
                        height: '250px'
                      }}>
                      <Marker
                        position={{ lng: loc.longitude, lat: loc.latitude }}
                      />
                    </GoogleMaps>
                  </div>
                )}
              </Columns>
            </div>
          </div>
          <footer className='card-footer'>
            <button
              className='button is-primary card-footer-item'
              style={{ borderRadius: 0 }}
              onClick={() => acknowledgeIncident(id)}>
              Acknowledge
            </button>
            <button
              className='button is-danger card-footer-item'
              style={{ borderRadius: 0 }}
              onClick={() => ignoreIncident(id)}>
              Ignore
            </button>
          </footer>
        </div>
      ))}
    </ModalDialog>
  )
}
