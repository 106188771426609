/* eslint-disable */
import React, { useState, useRef, useEffect, useCallback } from 'react'

export function DropdownItem({ children, onClick }) {
  return (
    <a onClick={onClick} className='dropdown-item'>
      {children}
    </a>
  )
}

export default function ({ children, label, small, ...props }) {
  const [active, setActive] = useState(false)
  const ref = useRef()

  const handleOutsideClick = useCallback(
    (e) => {
      if (ref.current && ref.current.contains(e.target)) {
        return
      }

      setActive(false)
    },
    [setActive]
  )

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick)
    return () => document.removeEventListener('mousedown', handleOutsideClick)
  }, [handleOutsideClick])

  const handleClick = useCallback(
    (onClick) => {
      setTimeout(() => setActive(false), 0)

      if (onClick) {
        onClick()
      }
    },
    [setActive]
  )

  if (!Array.isArray(children)) {
    children = [children]
  }

  children = children.filter((child) => child)
  if (!children || children.length === 0) {
    return null
  }

  return (
    <div
      ref={ref}
      className={`dropdown is-right ${active ? 'is-active' : ''}`}
      {...props}>
      <div className='dropdown-trigger'>
        <button
          className={`button ${small ? 'is-small' : ''}`}
          aria-haspopup='true'
          aria-controls='dropdown-menu'
          onClick={() => setActive(!active)}>
          <span>{label}</span>
          <span className='icon is-small'>
            <i className='fas fa-angle-down' aria-hidden='true'></i>
          </span>
        </button>
      </div>
      <div className='dropdown-menu' id='dropdown-menu' role='menu'>
        <div className='dropdown-content'>
          {children &&
            children.map((child, key) =>
              child
                ? React.cloneElement(child, {
                    onClick: () => handleClick(child.props.onClick),
                    key
                  })
                : null
            )}
        </div>
      </div>
    </div>
  )
}
