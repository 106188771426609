import React from 'react'
import { useParams } from 'react-router-dom'
import IncidentDetails from '../../../components/IncidentDetails'

export default function () {
  const { id } = useParams()
  return (
    <div className='container'>
      <IncidentDetails id={id} />
    </div>
  )
}
