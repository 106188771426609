import React from 'react'

export default function ({ value, className, ...props }) {
  return (
    <progress
      className={`progress ${className ? className : ''}`}
      value={value * 100.0}
      max='100'
      {...props}>
      {Math.floor(value * 100.0).toFixed(0)}%
    </progress>
  )
}
