import { useRef, useCallback } from 'react'

import styles from './effects.module.scss'

export function useFlashBackground() {
  const ref = useRef()

  const playAnimation = useCallback(() => {
    const el = ref.current
    if (el) {
      el.classList.add(styles.flash)
      setTimeout(() => {
        el.classList.remove(styles.flash)
      }, 1000)
    }
  }, [ref])

  return [ref, playAnimation]
}
