import React, { useState, useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom'

import { useApi, useApiRequest, useError, useForm } from '../../hooks'
import DateTime from '../DateTime'
import ContextMenu from '../ContextMenu'
import Columns from '../Columns'
import Info from '../Info'
import DropdownMenu, { DropdownItem } from '../DropdownMenu'
import Button from '../Button'
import EditAgentDetails from '../EditAgentDetails'
import DataTable, { Row, RowData } from '../DataTable'
import TextAreaField from '../Form/TextAreaField'

function capitalize(s) {
  return s[0].toUpperCase() + s.slice(1)
}

function getSourceStatus({ status }, source) {
  const uri = source.opts.uri
  if (!status || !status.workers) {
    return 'Unknown'
  }

  const worker = status.workers.find(
    (w) => w.streamInfo && w.streamInfo.uri === uri
  )
  if (!worker) {
    return 'Not running'
  }

  return capitalize(worker.status)
}

function SourcesList({ agent }) {
  return (
    <DataTable
      columns={['Type', 'Name', 'URI', 'Status']}
      className='is-fullwidth is-hoverable'>
      {agent.sources &&
        agent.sources.map((item, index) => (
          <Row key={index} className='is-size-7'>
            <RowData>{item.type}</RowData>
            <RowData>{item.name}</RowData>
            <RowData>{item.opts.uri}</RowData>
            <RowData>{getSourceStatus(agent, item)}</RowData>
          </Row>
        ))}
    </DataTable>
  )
}

function EditVideoSources({ agent, onFinishEditing }) {
  const setError = useError()
  const [client] = useApi()
  let [json, setJson] = useState()

  useEffect(() => {
    if (!json) {
      setJson(JSON.stringify(agent.sources, null, 2))
    }
  }, [json, agent.sources])

  function addSource() {
    try {
      json = JSON.parse(json)
    } catch {
      return
    }

    json.push({ type: 'pyav', name: '', opts: { uri: '' } })
    setJson(JSON.stringify(json, null, 2))
  }

  const [getFieldProps, formData, formError] = useForm()

  async function handleSubmit(e) {
    e.preventDefault()

    let sources

    try {
      sources = JSON.parse(formData.sources)
    } catch {
      formError({ sources: 'Invalid JSON.' })
      return
    }

    try {
      await client(`admin/agents/${agent.id}/config`, { sources })
      onFinishEditing()
    } catch (err) {
      if (err.message === 'validation_error') {
        formError(err.props)
      } else {
        setError(err)
      }
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <TextAreaField
        name='sources'
        defaultValue={json}
        {...getFieldProps()}
        style={{ height: '500px' }}
      />
      <ContextMenu>
        <Button
          type='button'
          className='is-secondary'
          onClick={() => addSource()}>
          Add source
        </Button>
        <Button type='submit'>Update</Button>
      </ContextMenu>
    </form>
  )
}

export default function ({ id }) {
  const navigate = useNavigate()
  const [client] = useApi()
  const [agent, reloadAgent] = useApiRequest(`admin/agents/${id}`)
  const [accessToken, setAccessToken] = useState(null)
  const setError = useError()
  const [editing, setEditing] = useState()
  const [editingSources, setEditingSources] = useState()

  async function createAccessToken() {
    try {
      const { token } = await client(
        `admin/agents/${id}/create-access-token`,
        {}
      )
      setAccessToken(token)
    } catch (err) {
      setError(err)
    }
  }

  async function deleteAgent() {
    try {
      if (window.confirm('Are you sure you wish to delete this agent?')) {
        await client(`admin/agents/${id}/delete`, {})
        navigate('/agents/list')
      }
    } catch (err) {
      setError(err)
    }
  }

  return (
    <div>
      <ContextMenu
        renderTitle={() => agent && <h1 className='title'>{agent.name}</h1>}>
        <DropdownMenu label='Actions'>
          <DropdownItem onClick={() => createAccessToken()}>
            Create an access token
          </DropdownItem>
          <DropdownItem onClick={() => setEditing(true)}>
            Edit agent details
          </DropdownItem>
          <DropdownItem onClick={() => setEditingSources(true)}>
            Edit video sources
          </DropdownItem>
          <DropdownItem onClick={() => deleteAgent()}>
            Delete this agent
          </DropdownItem>
        </DropdownMenu>
      </ContextMenu>
      {accessToken && (
        <div
          className='notification is-warning'
          style={{ overflowWrap: 'break-word' }}>
          {accessToken}
        </div>
      )}
      {agent && (
        <div className='box'>
          <h2 className='subtitle'>Agent Details</h2>
          <Columns>
            <Info label='ID'>{agent.id}</Info>
            <Info label='Name'>{agent.name}</Info>
            <Info label='Site'>
              <Link to={`/sites/${agent.site.id}`}>{agent.site.name}</Link>
            </Info>
            <Info label='Updated At'>
              <DateTime value={agent.updatedAt} />
            </Info>
          </Columns>
          {editing && (
            <EditAgentDetails
              agent={agent}
              onFinishEditing={() => reloadAgent() && setEditing(false)}
            />
          )}
          {editingSources && (
            <EditVideoSources
              agent={agent}
              onFinishEditing={() => reloadAgent() && setEditingSources(false)}
            />
          )}
        </div>
      )}
      {agent && (
        <div className='box'>
          <h2 className='subtitle'>Video Sources</h2>
          <SourcesList agent={agent} />
        </div>
      )}
      {agent && (
        <div className='box'>
          <div className='level'>
            <div className='level-left'>
              <div className='level-item'>
                <h2 className='subtitle'>Status</h2>
              </div>
              <div className='level-item'>
                <Button className='is-secondary is-small' onClick={reloadAgent}>
                  <i className='fa fa-sync' />
                </Button>
              </div>
            </div>
          </div>
          <code
            className='is-size-7'
            style={{ display: 'block', whiteSpace: 'pre-wrap' }}>
            {JSON.stringify(agent.status, null, 2)}
          </code>
        </div>
      )}
    </div>
  )
}
