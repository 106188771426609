import React, { useMemo } from 'react'
import { useApi, useError, useForm, useNotifications } from '../../hooks'

import CheckboxField from '../Form/CheckboxField'
import Button from '../Button'
import { USER_TYPE_FLAGS } from '../CreateUserType'
import { iconClassName } from '../Dispatch/DispatchMap/icons'

const ORGS_PREFIX = 'organization:'

const DISPATCH_FLAGS = {
  INCLUDE_OFFDUTY: 1 << 0
}

export default function ({
  incident,
  dispatchBindings,
  onDispatchOrganizationCreated,
  userTypes,
  profile
}) {
  const [client] = useApi()
  const setError = useError()
  const addNotification = useNotifications()

  const [getFieldProps, formData, formError] = useForm()

  const existingDispatchOrgs = useMemo(
    () =>
      incident.dispatchOrganizations.reduce(
        (acc, { id, name, dispatchFlags }) => ({
          ...acc,
          [id]: {
            name,
            offDuty: (dispatchFlags & DISPATCH_FLAGS.INCLUDE_OFFDUTY) !== 0
          }
        }),
        {}
      ),
    [incident]
  )

  const userTypesById = useMemo(() => {
    return userTypes.reduce(
      (acc, userType) => ({
        ...acc,
        [userType.id]: userType
      }),
      {}
    )
  }, [userTypes])

  const dispatchOrgs = useMemo(() => {
    const dispatchableUserTypes = userTypes.filter(
      ({ flags }) => !!(flags & USER_TYPE_FLAGS.DISPATCHABLE)
    )

    const orgs = [
      profile.organization,
      ...dispatchBindings.map(
        ({ dispatchOrganization }) => dispatchOrganization
      )
    ]

    return orgs
      .filter((x) => x)
      .filter(({ defaultUserType }) =>
        dispatchableUserTypes.find(({ id }) => id === defaultUserType.id)
      )
      .reduce((acc, organization) => {
        const { defaultUserType } = organization

        if (!acc[defaultUserType.id]) {
          acc[defaultUserType.id] = []
        }

        acc[defaultUserType.id].push(organization)
        return acc
      }, {})
  }, [userTypes, dispatchBindings, profile])

  async function handleSubmit(e) {
    e.preventDefault()

    const dispatchOrganizationIds = Object.entries(formData)
      .filter(([key, value]) => key.startsWith(ORGS_PREFIX) && value)
      .map(([key]) => parseInt(key.slice(ORGS_PREFIX.length)))

    if (dispatchOrganizationIds.length === 0) {
      setError({ dispatchOrganizations: true })
      return
    }

    const dispatchOrganizations = dispatchOrganizationIds.map((id) => ({
      id,
      offDuty: formData[`offduty:${ORGS_PREFIX}${id}`] || false
    }))

    try {
      for (const dispatchOrg of dispatchOrganizations) {
        if (
          !existingDispatchOrgs[dispatchOrg.id] ||
          (existingDispatchOrgs[dispatchOrg.id] &&
            dispatchOrg.offDuty &&
            !existingDispatchOrgs[dispatchOrg.id].offDuty)
        ) {
          await client(
            `admin/incidents/${incident.id}/add-dispatch-organization`,
            { id: dispatchOrg.id, offDuty: dispatchOrg.offDuty }
          )
        }
      }

      addNotification(
        <div>
          <div>Responders added to incident.</div>
        </div>,
        { className: 'is-success' }
      )

      onDispatchOrganizationCreated && onDispatchOrganizationCreated()
    } catch (err) {
      if (err.message === 'validation_error') {
        formError(err.props)
      } else {
        setError(err)
      }
    }
  }

  return (
    <div style={{ width: '100%', display: 'flex', padding: '1rem' }}>
      <form onSubmit={handleSubmit} style={{ width: '100%' }}>
        {Object.entries(dispatchOrgs).map(([userTypeId, organizations]) => (
          <div className='dispatch-type-select-item' key={userTypeId}>
            <div className='dispatch-type-select-item-header'>
              <div className='dispatch-type-select-item-header-icon'>
                <i
                  className={iconClassName(userTypesById[userTypeId].icon)}
                  style={{ color: userTypesById[userTypeId].color }}
                />
              </div>
              <span>{userTypesById[userTypeId].name}</span>
            </div>
            <div className='dispatch-type-select-item-body'>
              {organizations.map(({ id, name }) => (
                <div key={id}>
                  <CheckboxField
                    name={`${ORGS_PREFIX}${id}`}
                    label={name}
                    defaultChecked={existingDispatchOrgs[id]}
                    disabled={existingDispatchOrgs[id]}
                    {...getFieldProps()}
                  />
                  {formData && formData[`${ORGS_PREFIX}${id}`] && (
                    <CheckboxField
                      name={`offduty:${ORGS_PREFIX}${id}`}
                      label='Include Off-Duty'
                      disabled={
                        existingDispatchOrgs[id] &&
                        existingDispatchOrgs[id].offDuty
                      }
                      defaultChecked={
                        existingDispatchOrgs[id] &&
                        existingDispatchOrgs[id].offDuty
                      }
                      {...getFieldProps()}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
        ))}
        <div className='level'>
          <div className='level-left' />
          <div className='level-right'>
            <div className='level-item'>
              <Button type='submit'>Add responders</Button>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}
