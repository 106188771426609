import React, { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  useApi,
  useApiRequest,
  useError,
  usePermissions,
  useNotifications
} from '../../hooks'
import Columns from '../Columns'
import Info from '../Info'
import DataTable, { Row, RowData } from '../DataTable'
import DropdownMenu, { DropdownItem } from '../DropdownMenu'
import ContextMenu from '../ContextMenu'
import EditUserPersonalData from '../EditUserPersonalData'
import { IconPickerItem } from '../Form/IconField'
import NotificationsRenderer from '../NotificationsRenderer'

function capitalize(s) {
  return s[0].toUpperCase() + s.slice(1)
}

function UserRolesList({ userId, organizationId, roleBindings, reloadUser }) {
  const [client] = useApi()
  const setError = useError()

  async function removeRole(userId, roleId) {
    try {
      if (
        window.confirm('Are you sure you wish to delete this role binding?')
      ) {
        await client(
          `admin/organizations/${organizationId}/user-role-bindings/delete`,
          { userId, roleId }
        )
        await reloadUser()
      }
    } catch (err) {
      setError(err)
    }
  }

  return (
    <DataTable
      columns={['Role', 'Actions']}
      className='is-fullwidth is-hoverable is-size-7'>
      {roleBindings &&
        roleBindings.map((item) => (
          <Row key={item.id}>
            <RowData>{item.role.name}</RowData>
            <RowData>
              <DropdownMenu label='Actions' small={true}>
                <DropdownItem onClick={() => removeRole(userId, item.role.id)}>
                  Delete
                </DropdownItem>
              </DropdownMenu>
            </RowData>
          </Row>
        ))}
    </DataTable>
  )
}

function UserGroupsList({ userId, organizationId, groupBindings, reloadUser }) {
  const [client] = useApi()
  const setError = useError()

  async function removeFromGroup(userId, groupId) {
    try {
      if (
        window.confirm('Are you sure you wish to delete this group binding?')
      ) {
        await client(
          `admin/organizations/${organizationId}/user-groups/delete-binding`,
          { userId, groupId }
        )
        await reloadUser()
      }
    } catch (err) {
      setError(err)
    }
  }

  return (
    <DataTable
      columns={['Group', 'Actions']}
      className='is-fullwidth is-hoverable is-size-7'>
      {groupBindings &&
        groupBindings.map((item) => (
          <Row key={item.id}>
            <RowData>{item.group.name}</RowData>
            <RowData>
              <DropdownMenu label='Actions' small={true}>
                <DropdownItem
                  onClick={() => removeFromGroup(userId, item.group.id)}>
                  Remove
                </DropdownItem>
              </DropdownMenu>
            </RowData>
          </Row>
        ))}
    </DataTable>
  )
}

/*function DevicesList({ devices }) {
  return (
    <DataTable
      columns={['UID', 'Name', 'User agent']}
      className='is-fullwidth is-hoverable is-size-7'>
      {devices &&
        devices.map((item) => (
          <Row key={item.id}>
            <RowData>{item.uid}</RowData>
            <RowData>{item.name}</RowData>
            <RowData>{item.userAgent}</RowData>
          </Row>
        ))}
    </DataTable>
  )
}*/

export default function ({ id }) {
  const navigate = useNavigate()
  const [client] = useApi()
  const [user, reloadUser] = useApiRequest(`admin/users/${id}`)
  const [editing, setEditing] = useState()
  const setError = useError()
  const updateUserPermission = usePermissions('user', 'updateAny')
  const addNotification = useNotifications()

  const setOffDuty = useCallback(async () => {
    try {
      if (
        window.confirm('Are you sure you wish to set this user to off-duty?')
      ) {
        await client(`v2/users/${id}/availability`, { status: 'off-duty' })
        await reloadUser()

        addNotification(
          <div>
            <div>User #{id} has been set to off-duty.</div>
          </div>,
          { className: 'is-success' }
        )
      }
    } catch (err) {
      setError(err)
    }
  }, [client, id, addNotification, reloadUser, setError])

  async function deleteUser() {
    try {
      if (window.confirm('Are you sure you wish to delete this user?')) {
        await client(`admin/users/${id}/delete`, {})
        navigate(`/organizations/${user.organization.id}`)
      }
    } catch (err) {
      setError(err)
    }
  }

  async function finishEditing() {
    await reloadUser()
    setEditing(false)
  }

  return (
    <div style={{ width: '100%' }}>
      <ContextMenu
        renderTitle={() =>
          user && (
            <h1 className='title' style={{ paddingLeft: '1rem' }}>
              {user.email}
            </h1>
          )
        }>
        <DropdownMenu label='Actions'>
          <DropdownItem onClick={() => setEditing(true)}>
            Edit personal data
          </DropdownItem>
          <DropdownItem
            onClick={() => navigate(`/users/${user.id}/create-role-binding`)}>
            Assign a role
          </DropdownItem>
          <DropdownItem
            onClick={() => navigate(`/users/${user.id}/create-group-binding`)}>
            Assign to group
          </DropdownItem>
          <DropdownItem
            onClick={() => navigate(`/users/${user.id}/set-user-type`)}>
            Change the user's type
          </DropdownItem>
          {updateUserPermission &&
            updateUserPermission.granted &&
            user &&
            user.availability === 'on-duty' && (
              <DropdownItem onClick={() => setOffDuty()}>
                Set availability to off-duty
              </DropdownItem>
            )}
          <DropdownItem onClick={() => deleteUser()}>Delete</DropdownItem>
        </DropdownMenu>
      </ContextMenu>
      {user && (
        <div className='box'>
          <h2 className='subtitle'>User Details</h2>
          <Columns>
            <Info label='ID'>{user.id}</Info>
            <Info label='Email'>{user.email}</Info>
            <Info label='Organization'>{user.organization.name}</Info>
            {user.userType && (
              <Info label='Type'>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                  }}>
                  <IconPickerItem
                    icon={user.userType.icon}
                    size={24}
                    color={user.userType.color}
                  />
                  {user.userType.name}
                </div>
              </Info>
            )}
            <Info label='Availability'>{capitalize(user.availability)}</Info>
          </Columns>
          {!editing && (
            <Columns>
              {user.personalData.map((data) => (
                <Info key={data.id} label={data.type.displayName}>
                  {data.value}
                </Info>
              ))}
            </Columns>
          )}
          {editing && (
            <EditUserPersonalData
              id={user.id}
              onFinishEditing={finishEditing}
            />
          )}
        </div>
      )}
      {user && user.groupBindings && (
        <div className='box'>
          <h2 className='subtitle'>Group Bindings</h2>
          <UserGroupsList
            userId={user.id}
            organizationId={user.organization.id}
            groupBindings={user.groupBindings}
            reloadUser={reloadUser}
          />
        </div>
      )}
      {user && user.roleBindings && (
        <div className='box'>
          <h2 className='subtitle'>Role Bindings</h2>
          <UserRolesList
            userId={user.id}
            organizationId={user.organization.id}
            roleBindings={user.roleBindings}
            reloadUser={reloadUser}
          />
        </div>
      )}
      {/*user && user.devices && (
        <div className='box'>
          <h2 className='subtitle'>Devices</h2>
          <DevicesList devices={user.devices} />
        </div>
      )*/}
      <NotificationsRenderer
        containerClassName='notifications'
        itemClassName='notification'
      />
    </div>
  )
}
