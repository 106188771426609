import React from 'react'
import { useNavigate } from 'react-router-dom'
import NavBar from '../NavBar'
import ErrorHandler from '../ErrorHandler'

function ErrorModal({ error, clearError }) {
  const navigate = useNavigate()

  return (
    <div className='modal is-active'>
      <div className='modal-background' />
      <div className='modal-content'>
        <article className='message is-danger'>
          <div className='message-header'>
            <p>Error!</p>
            <button
              className='delete'
              aria-label='delete'
              onClick={clearError}></button>
          </div>
          <div className='message-body is-family-monospace'>
            {error.message === 'forbidden' ? (
              <div>You do not have permission to access this resource.</div>
            ) : (
              <div>{error.message}</div>
            )}
            <div>
              <button onClick={() => navigate(-1)}>Click here</button> to go
              back to the previous page.
            </div>
          </div>
        </article>
      </div>
      <button
        className='modal-close is-large'
        aria-label='close'
        onClick={clearError}></button>
    </div>
  )
}

export default function ({ children }) {
  return (
    <div>
      <NavBar />
      <section className='section'>
        <ErrorHandler
          renderError={(error, clearError) => (
            <ErrorModal error={error} clearError={clearError} />
          )}>
          {children}
        </ErrorHandler>
      </section>
    </div>
  )
}
