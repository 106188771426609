import React, { Component, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

function ClearErrorOnLocationChange({ clearError }) {
  const location = useLocation()
  const [path, setPath] = useState(location.pathname)

  useEffect(() => {
    if (location.pathname !== path) {
      setPath(location.pathname)
      clearError()
    }
  }, [location, path, setPath, clearError])

  return null
}

export default class ErrorHandler extends Component {
  constructor(props) {
    super(props)

    this.state = {
      error: null
    }

    this.clearError = this.clearError.bind(this)
  }

  componentDidCatch(error, info) {
    this.setState({ error })
  }

  clearError() {
    this.setState({ error: null })
  }

  render() {
    const { error } = this.state
    const { children, renderError } = this.props

    if (error) {
      if (renderError) {
        return (
          <>
            <ClearErrorOnLocationChange clearError={this.clearError} />
            {renderError(error, this.clearError)}
          </>
        )
      }

      return (
        <div>
          <ClearErrorOnLocationChange clearError={this.clearError} />
          {error.message}
        </div>
      )
    }

    return (
      <>
        <ClearErrorOnLocationChange clearError={this.clearError} />
        {children}
      </>
    )
  }
}
