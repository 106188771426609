import React from 'react'

import { useApi, useForm, useError } from '../../hooks'
import TextField from '../Form/TextField'
import Button from '../Button'
import ContextMenu from '../ContextMenu'
import LocationForm, { parseLocation } from '../LocationForm'

export default function ({ site, onFinishEditing }) {
  const [client] = useApi()
  const setError = useError()

  const [getFieldProps, formData, formError] = useForm()

  async function handleSubmit(e) {
    e.preventDefault()

    const { name } = formData
    let location

    try {
      location = parseLocation(formData)
    } catch (err) {
      if (err.message === 'invalid_format') {
        formError({
          format: 'Invalid location format.'
        })
      } else {
        setError(err)
      }

      return
    }

    try {
      await client(`admin/sites/${site.id}`, {
        name,
        locations: [location]
      })
      onFinishEditing()
    } catch (err) {
      if (err.message === 'validation_error') {
        formError(err.props)
      } else {
        setError(err)
      }
    }
  }

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <TextField
          label='Name'
          name='name'
          defaultValue={site.name}
          required={true}
          {...getFieldProps()}
        />
        <LocationForm
          getFieldProps={getFieldProps}
          formData={formData}
          defaultValue={site.locations[0]}
        />
        <br />
        <ContextMenu>
          <Button type='submit'>Update</Button>
        </ContextMenu>
      </form>
    </div>
  )
}
