import React from 'react'

export default function ({ href }) {
  return (
    <a href={href} target='_blank' rel='noopener noreferrer'>
      <span className='icon'>
        <i className='fas fa-external-link-alt' />
      </span>
      <span style={{ fontSize: '0.9em' }}>{new URL(href).host}</span>
    </a>
  )
}
