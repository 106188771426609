import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { getResourceDataUrl } from '../../api'
import { useApiRequest, useApiToken, useError } from '../../hooks'
import DateTime from '../DateTime'
import Columns from '../Columns'
import Info from '../Info'
import Spinner from '../Spinner'

function drawSnapshot(canvas, image, { label, bbox }, drawBoxes) {
  const ctx = canvas.getContext('2d')
  ctx.drawImage(image, 0, 0)
  ctx.beginPath()

  if (!bbox || !drawBoxes) {
    return
  }

  ctx.font = '14px monospace'
  ctx.fillStyle = '#FFFFFF'
  ctx.fillText(
    label,
    (bbox[0] + bbox[2]) * 0.5 - ctx.measureText(label).width * 0.5,
    bbox[1] - 8.0
  )

  ctx.strokeStyle = '#00AAFFAA'
  ctx.lineWidth = 3.0
  ctx.rect(bbox[0], bbox[1], bbox[2] - bbox[0], bbox[3] - bbox[1])
  ctx.stroke()
}

function Snapshot({ cctvEvent }) {
  const [dataUrl, setDataUrl] = useState()
  const [drawBoxes, setDrawBoxes] = useState(true)
  const token = useApiToken()
  const setError = useError()
  const canvasRef = useRef()

  useEffect(() => {
    if (!dataUrl) {
      getResourceDataUrl(cctvEvent.snapshot.url, token)
        .then((url) => {
          setDataUrl(url)
        })
        .catch(setError)
    }

    if (canvasRef && dataUrl) {
      const image = new Image()
      image.src = dataUrl
      image.onload = () =>
        drawSnapshot(canvasRef.current, image, cctvEvent.data, drawBoxes)
    }
  }, [cctvEvent, dataUrl, setDataUrl, setError, token, drawBoxes])

  if (!dataUrl) {
    return <Spinner />
  }

  return (
    <div>
      <div>
        <canvas
          ref={canvasRef}
          width='1280'
          height='720'
          style={{ width: '100%' }}
        />
      </div>
      <div>
        <label className='checkbox'>
          <input
            type='checkbox'
            onChange={(e) => setDrawBoxes(e.target.checked)}
            defaultChecked={drawBoxes}
          />{' '}
          Draw bounding boxes
        </label>
      </div>
    </div>
  )
}

export default function ({ incidentId, eventId }) {
  const [cctvEvent] = useApiRequest(
    `admin/incidents/${incidentId}/cctv-events/${eventId}`
  )

  return (
    <div>
      {cctvEvent && (
        <h1 className='title'>
          CCTV event from incident "{cctvEvent.incident.name}"
        </h1>
      )}
      {cctvEvent && (
        <div className='box'>
          <h2 className='subtitle'>Event Details</h2>
          <Columns>
            <Info label='ID'>{cctvEvent.id}</Info>
            <Info label='Incident'>
              <Link to={`/incidents/${cctvEvent.incident.id}`}>
                {cctvEvent.incident.name}
              </Link>
            </Info>
            <Info label='Agent'>
              <Link to={`/agents/${cctvEvent.agent.id}`}>
                {cctvEvent.agent.name}
              </Link>
            </Info>
            <Info label='Site'>
              <Link to={`/sites/${cctvEvent.agent.site.id}`}>
                {cctvEvent.agent.site.name}
              </Link>
            </Info>
            {cctvEvent.data.source && (
              <Info label='Source'>{cctvEvent.data.source}</Info>
            )}
            <Info label='Created At'>
              <DateTime value={cctvEvent.createdAt} />
            </Info>
          </Columns>
        </div>
      )}
      {cctvEvent && (
        <div className='box'>
          <h2 className='subtitle'>Snapshot</h2>
          <Snapshot cctvEvent={cctvEvent} />
        </div>
      )}
      {cctvEvent && (
        <div className='box'>
          <div className='level'>
            <div className='level-left'>
              <div className='level-item'>
                <h2 className='subtitle'>Data</h2>
              </div>
            </div>
          </div>
          <code style={{ display: 'block', whiteSpace: 'pre-wrap' }}>
            {JSON.stringify(cctvEvent.data, null, 2)}
          </code>
        </div>
      )}
    </div>
  )
}
