import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { httpClient } from '../../api'
import {
  useApi,
  useApiRequest,
  useApiToken,
  useError,
  useForm
} from '../../hooks'

import TextField from '../Form/TextField'
import DropdownField from '../Form/DropdownField'
import FileField from '../Form/FileField'
import Button from '../Button'
import ProgressBar from '../ProgressBar'
import ContextMenu from '../ContextMenu'
import Columns from '../Columns'
import Spinner from '../Spinner'

export default function ({ siteId }) {
  const navigate = useNavigate()
  const [client] = useApi()
  const accessToken = useApiToken()
  const [site] = useApiRequest(`admin/sites/${siteId}`, null)
  const [uploadProgress, setUploadProgress] = useState(null)
  const [loading, setLoading] = useState(false)
  const setError = useError()

  const [getFieldProps, formData, formError] = useForm()

  if (!site) {
    return <Spinner />
  }

  async function handleSubmit(e) {
    e.preventDefault()

    const { name, type, file } = formData

    if (!file) {
      formError({ file: 'Please select a file.' })
      return
    }

    setLoading(true)

    const {
      data: { sha256 }
    } = await httpClient.put('v1/resources/upload', file, {
      onUploadProgress: (e) => setUploadProgress(e.loaded / e.total),
      headers: {
        authorization: `Bearer ${accessToken}`,
        'content-type': file.type
      },
      json: true
    })

    try {
      await client(`admin/sites/${siteId}/info/create`, {
        name,
        type,
        sha256
      })

      navigate(`/sites/${siteId}`)
    } catch (err) {
      setLoading(false)

      if (err.message === 'validation_error') {
        formError(err.props)
      } else {
        setError(err)
      }
    }
  }

  return (
    <div className='box'>
      <form onSubmit={handleSubmit}>
        <Columns slice={2}>
          <TextField label='Site' value={site.name} readOnly={true} />
          <TextField
            label='Name'
            name='name'
            placeholder='Name'
            required={true}
            {...getFieldProps()}
          />
          <DropdownField
            label='Type'
            name='type'
            required={true}
            {...getFieldProps()}>
            <option />
            <option value='floorplan'>Floorplan</option>
            <option value='other'>Other</option>
          </DropdownField>
          <FileField label='File' name='file' {...getFieldProps()} />
        </Columns>
        {uploadProgress && (
          <ProgressBar
            value={uploadProgress}
            className='is-info'
            style={{ marginTop: '2em' }}
          />
        )}
        <ContextMenu>
          <Button
            type='submit'
            className={loading ? 'is-loading' : ''}
            enabled={`${!loading}`}>
            Create
          </Button>
        </ContextMenu>
      </form>
    </div>
  )
}
