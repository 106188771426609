import React, { useContext, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, useLocation } from 'react-router-dom'
import { useLoadScript } from '@react-google-maps/api'
import ReactGA from 'react-ga'

import { ApiProvider } from './hooks/api'
import { NotificationsProvider } from './hooks/notifications'
import Routes from './routes'
import { ApiContext } from './hooks/api'
import App from './components/App'
import Spinner from './components/Spinner'

import '@fortawesome/fontawesome-free/css/all.min.css'
import 'bulma/css/bulma.min.css'
import './app.css'

const { GOOGLE_ANALYTICS_ID, GOOGLE_MAPS_API_KEY } = window.appConfig
const libraries = ['places']

function GoogleMapsProvider({ children }) {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries
  })

  if (loadError) {
    console.error(loadError)
  }

  if (!isLoaded && !loadError) {
    return <Spinner />
  }

  return children
}

function GoogleAnalyticsTracker() {
  const userIdSet = useRef(false)
  const { client } = useContext(ApiContext)
  const location = useLocation()

  useEffect(() => {
    async function fetchProfile() {
      const profile = await client('admin/profile')
      if (GOOGLE_ANALYTICS_ID) {
        ReactGA.set({ userId: profile.id })
      }
    }

    if (client && !userIdSet.current) {
      fetchProfile()
      userIdSet.current = true
    }
  }, [client])

  useEffect(() => {
    const path = location.pathname + location.search
    if (GOOGLE_ANALYTICS_ID) {
      ReactGA.pageview(path)
    }
  }, [location])

  return null
}

if (GOOGLE_ANALYTICS_ID) {
  ReactGA.initialize(GOOGLE_ANALYTICS_ID)
}

ReactDOM.render(
  <BrowserRouter>
    <ApiProvider>
      <GoogleAnalyticsTracker />
      <GoogleMapsProvider>
        <NotificationsProvider>
          <App>
            <Routes />
          </App>
        </NotificationsProvider>
      </GoogleMapsProvider>
    </ApiProvider>
  </BrowserRouter>,
  document.getElementById('root')
)
