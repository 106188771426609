import React from 'react'

import './styles.scss'

export default function ModalDialog({ active, title, onClose, children }) {
  return (
    <div className={`modal ${active ? 'is-active' : ''}`}>
      <div className='modal-background' />
      <div className='modal-card'>
        <header className='modal-card-head'>
          <p className='modal-card-title'>{title}</p>
          {onClose && (
            <button className='delete' aria-label='close' onClick={onClose} />
          )}
        </header>
        <section className='modal-card-body'>{children}</section>
      </div>
    </div>
  )
}
