import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useApi, useError, useForm } from '../../hooks'

import TextField from '../Form/TextField'
import Button from '../Button'
import ContextMenu from '../ContextMenu'

import LocationForm, { parseLocation } from '../LocationForm'

export default function () {
  const navigate = useNavigate()
  const [client] = useApi()
  const setError = useError()

  const [getFieldProps, formData, formError] = useForm()

  async function handleSubmit(e) {
    e.preventDefault()

    const { name } = formData
    let location

    try {
      location = parseLocation(formData)
    } catch (err) {
      if (err.message === 'invalid_format') {
        formError({
          format: 'Invalid location format.'
        })
      } else {
        setError(err)
      }

      return
    }

    try {
      const site = await client('admin/sites/create', {
        name,
        locations: [location]
      })
      navigate(`/sites/${site.id}`)
    } catch (err) {
      if (err.message === 'validation_error') {
        formError(err.props)
      } else {
        setError(err)
      }
    }
  }

  return (
    <div className='box'>
      <form onSubmit={handleSubmit}>
        <TextField
          label='Name'
          name='name'
          placeholder='Name'
          required={true}
          {...getFieldProps()}
        />
        <LocationForm getFieldProps={getFieldProps} formData={formData} />
        <br />
        <ContextMenu>
          <Button type='submit'>Create</Button>
        </ContextMenu>
      </form>
    </div>
  )
}
