import EventEmitter from 'eventemitter3'
import { useState, useEffect, useCallback, useRef } from 'react'

const events = new EventEmitter()

export function usePersistentState(key, initialState) {
  key = `state:${key}`

  if (localStorage[key]) {
    try {
      initialState = JSON.parse(localStorage[key])
    } catch (err) {
      console.error(err)
    }
  }

  const [state, setState] = useState(initialState)

  const eventListener = useRef(null)

  useEffect(() => {
    if (eventListener.current) {
      return
    }

    eventListener.current = events.addListener(key, (newState) => {
      if (!eventListener.current) {
        return
      }
      setState((s) => ({ ...s, ...newState }))
    })

    return () => {
      if (eventListener.current) {
        events.removeListener(key, eventListener.current)
        eventListener.current = null
      }
    }
  }, [key])

  useEffect(() => {
    localStorage[key] = JSON.stringify(state)
  }, [key, state])

  const updateState = useCallback(
    (newState) => events.emit(key, newState),
    [key]
  )

  return [state, updateState]
}
