import React from 'react'
import { useNavigate } from 'react-router-dom'

import ContextMenu from '../../components/ContextMenu'
import DropdownMenu, { DropdownItem } from '../../components/DropdownMenu'
import UserTypesList from '../../components/UserTypesList'

export default function () {
  const navigate = useNavigate()

  return (
    <div className='container'>
      <ContextMenu renderTitle={() => <h1 className='title'>System</h1>}>
        <DropdownMenu label='Actions'>
          <DropdownItem onClick={() => navigate('/system/user-types/create')}>
            Create a new user type
          </DropdownItem>
        </DropdownMenu>
      </ContextMenu>

      <h2 className='subtitle'>User Types</h2>
      <UserTypesList />
    </div>
  )
}
