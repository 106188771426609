import React from 'react'
import { useNavigate } from 'react-router-dom'

import { useApi, useError, useApiRequest, useForm } from '../../hooks'
import TextField from '../Form/TextField'
import DropdownField from '../Form/DropdownField'
import Button from '../Button'
import ContextMenu from '../ContextMenu'
import Spinner from '../Spinner'

export default function ({ organizationId }) {
  const navigate = useNavigate()
  const [client] = useApi()
  const [organization] = useApiRequest(`admin/organizations/${organizationId}`)
  const [organizations] = useApiRequest(
    'admin/organizations?limit=250&sortBy=name&sortOrder=asc'
  )

  const setError = useError()

  const [getFieldProps, formData, formError] = useForm()

  if (!organization || !organizations) {
    return <Spinner />
  }

  organizations.items = organizations.items.filter(
    ({ id }) => id !== organizationId
  )

  async function handleSubmit(e) {
    e.preventDefault()

    let { dispatchOrganizationId } = formData

    try {
      await client(
        `admin/organizations/${organizationId}/dispatch-bindings/create`,
        {
          dispatchOrganizationId
        }
      )

      navigate(`/organizations/${organization.id}`)
    } catch (err) {
      if (err.message === 'validation_error') {
        formError(err.props)
      } else {
        setError(err)
      }
    }
  }

  return (
    <div className='box'>
      <form onSubmit={handleSubmit}>
        <TextField
          label='Organization'
          value={organization.name}
          readOnly={true}
        />
        <DropdownField
          name='dispatchOrganizationId'
          label='Dispatch Organization'
          {...getFieldProps()}>
          <option />

          {organizations.items.map(({ id, name }) => (
            <option value={id} key={id}>
              {name}
            </option>
          ))}
        </DropdownField>
        <ContextMenu>
          <Button type='submit'>Create</Button>
        </ContextMenu>
      </form>
    </div>
  )
}
