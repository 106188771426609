import React from 'react'

import { useApiRequest } from '../../hooks'
import UserDetails from '../UserDetails'

export default function () {
  const [user] = useApiRequest('admin/profile')

  return <div>{user && <UserDetails id={user.id} />}</div>
}
