import React from 'react'
import { useWebSocket, usePersistentState } from '../../../hooks'

import Spinner from '../../Spinner'

import './styles.scss'

function SidebarItem({ icon, label, active, onClick, subdued }) {
  return (
    <div
      className={`dispatch-sidebar-item ${active ? 'active' : ''} ${
        subdued && 'subdued'
      }`}
      onClick={onClick}>
      <div className='dispatch-sidebar-item-icon'>
        <i className={`fas fa-${icon}`} />
      </div>
      <span>{label}</span>
    </div>
  )
}

export default function ({ activePanel, onItemClick }) {
  const wsConnected = useWebSocket()[1]
  const [{ expanded }, setSidebarState] = usePersistentState(
    'dispatch-sidebar',
    {
      expanded: false
    }
  )

  return (
    <div className={`dispatch-sidebar ${expanded ? 'expanded' : ''}`}>
      <div className='dispatch-sidebar-header'>
        <div
          className={`dispatch-sidebar-connection ${
            wsConnected ? 'connected' : 'disconnected'
          }`}>
          {wsConnected && (
            <div style={{ display: 'flex' }}>
              <i
                className='fas fa-link'
                style={{ transform: 'rotate(45deg)' }}
              />{' '}
              {expanded && 'Connected'}
            </div>
          )}
          {!wsConnected && (
            <div style={{ display: 'flex' }}>
              <Spinner />
              {expanded && 'Connecting...'}
            </div>
          )}
        </div>
      </div>

      <SidebarItem
        icon='map'
        label='Map'
        onClick={() => onItemClick()}
        active={!activePanel}
      />
      <SidebarItem
        icon='exclamation-triangle'
        label='Incidents'
        onClick={() => onItemClick('ongoing-incidents')}
        active={activePanel === 'ongoing-incidents'}
      />
      <SidebarItem
        icon='comments'
        label='Chat'
        onClick={() => onItemClick('chat')}
        active={activePanel === 'chat'}
      />
      <SidebarItem
        icon='clipboard-list'
        label='Duty Status'
        onClick={() => onItemClick('available-units')}
        active={activePanel === 'available-units'}
        subdued
      />

      <div className='dispatch-sidebar-footer'>
        <div className='dispatch-sidebar-expand'>
          <button
            className='button'
            onClick={() => setSidebarState({ expanded: !expanded })}>
            <i
              className={`fas fa-chevron-circle-${expanded ? 'left' : 'right'}`}
            />
            <span>{expanded && 'Hide'}</span>
          </button>
        </div>
      </div>
    </div>
  )
}
