import React from 'react'

export function Row({ children, ...props }) {
  return (
    <tr style={{ cursor: props.onClick ? 'pointer' : null }} {...props}>
      {children}
    </tr>
  )
}

export function RowData({ children, ...props }) {
  return <td {...props}>{children}</td>
}

export default function ({ columns, children, className, style }) {
  return (
    <div style={{ width: '100%' }}>
      <table className={`table ${className}`} style={style}>
        <thead>
          <tr>
            {columns.map((name) => (
              <th key={name} className='has-text-info'>
                {name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className='is-family-monospace'>
          {children}
          {(!children || children.length === 0) && (
            <tr>
              <td colSpan='100'>No data</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}
