import React, { useState } from 'react'
import Control from '../Control'
import { IconPicker } from 'react-fa-icon-picker'
export { IconPickerItem } from 'react-fa-icon-picker'

export default React.forwardRef(
  (
    {
      name,
      label,
      iconColor,
      onChange,
      renderAddons,
      getValidationError,
      defaultValue,
      ...props
    },
    ref
  ) => {
    const error = getValidationError && getValidationError(props.name)
    const [icon, setIcon] = useState(defaultValue || 'FaQuestion')

    function handleChange(icon) {
      setTimeout(() => {
        setIcon(icon)
        onChange(icon)
      }, 0)
    }

    return (
      <Control
        label={label}
        renderAddons={renderAddons}
        className={`${error ? 'has-icons-right' : ''}`}>
        <div className='field has-addons'>
          <div className='control'>
            <input
              className={`input ${error ? 'is-danger' : ''} `}
              type='text'
              name={name}
              ref={ref}
              value={icon}
              readOnly={true}
            />
          </div>
          <div className='control'>
            <IconPicker
              value={icon}
              onChange={(i) => handleChange(i)}
              buttonIconStyles={{ color: iconColor }}
              buttonStyles={{
                maxHeight: '40px',
                borderRadius: 0,
                paddingTop: '10px'
              }}
            />
          </div>
        </div>
        {error && (
          <span className='icon is-small is-right'>
            <i className='fas fa-exclamation-triangle has-text-danger' />
          </span>
        )}
        {error && <p className='help is-danger'>{error}</p>}
      </Control>
    )
  }
)
