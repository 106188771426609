import React from 'react'

import CreateOrganization from '../../../components/CreateOrganization'

export default function () {
  return (
    <div className='container'>
      <h1 className='title'>Create a new organization</h1>
      <CreateOrganization />
    </div>
  )
}
