import React from 'react'

import { useApi, useForm, useError } from '../../hooks'
import TextField from '../Form/TextField'
import Button from '../Button'
import ContextMenu from '../ContextMenu'

export default function ({ agent, onFinishEditing }) {
  const [client] = useApi()
  const setError = useError()

  const [getFieldProps, formData, formError] = useForm()

  async function handleSubmit(e) {
    e.preventDefault()

    try {
      await client(`admin/agents/${agent.id}`, formData)
      onFinishEditing()
    } catch (err) {
      if (err.message === 'validation_error') {
        formError(err.props)
      } else {
        setError(err)
      }
    }
  }

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <TextField
          name='name'
          label='Name'
          defaultValue={agent.name}
          {...getFieldProps()}
        />
        <ContextMenu>
          <Button type='submit'>Update</Button>
        </ContextMenu>
      </form>
    </div>
  )
}
