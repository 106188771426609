const mapContainer = {
  position: 'absolute',
  top: '52px',
  left: 0,
  width: '100%',
  height: 'calc(100vh - 52px)'
}

const searchBox = {
  boxSizing: 'border-box',
  border: '1px solid transparent',
  width: '500px',
  height: '40px',
  padding: '0 12px',
  borderRadius: '3px',
  boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
  fontSize: '16px',
  outline: 'none',
  textOverflow: 'ellipses',
  position: 'absolute',
  left: '52px',
  top: '12px',
  transition: 'left 0.2s, opacity 1s'
}

export default {
  mapContainer,
  searchBox
}
