import React from 'react'

export default function ({ renderTitle, children }) {
  if (!Array.isArray(children)) {
    children = [children]
  }

  return (
    <nav className='level context-menu'>
      <div className='level-left'>{renderTitle && renderTitle()}</div>
      <div className='level-right'>
        {children.map((child, index) => (
          <div className='level-item' key={index}>
            {child}
          </div>
        ))}
      </div>
    </nav>
  )
}
