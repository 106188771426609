import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react'
import { usePersistentState, useFlashBackground } from '../../hooks'
import NotificationsRenderer from '../NotificationsRenderer'
import DispatchMap, { MapSelection, useMapSettings } from './DispatchMap'
import DispatchSideBar from './DispatchSideBar'
import DispatchIncidentAlert from './DispatchIncidentAlert'
import DispatchPanel from './DispatchPanel'
import CreateIncidentPanel from './Panels/CreateIncident'
import OngoingIncidentsPanel from './Panels/OngoingIncidents'
import RespondingUnitsPanel from './Panels/RespondingUnits'
import AvailableUnitsPanel from './Panels/AvailableUnits'
import ChatPanel from './Panels/Chat'

import './styles.scss'

function panelTitle(activePanel) {
  switch (activePanel) {
    case 'new-incident':
      return 'Create a new incident'
    case 'ongoing-incidents':
      return 'Incidents'
    case 'responding-units':
      return 'Responding units'
    case 'available-units':
      return 'Duty status'
    case 'map-settings':
      return 'Map settings'
    default:
      return ''
  }
}

export default function ({
  availableUnits,
  deviceLocations,
  incidents,
  userTypes,
  dispatchBindings,
  profile
}) {
  const [mapSettings] = useMapSettings()
  const [activePanel, setActivePanel] = useState('new-incident')
  const [secondaryPanel, setSecondaryPanel] = useState()
  const [{ expanded }, setMenuState] = usePersistentState('dispatch-menu', {
    expanded: true
  })

  const [sidebarWidth, setSidebarWidth] = useState(1e10)
  const [mapCenter, setMapCenter] = useState({ lat: 0.0, lng: 0.0 })
  const [mapZoom, setMapZoom] = useState(15)
  const [mapSelection, setMapSelection] = useState()
  const [customActiveChannel, setCustomActiveChannel] = useState()
  const [selectedIncident, setSelectedIncident] = useState()
  const [directionsDestination, setDirectionsDestination] = useState()

  const directionsOrigin = useMemo(() => {
    const deviceData = Object.values(deviceLocations).find(
      ({ user: { id } }) => id === profile.id
    )

    if (!deviceData) {
      return [41.54465187, -90.55797034]
      //      return null
    }

    return deviceData && deviceData.loc
  }, [deviceLocations, profile])

  useEffect(() => {
    if (mapSettings.defaultMapCenter) {
      setMapCenter(mapSettings.defaultMapCenter)
    } else if (mapCenter.lat === 0.0 && mapCenter.lng === 0.0) {
      navigator.geolocation.getCurrentPosition(
        ({ coords: { latitude, longitude } }) =>
          setMapCenter({ lat: latitude, lng: longitude })
      )
    }

    if (mapSettings.defaultMapZoom) {
      setMapZoom(mapSettings.defaultMapZoom)
    }
  }, [mapSettings])

  const onMapClick = useCallback(
    ({ loc, placeId }) => {
      setMapSelection(new MapSelection(loc, placeId))
    },
    [setMapSelection]
  )

  const sidebarContainer = useRef(null)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (sidebarContainer.current) {
      const width = sidebarContainer.current.getBoundingClientRect().width
      if (width !== sidebarWidth) {
        setSidebarWidth(width)
      }
    }
  })

  const openSecondaryPanel = useCallback(
    (component, title) => {
      setMenuState({ expanded: true })
      setSecondaryPanel(null)

      if (!component) {
        return
      }

      setTimeout(() => {
        setSecondaryPanel(
          <DispatchPanel
            onHideClick={() => setSecondaryPanel(null)}
            style={{ width: '600px' }}
            title={title}>
            {component}
          </DispatchPanel>
        )
      }, 0)
    },
    [setSecondaryPanel, setMenuState]
  )

  const onIncidentCreated = useCallback((incident) => {
    setActivePanel(null)
    setMapSelection(null)
    setTimeout(() => setActivePanel('new-incident'), 250)
  }, [])

  useEffect(() => {
    setSecondaryPanel(null)
  }, [activePanel])

  const [addressSearchBoxRef, flashAddressSearchBox] = useFlashBackground()

  return (
    <>
      <div
        className='dispatch-sidebar-container'
        style={{ left: expanded ? 0 : -sidebarWidth + 16 }}
        ref={sidebarContainer}>
        <DispatchSideBar
          onItemClick={setActivePanel}
          activePanel={activePanel}
        />
        {activePanel && (
          <DispatchPanel
            title={activePanel !== 'chat' && panelTitle(activePanel)}
            onHideClick={() => setActivePanel(null)}
            style={{ width: activePanel === 'chat' && '800px' }}>
            {activePanel === 'new-incident' && userTypes && (
              <CreateIncidentPanel
                mapSelection={mapSelection}
                userTypes={userTypes.items}
                profile={profile}
                dispatchBindings={dispatchBindings}
                onIncidentCreated={onIncidentCreated}
                addressSearchBoxRef={addressSearchBoxRef}
                flashAddressSearchBox={flashAddressSearchBox}
              />
            )}
            {activePanel === 'ongoing-incidents' && (
              <OngoingIncidentsPanel
                incidents={incidents}
                setActivePanel={setActivePanel}
                openSecondaryPanel={openSecondaryPanel}
                setMapCenter={setMapCenter}
                setMapZoom={setMapZoom}
                mapSelection={mapSelection}
                dispatchBindings={dispatchBindings}
                profile={profile}
                setCustomActiveChannel={setCustomActiveChannel}
                selectedIncident={selectedIncident}
                setSelectedIncident={setSelectedIncident}
                setDirectionsDestination={setDirectionsDestination}
                userTypes={userTypes.items}
              />
            )}
            {activePanel === 'responding-units' && (
              <RespondingUnitsPanel
                incidents={incidents}
                deviceLocations={deviceLocations}
                availableUnits={availableUnits}
                openSecondaryPanel={openSecondaryPanel}
                setMapCenter={setMapCenter}
              />
            )}
            {activePanel === 'available-units' && (
              <AvailableUnitsPanel
                availableUnits={availableUnits}
                openSecondaryPanel={openSecondaryPanel}
                setMapCenter={setMapCenter}
              />
            )}
            <div
              style={{
                display: activePanel === 'chat' ? 'flex' : 'none',
                width: '100%'
              }}>
              <ChatPanel
                customActiveChannel={customActiveChannel}
                setCustomActiveChannel={setCustomActiveChannel}
              />
            </div>
          </DispatchPanel>
        )}
        {secondaryPanel}
        <div className='dispatch-sidebar-chevron'>
          <button
            type='button'
            className='button'
            onClick={() => setMenuState({ expanded: !expanded })}>
            <i className={`fa fa-chevron-${expanded ? 'left' : 'right'}`} />
          </button>
        </div>
      </div>
      <DispatchMap
        center={mapCenter}
        setMapCenter={setMapCenter}
        zoom={mapZoom}
        setMapZoom={setMapZoom}
        deviceLocations={deviceLocations}
        incidents={incidents}
        mapSelection={mapSelection}
        onMapClick={onMapClick}
        openSecondaryPanel={openSecondaryPanel}
        selectedIncident={selectedIncident}
        setSelectedIncident={setSelectedIncident}
        leftMargin={expanded ? sidebarWidth : 16}
        addressSearchBoxRef={addressSearchBoxRef}
        directionsOrigin={directionsOrigin}
        directionsDestination={directionsDestination}
      />
      <NotificationsRenderer
        containerClassName='dispatch-notifications'
        containerStyle={{ left: expanded ? sidebarWidth : 16 }}
        itemClassName='notification'
      />
      <DispatchIncidentAlert incidents={incidents} />
    </>
  )
}
