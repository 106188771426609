import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext
} from 'react'

import Keycloak from 'keycloak-js'
import { ReactKeycloakProvider } from '@react-keycloak/web'

import { createApiClient, createWebSocket } from './../api'
import { useError } from './util'

import Spinner from '../components/Spinner'

const INIT_OPTIONS = { onLoad: 'login-required' }

const { KEYCLOAK_URL, KEYCLOAK_REALM, KEYCLOAK_CLIENT_ID } = window.appConfig

export const keycloak = new Keycloak({
  url: KEYCLOAK_URL,
  realm: KEYCLOAK_REALM,
  clientId: KEYCLOAK_CLIENT_ID
})

export const ApiContext = React.createContext()

export function ApiProvider({ children }) {
  const [state, setState] = useState({
    client: null,
    ws: null
  })

  const handleTokens = useCallback(
    (tokens) => {
      setState((s) => ({
        ...s,
        ...tokens,
        client: createApiClient(tokens.token)
      }))
    },
    [setState]
  )

  const handleEvent = useCallback(
    (event) => {
      if (event === 'onReady') {
        setState((s) => ({
          ...s,
          client: createApiClient(s.token),
          ws: createWebSocket(s.token)
        }))
      }
    },
    [setState]
  )

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={INIT_OPTIONS}
      onTokens={handleTokens}
      onEvent={handleEvent}
      LoadingComponent={<Spinner />}>
      <ApiContext.Provider value={state}>{children}</ApiContext.Provider>
    </ReactKeycloakProvider>
  )
}

export function useApi() {
  const { client } = useContext(ApiContext)
  return [client]
}

export function useApiToken() {
  const { token } = useContext(ApiContext)
  return token
}

export function useApiRequest(endpoint, payload, dependencies) {
  const [client] = useApi()

  const [data, setData] = useState(null)
  const setError = useError()

  const fetching = useRef(false)

  const fetchData = useCallback(
    async (isMounted) => {
      if (fetching.current || !client) {
        return
      }

      fetching.current = true

      try {
        const response = await client(endpoint, payload)
        fetching.current = false
        if (isMounted) {
          setData(response)
        }
      } catch (err) {
        fetching.current = false
        if (isMounted) {
          setError(err)
        }
      }
    },
    [client, endpoint, payload, setError]
  )

  useEffect(() => {
    let isMounted = true
    if (dependencies) {
      for (const dependency of dependencies) {
        if (!dependency) {
          return
        }
      }
    }

    if (data) {
      return
    }

    fetchData(isMounted)
    return () => (isMounted = false)
  })

  return [data, () => fetchData(true)]
}

export function useWebSocket() {
  const [state, setState] = useState({
    connected: false,
    authenticated: false
  })
  const { ws } = useContext(ApiContext)

  useEffect(() => {
    if (!ws) {
      return
    }

    ws.on('connected', () =>
      setState({
        connected: true,
        authenticated: false
      })
    )
    ws.on('authenticated', () =>
      setState({
        connected: true,
        authenticated: true
      })
    )
    ws.on('disconnected', () =>
      setState({
        connected: false,
        authenticated: false
      })
    )
    setState({
      connected: ws.isConnected(),
      authenticated: ws.isAuthenticated()
    })
  }, [ws, setState])

  return [ws, state.connected, state.authenticated]
}

export function useEventStream(name, dispatch, reinit) {
  const [ws, , wsAuthenticated] = useWebSocket()
  const [state, setState] = useState({
    stream: null,
    connectedListener: null,
    eventListener: null
  })

  const busy = useRef(false)

  useEffect(() => {
    if (!ws || !wsAuthenticated) {
      busy.current = false
      return
    }

    if (!busy.current) {
      busy.current = true
      const stream = ws.createEventStream()
      const connectedListener = stream.addListener('connected', reinit)
      const eventListener = stream.addListener('event', dispatch)
      stream
        .connect(name)
        .then(() =>
          setState({
            stream,
            connectedListener,
            eventListener
          })
        )
        .catch((err) => console.error(err))
    }

    return () => {
      const { stream, connectedListener, eventListener } = state

      if (!stream) {
        return
      }

      stream.removeListener('connected', connectedListener)
      stream.removeListener('event', eventListener)
      stream.disconnect().catch((err) => console.error(err))
      setState({
        stream: null,
        connectedListener: null,
        eventListener: null
      })
    }
  }, [ws, wsAuthenticated, dispatch, reinit, name, state])
}

export function usePagination(opts) {
  opts = opts || {}
  const localStorageKey = `pagination-${opts.uid}`

  const [client] = useApi()

  if (localStorage[localStorageKey]) {
    try {
      opts = {
        ...opts,
        ...JSON.parse(localStorage[localStorageKey])
      }
    } catch (err) {
      console.error(err)
    }
  }

  const [state, setState] = useState({
    currentPage: opts.currentPage || 0,
    itemsPerPage: opts.itemsPerPage,
    sortBy: opts.sortBy || 'id',
    sortOrder: opts.sortOrder || 'desc',
    persistent: opts.persistent,
    totalCount: 0,
    items: null
  })
  const [loading, setLoading] = useState(false)

  const setError = useError()
  const fetching = useRef(false)

  const serialize = useCallback(
    (opts) => {
      localStorage[localStorageKey] = JSON.stringify({
        sortBy: opts.sortBy,
        sortOrder: opts.sortOrder,
        currentPage: opts.currentPage,
        itemsPerPage: opts.itemsPerPage
      })
    },
    [localStorageKey]
  )

  const fetchItems = useCallback(
    async (pageIndex) => {
      if (fetching.current) {
        return
      }

      fetching.current = true
      setLoading(true)

      const params = new URLSearchParams({
        offset: pageIndex * state.itemsPerPage,
        limit: state.itemsPerPage,
        sortBy: state.sortBy,
        sortOrder: state.sortOrder
      }).toString()

      let response
      try {
        response = await client(`${opts.endpoint}?${params}`)
      } catch (err) {
        setError(err)
        setLoading(false)
        return
      }

      fetching.current = false

      const numPages = Math.ceil(response.count / state.itemsPerPage)
      if (pageIndex > numPages - 1) {
        delete localStorage[localStorageKey]
      }

      const newState = {
        ...state,
        currentPage: pageIndex,
        totalCount: response.count,
        items: response.items
      }

      if (opts.persistent) {
        serialize(newState)
      }

      setState(newState)
      setLoading(false)
    },
    [
      client,
      state,
      setState,
      setError,
      opts.endpoint,
      opts.persistent,
      serialize,
      localStorageKey
    ]
  )

  const oldStateRef = useRef(state)

  useEffect(
    function () {
      const oldState = oldStateRef.current
      if (
        state.items === null ||
        oldState.sortBy !== state.sortBy ||
        oldState.sortOrder !== state.sortOrder ||
        oldState.itemsPerPage !== state.itemsPerPage
      ) {
        fetchItems(state.currentPage)
      }

      oldStateRef.current = state
    },
    [fetchItems, state]
  )

  function setOpts(opts) {
    if (opts.persistent) {
      serialize(opts)
    }

    if (opts.itemsPerPage !== oldStateRef.current.itemsPerPage) {
      opts.currentPage = 0
    }

    setState(opts)
  }

  return [
    state.items,
    state.currentPage,
    state.totalCount,
    loading,
    fetchItems,
    setOpts,
    state
  ]
}

export function usePermissions(resource, action) {
  const [client] = useApi()
  const [permission, setPermission] = useState()

  useEffect(() => {
    async function checkPermissions() {
      const res = await client(
        `v2/profile/permissions?resource=${resource}&action=${action}`
      )

      setPermission(res)
    }

    checkPermissions()
  }, [client, setPermission, resource, action])

  return permission
}
