import React from 'react'

import Control from '../Control'

export default React.forwardRef(
  ({ label, renderAddons, getValidationError, ...props }, ref) => {
    const error = getValidationError && getValidationError(props.name)

    return (
      <Control
        label={label}
        renderAddons={renderAddons}
        className={`${error ? 'has-icons-right' : ''}`}>
        <input
          className={`input ${error ? 'is-danger' : ''} `}
          {...props}
          ref={ref}
        />
        {error && (
          <span className='icon is-small is-right'>
            <i className='fas fa-exclamation-triangle has-text-danger' />
          </span>
        )}
        {error && <p className='help is-danger'>{error}</p>}
      </Control>
    )
  }
)
