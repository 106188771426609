import React from 'react'
import { useNavigate } from 'react-router-dom'

import { useApi, useError, useApiRequest, useForm } from '../../hooks'
import TextField from '../Form/TextField'
import Button from '../Button'
import Columns from '../Columns'
import ContextMenu from '../ContextMenu'
import Spinner from '../Spinner'

export default function ({ organizationId }) {
  const navigate = useNavigate()
  const [client] = useApi()
  const [personalDataTypes] = useApiRequest('admin/users/personal-data-types')
  const [organization] = useApiRequest(`admin/organizations/${organizationId}`)
  const setError = useError()

  const [getFieldProps, formData, formError] = useForm()

  if (!organization || !personalDataTypes) {
    return <Spinner />
  }

  async function handleSubmit(e) {
    e.preventDefault()

    let { name, email, ...personalData } = formData

    personalData = Object.fromEntries(
      Object.entries(personalData).filter(([_, value]) => value !== '')
    )

    try {
      const user = await client('admin/users/create', {
        organizationId,
        email,
        personalData
      })

      navigate(`/users/${user.id}`)
    } catch (err) {
      if (err.message === 'validation_error') {
        formError(err.props)
      } else {
        setError(err)
      }
    }
  }

  return (
    <div className='box'>
      <form onSubmit={handleSubmit}>
        <Columns>
          <TextField
            label='Organization'
            value={organization.name}
            readOnly={true}
            required={true}
          />
          <TextField
            label='Email'
            name='email'
            type='email'
            placeholder='Email'
            required={true}
            {...getFieldProps()}
          />
        </Columns>
        <Columns>
          {personalDataTypes.map((type) => (
            <TextField
              key={type.id}
              name={type.typeName}
              label={type.displayName}
              placeholder={type.displayName}
              {...getFieldProps()}
            />
          ))}
        </Columns>
        <ContextMenu>
          <Button type='submit'>Create</Button>
        </ContextMenu>
      </form>
    </div>
  )
}
