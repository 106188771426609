import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useApi, useForm, useError } from '../../hooks'

import Button from '../Button'
import TextField from '../Form/TextField'
import TextAreaField from '../Form/TextAreaField'
import ContextMenu from '../ContextMenu'
import LocationForm, { parseLocation } from '../LocationForm'
import DropdownField from '../Form/DropdownField'
import { STATUS_TYPES } from '../EditIncidentStatus'

function capitalize(s) {
  return `${s.slice(0, 1).toUpperCase()}${s.slice(1)}`
}

export default function () {
  const navigate = useNavigate()
  const [client] = useApi()
  const setError = useError()

  const [getFieldProps, formData, formError] = useForm()

  async function handleSubmit(e) {
    e.preventDefault()

    const { name, description, status } = formData
    let location

    try {
      location = parseLocation(formData)
    } catch (err) {
      if (err.message === 'invalid_format') {
        formError({
          format: 'Invalid location format.'
        })
      } else {
        setError(err)
      }

      return
    }

    try {
      const incident = await client('admin/incidents/create', {
        name,
        description,
        status,
        locations: [location],
        userTypeIds: [1]
      })
      navigate(`/incidents/${incident.id}`)
    } catch (err) {
      if (err.message === 'validation_error') {
        formError(err.props)
      } else {
        setError(err)
      }
    }
  }

  return (
    <div className='box'>
      <form onSubmit={handleSubmit}>
        <TextField
          label='Name'
          name='name'
          required={true}
          {...getFieldProps()}
        />
        <TextAreaField
          label='Description'
          name='description'
          {...getFieldProps()}
        />
        <DropdownField
          name='status'
          label='Status'
          defaultValue='pending'
          required={true}
          {...getFieldProps()}>
          {STATUS_TYPES.map((type) => (
            <option value={type} key={type}>
              {capitalize(type)}
            </option>
          ))}
        </DropdownField>
        <LocationForm getFieldProps={getFieldProps} formData={formData} />
        <br />
        <ContextMenu>
          <Button type='submit'>Create</Button>
        </ContextMenu>
      </form>
    </div>
  )
}
