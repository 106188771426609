import React from 'react'
import { useNavigate } from 'react-router-dom'

import { useApi, useApiRequest, useError, useForm } from '../../hooks'
import TextField from '../Form/TextField'
import DropdownField from '../Form/DropdownField'
import Button from '../Button'
import Columns from '../Columns'
import ContextMenu from '../ContextMenu'

export default function () {
  const navigate = useNavigate()
  const [sites] = useApiRequest('admin/sites?limit=250')
  const [client] = useApi()
  const setError = useError()

  const [getFieldProps, formData, formError] = useForm()

  async function handleSubmit(e) {
    e.preventDefault()

    try {
      const agent = await client('admin/agents/create', formData)
      navigate(`/agents/${agent.id}`)
    } catch (err) {
      if (err.message === 'validation_error') {
        formError(err.props)
      } else {
        setError(err)
      }
    }
  }

  return (
    <div className='box'>
      <form onSubmit={handleSubmit}>
        <Columns>
          <TextField
            label='Name'
            name='name'
            placeholder='Name'
            required={true}
            {...getFieldProps()}
          />
          <DropdownField name='siteId' label='Site' {...getFieldProps()}>
            <option />
            {sites &&
              sites.items.map((site) => (
                <option value={site.id} key={site.id}>
                  {site.name}
                </option>
              ))}
          </DropdownField>
        </Columns>
        <ContextMenu>
          <Button type='submit'>Create</Button>
        </ContextMenu>
      </form>
    </div>
  )
}
