import React, { useCallback, useMemo } from 'react'

import { PanelSection } from '../../DispatchPanel'
import UserDetails from '../../../UserDetails'
import DataTable, { Row, RowData } from '../../../DataTable'
import { useDispatchData } from '../../DispatchDataWrapper'

function displayAvailability(status) {
  switch (status) {
    case 'on-duty':
      return 'On-Duty'
    case 'off-duty':
      return 'Off-Duty'
    default:
      return 'Unknown'
  }
}

function TableRow({
  user,
  openDetails,
  setMapCenter,
  deviceLocationsByUserId
}) {
  const displayName =
    user.personalData.displayName?.value ||
    user.personalData.fullName?.value ||
    `User ${user.id}`

  const loc = deviceLocationsByUserId[user.id]

  return (
    <Row>
      <RowData style={{ cursor: 'pointer' }} onClick={() => openDetails(user)}>
        {displayName}
      </RowData>
      <RowData>{displayAvailability(user.availability)}</RowData>
      <RowData>
        <i
          title={!loc && 'Location data not available'}
          className='fa fa-map-marker-alt'
          style={{
            cursor: loc ? 'pointer' : 'default',
            fontSize: '1.25rem',
            opacity: loc ? 1.0 : 0.25
          }}
          onClick={() => loc && setMapCenter({ lng: loc[0], lat: loc[1] })}
        />
      </RowData>
    </Row>
  )
}

const COLUMNS = ['Name', 'Status', 'Location']

export default function ({ availableUnits, openSecondaryPanel, setMapCenter }) {
  const users = Object.values(availableUnits)
  const onDuty = users.filter((user) => user.availability === 'on-duty')
  const offDuty = users.filter((user) => user.availability !== 'on-duty')

  const { deviceLocations } = useDispatchData()

  const deviceLocationsByUserId = useMemo(
    () =>
      Object.values(deviceLocations).reduce(
        (acc, { user, loc }) => ({
          ...acc,
          [user.id]: loc
        }),
        {}
      ),
    [deviceLocations]
  )

  const openDetails = useCallback(
    (user) =>
      openSecondaryPanel(
        <UserDetails id={user.id} />,
        user.personalData?.displayName?.value || `User #${user.id}`
      ),
    [openSecondaryPanel]
  )

  return (
    <div style={{ width: '100%' }}>
      <PanelSection title='On-Duty' bodyStyle={{ padding: 0 }}>
        <DataTable
          columns={COLUMNS}
          style={{ fontSize: '0.9rem' }}
          className='is-fullwidth'>
          {onDuty.map((user) => (
            <TableRow
              key={user.id}
              user={user}
              openDetails={openDetails}
              setMapCenter={setMapCenter}
              deviceLocationsByUserId={deviceLocationsByUserId}
            />
          ))}
        </DataTable>
      </PanelSection>
      <PanelSection title='Off-Duty' bodyStyle={{ padding: 0 }}>
        <DataTable
          columns={COLUMNS}
          style={{ fontSize: '0.9rem' }}
          className='is-fullwidth'>
          {offDuty.map((user) => (
            <TableRow
              key={user.id}
              user={user}
              openDetails={openDetails}
              setMapCenter={setMapCenter}
              deviceLocationsByUserId={deviceLocationsByUserId}
            />
          ))}
        </DataTable>
      </PanelSection>
    </div>
  )
}
