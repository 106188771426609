import React from 'react'

export default function ({ value }) {
  const date = new Date(value)
  if (isNaN(date.getTime())) {
    return <span>Invalid date</span>
  }

  return (
    <>
      <span>{date.toLocaleDateString('en-US', {})}</span>{' '}
      <span>{date.toLocaleTimeString('en-US', { hour12: false })}</span>
    </>
  )
}
