import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useApi, useError, useApiRequest, useForm } from '../../hooks'

import TextField from '../Form/TextField'
import Columns from '../Columns'
import DropdownMenu, { DropdownItem } from '../DropdownMenu'
import ContextMenu from '../ContextMenu'
import DataTable, { Row, RowData } from '../DataTable'
import Spinner from '../Spinner'

function capitalize(s) {
  return s[0].toUpperCase() + s.slice(1)
}

function UserRoleGrantsList({ organizationId, roleId, grants, reloadData }) {
  const [client] = useApi()
  const setError = useError()

  async function deleteGrant(grantId) {
    try {
      if (window.confirm('Are you sure you wish to delete this grant?')) {
        await client(
          `admin/organizations/${organizationId}/user-roles/${roleId}/delete-grant`,
          { grantId }
        )

        await reloadData()
      }
    } catch (err) {
      setError(err)
    }
  }

  grants = grants.sort((a, b) => a.resource.localeCompare(b.resource))

  return (
    <DataTable
      columns={[
        'ID',
        'Resource',
        'Action',
        'Possession',
        'Attributes',
        'Actions'
      ]}
      className='is-fullwidth'>
      {grants.map((grant) => (
        <Row key={grant.id}>
          <RowData>{grant.id}</RowData>
          <RowData>{grant.resource}</RowData>
          <RowData>{capitalize(grant.action)}</RowData>
          <RowData>{capitalize(grant.possession)}</RowData>
          <RowData>
            {grant.attributes.map((attr) => (
              <div key={attr} className='is-size-7'>
                {attr}
              </div>
            ))}
          </RowData>
          <RowData>
            <DropdownMenu label='Actions' small={true}>
              <DropdownItem onClick={() => deleteGrant(grant.id)}>
                Delete
              </DropdownItem>
            </DropdownMenu>
          </RowData>
        </Row>
      ))}
    </DataTable>
  )
}

export default function ({ id, organizationId }) {
  const navigate = useNavigate()
  const [client] = useApi()
  const [organization] = useApiRequest(`admin/organizations/${organizationId}`)
  const [userRole, reloadData] = useApiRequest(
    `admin/organizations/${organizationId}/user-roles/${id}`
  )
  const setError = useError()

  const [, formData, formError] = useForm()

  if (!organization || !userRole) {
    return <Spinner />
  }

  async function handleSubmit(e) {
    e.preventDefault()

    let { ...permissions } = formData
    permissions = Object.keys(permissions).filter((key) => permissions[key])

    try {
      await client(`admin/organizations/${organizationId}/user-roles/update`, {
        id,
        permissions
      })

      navigate(`/organizations/${organization.id}`)
    } catch (err) {
      if (err.message === 'validation_error') {
        formError(err.props)
      } else {
        setError(err)
      }
    }
  }

  async function deleteUserRole() {
    try {
      if (window.confirm('Are you sure you want to delete this role?')) {
        await client(
          `admin/organizations/${organizationId}/user-roles/delete`,
          {
            roleId: id
          }
        )
      }

      navigate(`/organizations/${organizationId}`)
    } catch (err) {
      setError(err)
    }
  }

  return (
    <div>
      <ContextMenu
        renderTitle={() => (
          <h1 className='title'>Editing role "{userRole.name}"</h1>
        )}>
        <DropdownMenu label='Actions'>
          <DropdownItem
            onClick={() =>
              navigate(
                `/user-role-grants/create?organizationId=${organizationId}&roleId=${id}`
              )
            }>
            Add a new grant
          </DropdownItem>
          <DropdownItem
            onClick={() =>
              navigate(
                `/user-roles/copy/${id}?organizationId=${organizationId}`
              )
            }>
            Copy role to another organization
          </DropdownItem>
          <DropdownItem onClick={() => deleteUserRole()}>
            Delete role
          </DropdownItem>
        </DropdownMenu>
      </ContextMenu>
      <div className='box'>
        <h2 className='subtitle'>Details</h2>
        <form onSubmit={handleSubmit}>
          <Columns>
            <TextField
              label='Organization'
              value={organization.name}
              readOnly={true}
            />
            <TextField
              label='Role Name'
              value={userRole.name}
              readOnly={true}
            />
          </Columns>
        </form>
      </div>
      <div className='box'>
        <h2 className='subtitle'>Grants</h2>
        <UserRoleGrantsList
          organizationId={organizationId}
          roleId={id}
          grants={userRole.grants}
          reloadData={reloadData}
        />
      </div>
    </div>
  )
}
