/* eslint jsx-a11y/anchor-is-valid: 0 */

import React, { useState, useCallback, useEffect, useRef, useMemo } from 'react'
import {
  usePersistentState,
  useBrowserLocation,
  useDraggable
} from '../../../hooks'
import {
  GoogleMap,
  StandaloneSearchBox,
  Marker,
  InfoWindow,
  TrafficLayer
} from '@react-google-maps/api'
import '@fortawesome/fontawesome-free/css/all.min.css'

import IncidentDetails from '../../IncidentDetails'
import UserDetails from '../../UserDetails'
import ModalDialog from '../../ModalDialog'
import TextField from '../../Form/TextField'
import TextAreaField from '../../Form/TextAreaField'
import ColorField from '../../Form/ColorField'
import Button from '../../Button'

import styles from './styles'
import './styles.scss'
import { iconToUnicode } from './icons'
import { useApi, useError, useForm } from '../../../hooks'

const SEARCHBOX_BOUNDS_RADIUS_METERS = 50000

function convertLocation(loc) {
  return { lng: loc[0], lat: loc[1] }
}

export class MapSelection {
  constructor(loc, placeId) {
    this.loc = loc
    this.placeId = placeId
  }
}

export function useMapSettings() {
  return usePersistentState('dispatch-map-settings', {
    showTrafficLayer: false,
    defaultMapCenter: undefined,
    defaultMapZoom: undefined,
    showLastName: false
  })
}

function GoogleMapsMarker({ onClick, ...props }) {
  const [marker, setMarker] = useState()
  return (
    <Marker
      onLoad={setMarker}
      onUnmount={() => setMarker(null)}
      onClick={() => onClick && onClick(marker)}
      {...props}
    />
  )
}

function MarkerWithFaIcon({ icon, color, ...props }) {
  return (
    <GoogleMapsMarker
      label={{
        text: iconToUnicode(icon),
        color: 'white',
        fontWeight: '600',
        fontSize: '16px',
        fontFamily: "'Font Awesome 5 Free'",
        className: 'dispatch-incident-marker-label'
      }}
      {...props}
    />
  )
}

function createCanvas(size) {
  const canvas = document.createElement('canvas')
  canvas.width = size
  canvas.height = size

  const ctx = canvas.getContext('2d')
  return [canvas, ctx]
}

function createTriangleMarker(size, color) {
  const [canvas, ctx] = createCanvas(size)

  ctx.beginPath()
  ctx.moveTo(0, 1)
  ctx.lineTo(size * 0.5, size)
  ctx.lineTo(size, 1)
  ctx.lineTo(0, 1)
  ctx.fillStyle = color
  ctx.fill()
  ctx.lineWidth = '1px'
  ctx.strokeStyle = '#333333'
  ctx.stroke()

  return canvas.toDataURL()
}

function createFaIconMarker(size, color, icon) {
  const [canvas, ctx] = createCanvas(size)

  ctx.font = `900 ${size}px 'Font Awesome 5 Free'`
  ctx.fillStyle = color

  const iconSize = ctx.measureText(icon)
  ctx.fillText(
    icon,
    size * 0.5 - iconSize.width * 0.5,

    iconSize.actualBoundingBoxAscent
  )

  return canvas.toDataURL()
}

const DEVICE_MARKER_SIZE = 64
const DEVICE_MARKER_ICON_CACHE = {}

function DeviceMarker({ text, color, ...props }) {
  const iconUrl =
    DEVICE_MARKER_ICON_CACHE[color] ||
    createTriangleMarker(DEVICE_MARKER_SIZE, color)
  DEVICE_MARKER_ICON_CACHE[color] = iconUrl

  return (
    <Marker
      label={{
        text,
        color: 'white',
        fontWeight: '800',
        fontSize: '20px',
        className: 'dispatch-device-marker-label'
      }}
      icon={{
        url: iconUrl,
        scaledSize: { width: 24, height: 24 }
      }}
      {...props}
    />
  )
}

function DeviceMarkersWrapper({ deviceLocations, openSecondaryPanel }) {
  const [{ showLastName }] = useMapSettings()

  return Object.entries(deviceLocations).map(
    ([
      deviceId,
      {
        loc,
        user: { id, userType, personalData }
      }
    ]) => {
      let displayName = personalData.displayName?.value || `User #${id}`
      if (showLastName) {
        const fullName = personalData.fullName?.value
        if (fullName) {
          displayName = fullName.split(' ')[1] || displayName
        }
      }

      return (
        <DeviceMarker
          key={deviceId}
          text={displayName}
          color={userType.color}
          position={convertLocation(loc)}
          onClick={() =>
            openSecondaryPanel(<UserDetails id={id} />, displayName)
          }
        />
      )
    }
  )
}

function IncidentMarkersWrapper({
  incidents,
  selectedIncident,
  onClick,
  iconSize
}) {
  return Object.values(incidents)
    .filter(
      (incident) =>
        incident.status === 'in-progress' &&
        (!selectedIncident || selectedIncident.id === incident.id)
    )
    .map((incident) => {
      const loc = incident.locations.find((loc) => loc.format === 'lat-long')
      if (loc) {
        incident.loc = [loc.longitude, loc.latitude]
      }
      return incident
    })
    .filter(({ loc }) => loc)
    .map((incident) => (
      <MarkerWithFaIcon
        iconSize={iconSize}
        key={incident.id}
        position={convertLocation(incident.loc)}
        icon='FaExclamationCircle'
        title={`Incident "${incident.name}"`}
        onClick={(marker) => onClick(marker, incident)}
      />
    ))
}

function IncidentInfoWindow({ incident, anchor, onClose, onOpenDetails }) {
  return (
    <InfoWindow
      anchor={anchor}
      onCloseClick={() => onClose()}
      options={{ maxWidth: 400 }}>
      <div>
        <h2 className='subtitle' style={{ marginBottom: '0.25rem' }}>
          {incident.name}
        </h2>
        <div style={{ marginBottom: '0.5rem' }}>{incident.description}</div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
          <a onClick={() => onOpenDetails(incident.id)}>Details</a>
        </div>
      </div>
    </InfoWindow>
  )
}

function IncidentMapMarkerInfoWindow({
  incident,
  mapMarker,
  anchor,
  onClose,
  onOpenDetails,
  onDeleteMarker
}) {
  return (
    <InfoWindow
      anchor={anchor}
      onCloseClick={() => onClose()}
      options={{ maxWidth: 400 }}>
      <div>
        <h2 className='subtitle' style={{ marginBottom: '0.25rem' }}>
          {mapMarker.label}
        </h2>
        <div style={{ marginBottom: '0.5rem' }}>{mapMarker.description}</div>
        <div style={{ marginBottom: '0.5rem' }}>Incident "{incident.name}"</div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
          <a onClick={() => onOpenDetails(incident.id)}>Details</a>
          <div style={{ marginLeft: 'auto' }}>
            <i
              className='fa fa-trash-alt delete-marker-btn'
              onClick={() => onDeleteMarker(mapMarker, incident)}
            />
          </div>
        </div>
      </div>
    </InfoWindow>
  )
}

const INCIDENT_MAP_MARKER_ICON_CACHE = {}
const INCIDENT_MAP_MARKER_SIZE = 64

function IncidentMapMarker({ text, color, icon, ...props }) {
  const iconUrl =
    INCIDENT_MAP_MARKER_ICON_CACHE[color] ||
    createFaIconMarker(INCIDENT_MAP_MARKER_SIZE, color, '\uf3c5')
  INCIDENT_MAP_MARKER_ICON_CACHE[color] = iconUrl

  return (
    <GoogleMapsMarker
      label={{
        text,
        color: 'white',
        fontWeight: '800',
        fontSize: '20px',
        className: 'dispatch-incident-map-marker-label'
      }}
      icon={{
        url: iconUrl,
        scaledSize: { width: 24, height: 24 }
      }}
      {...props}
    />
  )
}

function IncidentMapMarkersWrapper({ incidents, selectedIncident, onClick }) {
  return Object.values(incidents)
    .filter(
      (incident) =>
        incident.status === 'in-progress' &&
        (!selectedIncident || selectedIncident.id === incident.id)
    )
    .map((incident) =>
      incident.mapMarkers.map((mapMarker) => {
        const { id, label, color, latitude, longitude } = mapMarker

        return (
          <IncidentMapMarker
            key={id}
            position={{ lat: latitude, lng: longitude }}
            icon='\uf3c5'
            color={color}
            text={label}
            onClick={(marker) => onClick(marker, incident, mapMarker)}
          />
        )
      })
    )
    .flat()
}

function AddressSearchBox({
  addressSearchBoxRef,
  leftMargin,
  mapSettings,
  setMapCenter,
  onMapClick
}) {
  const [searchBox, setSearchBox] = useState()
  const browserLocation = useBrowserLocation()

  const searchboxStyle = useMemo(
    () =>
      Object.assign({}, styles.searchBox, {
        left: leftMargin,
        opacity: leftMargin > 800 ? 0.0 : 1.0
      }),
    [leftMargin]
  )

  const bounds = useMemo(() => {
    const { defaultMapCenter } = mapSettings

    if (!defaultMapCenter) {
      return
    }

    const center = new window.google.maps.LatLng(
      (defaultMapCenter && defaultMapCenter.lat) ||
        (browserLocation && browserLocation[0]) ||
        0,
      (defaultMapCenter && defaultMapCenter.lng) ||
        (browserLocation && browserLocation[1]) ||
        0
    )

    const circle = new window.google.maps.Circle({
      radius: SEARCHBOX_BOUNDS_RADIUS_METERS,
      center
    })
    return circle.getBounds()
  }, [mapSettings, browserLocation])

  const onPlacesChanged = useCallback(() => {
    if (!searchBox) {
      return
    }

    const places = searchBox.getPlaces()
    if (places.length === 0) {
      return
    }

    const place = places[0]
    const longitude = place.geometry.location.lng()
    const latitude = place.geometry.location.lat()

    setMapCenter(place.geometry.location)
    onMapClick &&
      onMapClick({ loc: [longitude, latitude], placeId: place.place_id })
  }, [searchBox, onMapClick, setMapCenter])

  return (
    <StandaloneSearchBox
      onLoad={setSearchBox}
      onUnmount={() => setSearchBox(null)}
      onPlacesChanged={onPlacesChanged}
      bounds={bounds}>
      <input
        ref={addressSearchBoxRef}
        type='text'
        placeholder='Search by address or location...'
        style={searchboxStyle}
      />
    </StandaloneSearchBox>
  )
}

function point2LatLng(point, map) {
  var topRight = map
    .getProjection()
    .fromLatLngToPoint(map.getBounds().getNorthEast())
  var bottomLeft = map
    .getProjection()
    .fromLatLngToPoint(map.getBounds().getSouthWest())
  var scale = Math.pow(2, map.getZoom())
  var worldPoint = new window.google.maps.Point(
    point.x / scale + bottomLeft.x,
    point.y / scale + topRight.y
  )
  return map.getProjection().fromPointToLatLng(worldPoint)
}

function DraggableMapMarker({ map, selectedIncident, setMapMarkerModal }) {
  const onDrop = useCallback(
    async (e) => {
      if (!map || !selectedIncident) {
        return
      }

      const cursorPos = [e.clientX, e.clientY]

      const mapRect = map.getDiv().getBoundingClientRect()
      cursorPos[0] -= mapRect.x
      cursorPos[1] -= mapRect.y

      const coords = point2LatLng({ x: cursorPos[0], y: cursorPos[1] }, map)
      if (!coords) {
        return
      }

      const latitude = coords.lat()
      const longitude = coords.lng()

      setMapMarkerModal({ latitude, longitude })
    },
    [map, selectedIncident, setMapMarkerModal]
  )

  const [draggableProps] = useDraggable(onDrop)

  return (
    <div className='draggable-map-marker'>
      <i className='fa fa-map-marker-alt' {...draggableProps} />
    </div>
  )
}

const INCIDENT_MAP_MARKER_COLORS = ['#eb144c', '#ff6900', '#00d084']

function CreateMapMarkerModal({
  incident,
  latitude,
  longitude,
  onMarkerCreated
}) {
  const [client] = useApi()
  const [loading, setLoading] = useState(false)
  const setError = useError()

  const [getFieldProps, formData, formError] = useForm()

  async function handleSubmit(e) {
    e.preventDefault()

    const { label, description, color } = formData

    setLoading(true)

    try {
      const marker = await client(
        `admin/incidents/${incident.id}/map-marker/create`,
        {
          latitude,
          longitude,
          label: label.toUpperCase(),
          description,
          color,
          icon: 'n/a'
        }
      )

      onMarkerCreated && onMarkerCreated(marker)
    } catch (err) {
      setLoading(false)

      if (err.message === 'validation_error') {
        formError(err.props)
      } else {
        setError(err)
      }
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        label='Label'
        name='label'
        placeholder='Label'
        maxLength={10}
        required={true}
        {...getFieldProps()}
      />
      <TextAreaField
        label='Description (optional)'
        name='description'
        placeholder='Description'
        {...getFieldProps()}
      />
      <ColorField
        label='Color'
        name='color'
        required={true}
        defaultValue={'#eb144c'}
        colors={INCIDENT_MAP_MARKER_COLORS}
        {...getFieldProps()}
      />
      <div className='level'>
        <div className='level-left' />
        <div className='level-right'>
          <div className='level-item'>
            <Button
              type='submit'
              className={loading ? 'is-loading' : ''}
              enabled={`${!loading}`}>
              Create marker
            </Button>
          </div>
        </div>
      </div>
    </form>
  )
}

/*function Directions({ origin, destination }) {
  const [response, setResponse] = useState()

  const serviceOpts = useMemo(
    () => ({
      destination: { lat: destination[0], lng: destination[1] },
      origin: { lat: origin[0], lng: origin[1] },
      travelMode: 'DRIVING'
    }),
    [origin, destination]
  )
  console.log(serviceOpts)

  const el = useMemo(
    () => (
      <DirectionsService options={serviceOpts} callback={setResponse}>
        {response && (
          <DirectionsRenderer
            options={{
              directions: response
            }}
          />
        )}
      </DirectionsService>
    ),
    [serviceOpts]
  )

  return el
}*/

export default function ({
  center,
  setMapCenter,
  zoom,
  setMapZoom,
  deviceLocations,
  incidents,
  mapSelection,
  onMapClick,
  openSecondaryPanel,
  leftMargin,
  selectedIncident,
  addressSearchBoxRef,
  directionsOrigin,
  directionsDestination
}) {
  const [map, setMap] = useState()
  const [infoBox, setInfoBox] = useState()
  const [mapSettings, setMapSettings] = useMapSettings()
  const [mapMarkerModal, setMapMarkerModal] = useState()
  const [client] = useApi()

  useEffect(() => {
    if (!mapSettings.defaultMapCenter || !mapSettings.defaultMapZoom) {
      setMapSettings({
        ...mapSettings,
        defaultMapCenter: center,
        defaultMapZoom: zoom
      })
    }
  }, [mapSettings, setMapSettings, center, zoom])

  const onClick = useCallback(
    (e) => {
      const latitude = e.latLng.lat()
      const longitude = e.latLng.lng()
      const placeId = e.placeId
      setInfoBox(null)
      onMapClick && onMapClick({ loc: [longitude, latitude], placeId })
    },
    [onMapClick, setInfoBox]
  )

  const onIncidentMarkerClick = useCallback(
    (marker, incident) => {
      setInfoBox(null)
      setInfoBox(
        <IncidentInfoWindow
          incident={incident}
          anchor={marker}
          onClose={() => setInfoBox(null)}
          onOpenDetails={() =>
            openSecondaryPanel(
              <IncidentDetails id={incident.id} />,
              incident.name
            )
          }
        />
      )
    },
    [setInfoBox, openSecondaryPanel]
  )

  const onDeleteMarker = useCallback(
    async (marker, incident) => {
      if (window.confirm('Are you sure you wish to delete this map marker?')) {
        await client(`admin/incidents/${incident.id}/map-marker/delete`, {
          id: marker.id
        })
      }
    },
    [client]
  )

  const onIncidentMapMarkerClick = useCallback(
    (marker, incident, mapMarker) => {
      setInfoBox(null)
      setInfoBox(
        <IncidentMapMarkerInfoWindow
          incident={incident}
          mapMarker={mapMarker}
          anchor={marker}
          onClose={() => setInfoBox(null)}
          onDeleteMarker={onDeleteMarker}
          onOpenDetails={() =>
            openSecondaryPanel(
              <IncidentDetails id={incident.id} />,
              incident.name
            )
          }
        />
      )
    },
    [setInfoBox, openSecondaryPanel, onDeleteMarker]
  )

  const mapOptions = useMemo(() => {
    const { ROADMAP, SATELLITE, TERRAIN } = window.google.maps.MapTypeId
    const { RIGHT_TOP } = window.google.maps.ControlPosition

    let defaultMapTypeId = ROADMAP
    switch (mapSettings.defaultMapType) {
      case 'roadmap':
      default:
        defaultMapTypeId = ROADMAP
        break
      case 'satellite':
        defaultMapTypeId = SATELLITE
        break
      case 'terrain':
        defaultMapTypeId = TERRAIN
        break
    }

    return {
      mapTypeControl: true,
      mapTypeControlOptions: {
        mapTypeIds: [ROADMAP, SATELLITE, TERRAIN],
        position: RIGHT_TOP
      },
      mapTypeId: defaultMapTypeId
    }
  }, [mapSettings])

  const dragging = useRef(false)

  useEffect(() => {
    if (!map || !map.center) {
      return
    }

    if (!center) {
      const incidentLocations = Object.values(incidents)
        .filter((incident) => incident.status === 'in-progress')
        .map((incident) => {
          const loc = incident.locations.find(
            (loc) => loc.format === 'lat-long'
          )
          return (
            loc && new window.google.maps.LatLng(loc.latitude, loc.longitude)
          )
        })
        .filter((x) => x)
      const incidentMapMarkerLocations = Object.values(incidents)
        .filter((incident) => incident.status === 'in-progress')
        .map((incident) =>
          incident.mapMarkers.map(
            ({ latitude, longitude }) =>
              new window.google.maps.LatLng(latitude, longitude)
          )
        )
        .flat()
      const flatDeviceLocations = Object.entries(deviceLocations).map(
        ([, { loc }]) => new window.google.maps.LatLng(loc[1], loc[0])
      )
      const bounds = new window.google.maps.LatLngBounds()

      for (const loc of incidentLocations) {
        bounds.extend(loc)
      }
      for (const loc of incidentMapMarkerLocations) {
        bounds.extend(loc)
      }
      for (const loc of flatDeviceLocations) {
        bounds.extend(loc)
      }

      map.setCenter(bounds.getCenter())
      map.fitBounds(bounds)
      return
    }

    if (!dragging.current) {
      if (map.center.lat() === center.lat && map.center.lng() === center.lng) {
        return
      }

      map.panTo(center)
    }
  }, [map, center, incidents, deviceLocations])

  const cachedCenter = useRef(center)

  return (
    <GoogleMap
      onLoad={(map) => {
        setMap(map)
        setTimeout(() => map.panTo(center), 0)
      }}
      onUnmount={() => {
        dragging.current = false
        setMap(null)
      }}
      mapContainerStyle={styles.mapContainer}
      options={mapOptions}
      center={cachedCenter.current}
      zoom={zoom}
      onClick={onClick}
      onZoomChanged={() => map && setMapZoom(map.zoom)}
      onCenterChanged={() =>
        map &&
        map.center &&
        setMapCenter({ lng: map.center.lng(), lat: map.center.lat() })
      }
      onDragStart={() => (dragging.current = true)}
      onDragEnd={() => (dragging.current = false)}>
      {/*directionsOrigin && directionsDestination && (
        <Directions
          origin={directionsOrigin}
          destination={directionsDestination}
        />
      )*/}
      <AddressSearchBox
        addressSearchBoxRef={addressSearchBoxRef}
        leftMargin={leftMargin}
        mapSettings={mapSettings}
        setMapCenter={setMapCenter}
        onMapClick={onMapClick}
      />
      {selectedIncident && (
        <div className='selected-incident' style={{ left: `${leftMargin}px` }}>
          Selected incident: {selectedIncident.name} (#{selectedIncident.id})
        </div>
      )}
      <DeviceMarkersWrapper
        deviceLocations={deviceLocations}
        openSecondaryPanel={openSecondaryPanel}
        iconSize={mapSettings.iconSize || 5}
      />
      <IncidentMarkersWrapper
        incidents={incidents}
        selectedIncident={selectedIncident}
        onClick={onIncidentMarkerClick}
        iconSize={mapSettings.iconSize || 5}
      />
      <IncidentMapMarkersWrapper
        incidents={incidents}
        selectedIncident={selectedIncident}
        onClick={onIncidentMapMarkerClick}
        iconSize={mapSettings.iconSize || 5}
      />
      {selectedIncident && (
        <DraggableMapMarker
          map={map}
          selectedIncident={selectedIncident}
          setMapMarkerModal={setMapMarkerModal}
        />
      )}
      {selectedIncident && (
        <ModalDialog
          active={mapMarkerModal}
          onClose={() => setMapMarkerModal()}>
          {mapMarkerModal && (
            <CreateMapMarkerModal
              incident={selectedIncident}
              latitude={mapMarkerModal.latitude}
              longitude={mapMarkerModal.longitude}
              onMarkerCreated={() => setMapMarkerModal()}
            />
          )}
        </ModalDialog>
      )}

      {mapSelection && <Marker position={convertLocation(mapSelection.loc)} />}
      {mapSettings.showTrafficLayer && <TrafficLayer />}
      {infoBox}
    </GoogleMap>
  )
}
