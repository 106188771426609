import axios from 'axios'
import EventEmitter from 'eventemitter3'

export const baseURL = window.appConfig.API_URL

function buf2hex(buffer) {
  return Array.prototype.map
    .call(new Uint8Array(buffer), (x) => ('00' + x.toString(16)).slice(-2))
    .join('')
}

function getDeviceUid() {
  if (localStorage.deviceUid) {
    return localStorage.deviceUid
  }

  const bytes = new Uint8Array(8)
  window.crypto.getRandomValues(bytes)
  localStorage.deviceUid = buf2hex(bytes)
  return localStorage.deviceUid
}

export const httpClient = axios.create({
  baseURL,
  timeout: 10000,
  responseType: 'json',
  headers: {
    'X-Device-ID': getDeviceUid()
  }
})

export class ApiError extends Error {
  constructor(error) {
    super(error?.message || 'ApiError')
    Object.assign(this, {}, error)
  }
}

export function createApiClient(token) {
  return async function apiRequest(endpoint, payload) {
    try {
      const { data } = await httpClient({
        method: payload ? 'post' : 'get',
        url: endpoint.startsWith('v2/') ? endpoint : `v1/${endpoint}`,
        data: payload,
        headers: {
          Authorization: token ? `Bearer ${token}` : undefined,
          'Content-Type': payload ? 'application/json' : undefined
        }
      })

      return data
    } catch (err) {
      if (err.response) {
        throw new ApiError(err.response.data)
      }

      throw new ApiError(err)
    }
  }
}

export async function getResourceDataUrl(url, accessToken) {
  const res = await axios.get(url, {
    headers: {
      authorization: `Bearer ${accessToken}`,
      'X-Device-ID': getDeviceUid()
    },
    maxRedirects: 0,
    responseType: 'blob',
    timeout: 3.6e7 // 10 hours
  })

  console.log(res)
  return 'google.com'
  //  return URL.createObjectURL(data)
}

export async function openResource(url, accessToken) {
  const url_2 = await getResourceDataUrl(url, accessToken)
  return window.open(url_2, '_blank')
}

export function createWebSocket(accessToken) {
  return new ApiWebSocket(accessToken)
}

export class ApiEventStream extends EventEmitter {
  constructor(ws) {
    super()

    this.ws = ws

    this.ws.on('disconnected', () => {
      this.sid = null
    })
  }

  async connect(name) {
    if (this.sid) {
      return
    }

    this.name = name

    const { sid } = await this.ws.rpc('sub', [name])
    this.emit('connected')
    this.sid = sid

    this.eventListener = this.ws.addListener(sid, (event) =>
      this.emit('event', event)
    )
  }

  async disconnect() {
    if (!this.sid) {
      return
    }

    if (this.eventListener) {
      this.ws.removeListener(this.sid, this.eventListener)
      this.eventListener = null
    }

    await this.ws.rpc('unsub', [this.sid])
    this.sid = null
  }
}

export class ApiWebSocket extends EventEmitter {
  constructor(accessToken) {
    super()

    const url = new URL(baseURL)

    this.socketUrl =
      url.protocol === 'http:' ? `ws://${url.host}` : `wss://${url.host}`
    this.accessToken = accessToken
    this.nextRpcId = 1
    this.authenticated = false
    this.callbacks = {}
    this.retries = 0

    this.connect()
  }

  isConnected() {
    return this.ws && this.ws.readyState === WebSocket.OPEN
  }

  isAuthenticated() {
    return this.isConnected() && this.authenticated
  }

  connect() {
    this.ws = new WebSocket(this.socketUrl)
    this.ws.onopen = this.handleConnected.bind(this)
    this.ws.onclose = this.handleDisconnected.bind(this)
    this.ws.onmessage = this.handleMessage.bind(this)
  }

  handleConnected() {
    this.emit('connected')

    this._rpc('auth', [this.accessToken, getDeviceUid()], (err) => {
      if (err) {
        console.error('websocket auth failed')
        this.authenticated = false

        window.location.reload() // hack to fix reconnection issue
        return
      }

      this.retries = 0
      this.authenticated = true
      this.emit('authenticated')
    })
  }

  handleDisconnected() {
    this.emit('disconnected')

    this.retries += 1
    const timeout = Math.pow(1.2, Math.min(this.retries, 20))
    console.warn(
      `WebSocket disconnected. Retrying connection in ${timeout.toFixed(0)}s (${
        this.retries
      } retries).`
    )

    setTimeout(() => {
      this.connect()
    }, timeout * 1000)
  }

  handleMessage(msg) {
    try {
      msg = JSON.parse(msg.data)
    } catch (ex) {
      console.error(
        `failed parsing websocket message "${msg.data}", reason: ${ex}`
      )
      return
    }

    if (msg.type === 'rpc') {
      const cb = this.callbacks[msg.id]

      if (msg.ok) {
        cb(undefined, msg.res)
      } else {
        cb(msg.error, undefined)
      }

      delete this.callbacks[msg.id]
    } else if (msg.type === 'evt') {
      this.emit(msg.sid, { type: msg.evt, data: msg.data })
    }
  }

  createEventStream() {
    return new ApiEventStream(this)
  }

  rpc(fn, args) {
    return new Promise((resolve, reject) => {
      this._rpc(fn, args, (err, response) => {
        if (err) {
          reject(err)
          return
        }

        resolve(response)
      })
    })
  }

  _rpc(fn, args, callback) {
    if (
      this.ws.readyState !== WebSocket.OPEN ||
      (!this.authenticated && fn !== 'auth')
    ) {
      setTimeout(() => this._rpc(fn, args, callback), 500)
      return
    }

    const id = this.nextRpcId++
    this.callbacks[id] = callback

    this.ws.send(
      JSON.stringify({
        id,
        rpc: fn,
        args
      })
    )
  }
}
