import React, { useCallback, useState, useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { useApiRequest, useApi, useApiToken, useError } from '../../hooks'
import { openResource } from '../../api'

import Columns from '../Columns'
import Info from '../Info'
import DataTable, { Row, RowData } from '../DataTable'
import DateTime from '../DateTime'
import ContextMenu from '../ContextMenu'
import EditIncidentStatus from '../EditIncidentStatus'
import DropdownMenu, { DropdownItem } from '../DropdownMenu'
import LocationRenderer from '../LocationRenderer'
import Spinner from '../Spinner'

function capitalize(s) {
  return `${s.slice(0, 1).toUpperCase()}${s.slice(1)}`
}

function DispatchOrganizationsList({ dispatchOrganizations }) {
  const navigate = useNavigate()

  dispatchOrganizations = dispatchOrganizations.sort((a, b) => a.id - b.id)

  return (
    <div>
      <DataTable
        columns={['ID', 'Name', 'Off-Duty']}
        className='is-fullwidth is-hoverable'>
        {dispatchOrganizations.map(({ id, name, dispatchFlags }) => (
          <Row key={id} onClick={() => navigate(`/organizations/${id}`)}>
            <RowData>{id}</RowData>
            <RowData>{name}</RowData>
            <RowData>{!!(dispatchFlags & (1 << 0)) ? 'Yes' : 'No'}</RowData>
          </Row>
        ))}
      </DataTable>
    </div>
  )
}

function MapMarkersList({ incidentId, mapMarkers, refreshData }) {
  const [client] = useApi()
  const setError = useError()

  mapMarkers = mapMarkers.sort((a, b) => a.id - b.id)

  const deleteMarker = useCallback(
    async (id) => {
      try {
        if (
          window.confirm('Are you sure you wish to delete this map marker?')
        ) {
          await client(`admin/incidents/${incidentId}/map-marker/delete`, {
            id
          })
          refreshData()
        }
      } catch (err) {
        setError(err)
      }
    },
    [client, setError, refreshData, incidentId]
  )

  return (
    <div>
      <DataTable
        columns={['ID', 'Label', 'Location', 'Actions']}
        className='is-fullwidth is-hoverable'>
        {mapMarkers.map(({ id, label, latitude, longitude }) => (
          <Row key={id}>
            <RowData>{id}</RowData>
            <RowData>{label}</RowData>
            <RowData>
              {latitude}, {longitude}
            </RowData>
            <RowData>
              <DropdownMenu label='Actions' small={true}>
                <DropdownItem onClick={() => deleteMarker(id)}>
                  Delete
                </DropdownItem>
              </DropdownMenu>
            </RowData>
          </Row>
        ))}
      </DataTable>
    </div>
  )
}

function AttachmentsList({ incidentId, attachments, refreshData }) {
  const [client] = useApi()
  const token = useApiToken()
  const setError = useError()

  const deleteAttachment = useCallback(
    async (id) => {
      try {
        if (
          window.confirm('Are you sure you wish to delete this attachment?')
        ) {
          await client(`admin/incidents/${incidentId}/attachment/delete`, {
            id
          })
          refreshData()
        }
      } catch (err) {
        setError(err)
      }
    },
    [client, setError, refreshData, incidentId]
  )

  return (
    <div>
      <DataTable
        columns={['Name', 'Resource Type', 'Actions']}
        className='is-fullwidth is-hoverable'>
        {attachments.map((item) => (
          <Row key={item.id}>
            <RowData>{item.name}</RowData>
            <RowData>{item.resource.mimeType}</RowData>
            <RowData>
              <DropdownMenu label='Actions' small={true}>
                <DropdownItem
                  onClick={() => openResource(item.resource.url, token)}>
                  View
                </DropdownItem>
                <DropdownItem onClick={() => deleteAttachment(item.id)}>
                  Delete
                </DropdownItem>
              </DropdownMenu>
            </RowData>
          </Row>
        ))}
      </DataTable>
    </div>
  )
}

/*function CCTVEventsList({ incidentId }) {
  const navigate = useNavigate()

  const [rows, currentPage, totalCount, loading, setPage, setOpts, opts] =
    usePagination({
      endpoint: `admin/incidents/${incidentId}/cctv-events`,
      itemsPerPage: 10,
      sortBy: 'id',
      sortOrder: 'desc',
      persistent: true,
      uid: `cctv-events-list-${incidentId}`
    })

  const columns = [
    {
      name: 'ID',
      sortKey: 'id',
      render: (item) => item.id
    },
    {
      name: 'Source',
      render: (item) => item.data.source
    },
    {
      name: 'Label',
      render: (item) => item.data.label
    },
    {
      name: 'Timestamp',
      sortKey: 'createdAt',
      render: (item) => <DateTime value={item.createdAt} />
    }
  ]

  return (
    <PaginatedTable
      className='is-fullwidth is-hoverable'
      columns={columns}
      rows={rows}
      currentPage={currentPage}
      totalCount={totalCount}
      loading={loading}
      setPage={setPage}
      opts={opts}
      setOpts={setOpts}
      onRowClick={(item) =>
        navigate(`/incidents/${incidentId}/cctv-events/${item.id}`)
      }
    />
  )
}*/

function displayUser({ id, personalData }) {
  return (
    personalData?.displayName?.value ||
    personalData?.fullName?.value ||
    `User #${id}`
  )
}

function IncidentActivity({ incidentId }) {
  const [dispatches, reloadActivity] = useApiRequest(
    `admin/incidents/${incidentId}/activity`
  )

  useEffect(() => {
    const interval = setInterval(reloadActivity, 5000)
    return () => clearInterval(interval)
  })

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <h2 className='subtitle'>Responding Units</h2>
        <button
          className='button is-small'
          style={{ marginLeft: '0.5rem', marginBottom: '1.5rem' }}
          onClick={reloadActivity}>
          <i className='fa fa-sync-alt' />
        </button>
      </div>
      <DataTable
        columns={['User', 'Organization', 'Status', 'Last Update']}
        className='is-fullwidth is-hoverable'>
        {dispatches &&
          dispatches.map((item) => (
            <Row key={item.id}>
              <RowData>{displayUser(item.user)}</RowData>
              <RowData>{item.user.organization.name}</RowData>
              <RowData>{capitalize(item.status)}</RowData>
              <RowData>
                <DateTime value={item.updatedAt} />
              </RowData>
            </Row>
          ))}
      </DataTable>
    </>
  )
}

export default function ({ id }) {
  const [incident, reloadIncident] = useApiRequest(`admin/incidents/${id}`)
  const [editing, setEditing] = useState()
  const navigate = useNavigate()
  const [client] = useApi()

  const createDataExportJob = useCallback(async () => {
    if (
      !window.confirm('Are you sure you wish to create a new data export job?')
    ) {
      return
    }

    try {
      await client('admin/data-export/create-job', {
        format: 'text',
        incidentId: id
      })
      navigate('/data-export/jobs')
    } catch (err) {
      console.error(err)
    }
  }, [client, id, navigate])

  if (!incident) {
    return <Spinner />
  }

  return (
    <div style={{ width: '100%' }}>
      <ContextMenu
        renderTitle={() => <h1 className='title'>{incident.name}</h1>}>
        <DropdownMenu label='Actions'>
          <DropdownItem onClick={() => setEditing(true)}>
            Edit status
          </DropdownItem>
          <DropdownItem
            onClick={() =>
              navigate(`/incidents/attachment/create?incidentId=${incident.id}`)
            }>
            Add a new attachment
          </DropdownItem>
          {incident.status !== 'ended' && (
            <DropdownItem
              onClick={() =>
                navigate(
                  `/incidents/add-dispatch-organization?incidentId=${incident.id}`
                )
              }>
              Add responders
            </DropdownItem>
          )}
          {incident.status === 'ended' && (
            <DropdownItem onClick={createDataExportJob}>
              Export data
            </DropdownItem>
          )}
        </DropdownMenu>
      </ContextMenu>
      <div className='box'>
        <h2 className='subtitle'>Incident Details</h2>
        {editing && (
          <EditIncidentStatus
            incident={incident}
            onFinishEditing={() => reloadIncident() && setEditing(false)}
          />
        )}
        <Columns slice={3}>
          <Info label='ID'>{incident.id}</Info>
          <Info label='Organization'>
            <Link to={`/organizations/${incident.organization.id}`}>
              {incident.organization.name}
            </Link>
          </Info>
          <Info label='Timestamp'>
            <DateTime value={incident.createdAt} />
          </Info>
          {incident.user && (
            <Info label='Created By'>
              <Link to={`/users/${incident.user.id}`}>
                {incident.user.personalData?.displayName?.value}
              </Link>
            </Info>
          )}
          <Info label='Name'>{incident.name}</Info>
          {incident.description.length !== 0 && (
            <Info label='Description'>{incident.description}</Info>
          )}
          <Info label='Status'>{capitalize(incident.status)}</Info>
          <Info label='Type'>{capitalize(incident.type)}</Info>
          <Info label='Acknowledged By'>
            {incident.acknowledgements.length === 0 && 'N/A'}
            {incident.acknowledgements.length !== 0 && (
              <Link to={`/users/${incident.acknowledgements[0].user.id}`}>
                {incident.acknowledgements[0].user.personalData?.displayName
                  ?.value || `User #${incident.acknowledgements[0].user.id}`}
              </Link>
            )}
          </Info>
          {incident.site && (
            <Info label='Site'>
              <Link to={`/sites/${incident.site.id}`}>
                {incident.site.name}
              </Link>
            </Info>
          )}
          {incident.locations &&
            incident.locations.map((location, index) => (
              <LocationRenderer key={index} location={location} />
            ))}
          {incident.customData.map(({ type: { displayName }, value, id }) => (
            <Info key={id} label={displayName}>
              {typeof value === 'string' ? value : JSON.stringify(value)}
            </Info>
          ))}
        </Columns>
      </div>
      <div className='box'>
        <h2 className='subtitle'>Dispatch Organizations</h2>
        <DispatchOrganizationsList
          dispatchOrganizations={incident.dispatchOrganizations}
        />
      </div>
      <div className='box'>
        <IncidentActivity incidentId={incident.id} />
      </div>
      <div className='box'>
        <h2 className='subtitle'>Attachments</h2>
        <AttachmentsList
          incidentId={incident.id}
          attachments={incident.attachments}
          refreshData={reloadIncident}
        />
      </div>
      <div className='box'>
        <h2 className='subtitle'>Map Markers</h2>
        <MapMarkersList
          incidentId={incident.id}
          mapMarkers={incident.mapMarkers}
          refreshData={reloadIncident}
        />
      </div>
      {/*<div className='box'>
        <h2 className='subtitle'>CCTV Events</h2>
        <CCTVEventsList incidentId={incident.id} />
          </div>*/}
    </div>
  )
}
