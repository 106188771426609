import React from 'react'

import DataExportJobsList from '../../components/DataExportJobsList'

export default function () {
  return (
    <div className='container'>
      <DataExportJobsList />
    </div>
  )
}
