import React, { useState } from 'react'

import { useApi, useForm, useApiRequest, useError } from '../../hooks'
import TextField from '../Form/TextField'
import DropdownMenu, { DropdownItem } from '../DropdownMenu'
import Button from '../Button'
import Spinner from '../Spinner'

function PersonalDataForm({ user, id, type, value, isNew, onChange }) {
  const [client] = useApi()
  const setError = useError()

  async function deleteData() {
    try {
      if (window.confirm('Are you sure you wish to delete this item?')) {
        await client(`admin/users/${user.id}/delete-personal-data`, { id })
        onChange()
      }
    } catch (err) {
      setError(err)
    }
  }

  const [getFieldProps, formData, formError] = useForm()
  const edit = formData && formData.value !== value

  async function handleSubmit(e) {
    e.preventDefault()

    const { value } = formData

    try {
      if (isNew) {
        await client(`admin/users/${user.id}/create-personal-data`, {
          type: type.typeName,
          value
        })
      } else {
        await client(`admin/users/${user.id}/update-personal-data`, {
          id,
          value
        })
      }
      onChange()
    } catch (err) {
      if (err.message === 'validation_error') {
        formError(err.props)
      } else {
        setError(err)
      }
    }
  }

  return (
    <form onSubmit={handleSubmit} style={{ marginBottom: '1em' }}>
      <TextField
        name='value'
        defaultValue={value}
        label={type.displayName}
        placeholder={type.displayName}
        renderAddons={() =>
          edit ? (
            <button type='submit' className='button is-primary'>
              Save
            </button>
          ) : (
            <button
              type='button'
              onClick={isNew ? onChange : deleteData}
              className={`button ${isNew ? 'is-warning' : 'is-danger'}`}>
              Delete
            </button>
          )
        }
        {...getFieldProps()}
      />
    </form>
  )
}

export default function ({ id, onFinishEditing }) {
  const [personalDataTypes] = useApiRequest('admin/users/personal-data-types')
  const [user, reloadUser] = useApiRequest(`admin/users/${id}`)
  const [newItems, setNewItems] = useState([])

  function addItem(type) {
    setNewItems([...newItems, { type, value: '', id: 10000 + newItems.length }])
  }

  if (!user || !personalDataTypes) {
    return <Spinner />
  }

  const existingTypes = user.personalData
    .concat(newItems)
    .reduce((acc, data) => (acc[data.type.typeName] = true) && acc, {})

  const availableTypes = personalDataTypes.filter(
    (type) => !existingTypes[type.typeName] || type.allowMany
  )

  return (
    <div>
      <div>
        {user.personalData.map((data) => (
          <PersonalDataForm
            key={data.id}
            user={user}
            onChange={reloadUser}
            {...data}
          />
        ))}
        {newItems.map((data, index) => (
          <PersonalDataForm
            key={data.id}
            user={user}
            onChange={() => {
              let items = [...newItems]
              items.splice(index, 1)
              setNewItems(items)
              reloadUser()
            }}
            isNew={true}
            {...data}
          />
        ))}
      </div>
      <br />
      <div className='level'>
        <div className='level-left'>
          <div className='level-item'>
            <DropdownMenu label='Add new item'>
              {availableTypes.map((type) => (
                <DropdownItem key={type.typeName} onClick={() => addItem(type)}>
                  {type.displayName}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </div>
          <div className='level-item'>
            <Button className='is-text' onClick={onFinishEditing}>
              Finish editing
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
