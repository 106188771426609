import React, { useState } from 'react'
import { useApi, useApiRequest, useError, useForm } from '../../hooks'

import Button from '../Button'
import ContextMenu from '../ContextMenu'
import {
  USER_TYPE_FLAGS,
  UserTypeForm,
  parseUserTypeFormData
} from '../CreateUserType'
import Spinner from '../Spinner'

export default function ({ id }) {
  const [updated, setUpdated] = useState(false)
  const [client] = useApi()
  const setError = useError()

  let [userType, reloadData] = useApiRequest(`admin/user-types/${id}`)
  const [getFieldProps, formData, formError] = useForm({
    onFormValueChanged: () => setUpdated(false),
    dependencies: [userType]
  })

  if (userType) {
    userType.dispatchable = !!(userType.flags & USER_TYPE_FLAGS.DISPATCHABLE)
    userType.backgroundLocation = !!(
      userType.flags & USER_TYPE_FLAGS.FORCE_BACKGROUND_LOCATION
    )
    userType.securityLeader = !!(
      userType.flags & USER_TYPE_FLAGS.SECURITY_LEADER
    )
    userType.citizenPanicButton = !!(
      userType.flags & USER_TYPE_FLAGS.CITIZEN_PANIC_BUTTON
    )
  }

  async function handleSubmit(e) {
    e.preventDefault()

    try {
      await client('admin/user-types/update', {
        id,
        ...parseUserTypeFormData(formData)
      })

      reloadData()
      setUpdated(true)
    } catch (err) {
      setUpdated(false)
      if (err.message === 'validation_error') {
        formError(err.props)
      } else {
        setError(err)
      }
    }
  }

  if (!userType) {
    return <Spinner />
  }

  return (
    <div>
      <h1 className='title'>Editing user type "{userType.name}"</h1>
      <div className='box'>
        <form onSubmit={handleSubmit}>
          <UserTypeForm getFieldProps={getFieldProps} defaultValue={userType} />
          <br />
          <ContextMenu>
            <Button type='submit'>
              {!updated && 'Update'}
              {updated && <i className='fa fa-check' />}
            </Button>
          </ContextMenu>
        </form>
      </div>
    </div>
  )
}
