import React from 'react'
import { useParams } from 'react-router-dom'
import SiteDetails from '../../../components/SiteDetails'

export default function () {
  const { id } = useParams()
  return (
    <div className='container'>
      <SiteDetails id={id} />
    </div>
  )
}
