import React, { useState, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { useApi, useApiRequest, usePermissions, useError } from '../../hooks'
import DropdownMenu, { DropdownItem } from '../DropdownMenu'
import DataTable, { Row, RowData } from '../DataTable'
import UsersList from '../UsersList'
import UserRolesList from '../UserRolesList'
import UserGroupsList from '../UserGroupsList'
import Columns from '../Columns'
import Info from '../Info'
import ContextMenu from '../ContextMenu'
import EditOrganizationDetails, {
  formatRetentionLifetime
} from '../EditOrganizationDetails'
import { IconPickerItem } from '../Form/IconField'
import Spinner from '../Spinner'

function capitalize(s) {
  return s[0].toUpperCase() + s.slice(1)
}

function DispatchBindingsList({ organizationId }) {
  const [client] = useApi()
  const setError = useError()
  const [dispatchBindings, reloadData] = useApiRequest(
    `admin/organizations/${organizationId}/dispatch-bindings`
  )

  const deleteBinding = useCallback(
    async (dispatchOrganizationId) => {
      if (
        !window.confirm(
          'Are you sure you wish to delete this dispatch binding?'
        )
      ) {
        return
      }

      try {
        await client(
          `admin/organizations/${organizationId}/dispatch-bindings/delete`,
          {
            dispatchOrganizationId
          }
        )

        reloadData()
      } catch (err) {
        setError(err)
      }
    },
    [client, setError, reloadData, organizationId]
  )

  if (!dispatchBindings) {
    return <Spinner />
  }

  return (
    <DataTable
      columns={['ID', 'Name', 'Actions']}
      className='is-fullwidth is-hoverable is-size-7'>
      {dispatchBindings &&
        dispatchBindings
          .filter((x) => x && x.dispatchOrganization)
          .map(({ dispatchOrganization: { id, name } }) => (
            <Row key={id}>
              <RowData>{id}</RowData>
              <RowData>{name}</RowData>
              <RowData>
                <DropdownMenu label='Actions' small={true}>
                  <DropdownItem onClick={() => deleteBinding(id)}>
                    Delete
                  </DropdownItem>
                </DropdownMenu>
              </RowData>
            </Row>
          ))}
    </DataTable>
  )
}

export default function ({ id }) {
  const navigate = useNavigate()
  const [organization, reloadData] = useApiRequest(`admin/organizations/${id}`)
  const createUserPermission = usePermissions('user', 'create')
  const updateOrganizationPermission = usePermissions(
    'organization',
    'updateAny'
  )
  const deleteOrganizationPermission = usePermissions(
    'organization',
    'deleteAny'
  )
  const dataExportPermission = usePermissions('dataExport', 'readAny')

  const [profile] = useApiRequest('admin/profile')
  const [editing, setEditing] = useState()
  const [client] = useApi()
  const setError = useError()

  async function deleteOrganization() {
    try {
      if (
        window.confirm('Are you sure you wish to delete this organization?')
      ) {
        await client(`admin/organizations/${organization.id}/delete`, {})
        navigate('/organizations/list')
      }
    } catch (err) {
      setError(err)
    }
  }

  return (
    <div>
      <ContextMenu
        renderTitle={() =>
          organization && <h1 className='title'>{organization.name}</h1>
        }>
        <DropdownMenu label='Actions'>
          {createUserPermission && createUserPermission.granted && (
            <DropdownItem
              onClick={() =>
                navigate(`/users/create?organizationId=${organization.id}`)
              }>
              Create a new user
            </DropdownItem>
          )}
          {updateOrganizationPermission &&
            updateOrganizationPermission.granted && (
              <>
                <DropdownItem
                  onClick={() =>
                    navigate(
                      `/user-roles/create?organizationId=${organization.id}`
                    )
                  }>
                  Create a new user role
                </DropdownItem>
                <DropdownItem
                  onClick={() =>
                    navigate(
                      `/user-groups/create?organizationId=${organization.id}`
                    )
                  }>
                  Create a new user group
                </DropdownItem>
                <DropdownItem
                  onClick={() =>
                    navigate(
                      `/dispatch-bindings/create?organizationId=${organization.id}`
                    )
                  }>
                  Create a new dispatch binding
                </DropdownItem>
                <DropdownItem onClick={() => setEditing(true)}>
                  Edit organization details
                </DropdownItem>
              </>
            )}
          <DropdownItem
            onClick={() =>
              navigate(
                `/data-retention-events?organizationId=${organization.id}`
              )
            }>
            Data retention events
          </DropdownItem>
          {dataExportPermission &&
            dataExportPermission.granted &&
            profile &&
            profile.organization.id === parseInt(id) && (
              <DropdownItem onClick={() => navigate('/data-export/jobs')}>
                Data export
              </DropdownItem>
            )}
          {deleteOrganizationPermission &&
            deleteOrganizationPermission.granted && (
              <DropdownItem onClick={() => deleteOrganization()}>
                Delete organization
              </DropdownItem>
            )}
        </DropdownMenu>
      </ContextMenu>
      {organization && (
        <div className='box'>
          <h2 className='subtitle'>Organization Details</h2>
          <Columns>
            <Info label='ID'>{organization.id}</Info>
            <Info label='Name'>{organization.name}</Info>
            <Info label='Data Retention Lifetime'>
              {formatRetentionLifetime(organization.dataRetentionLifetime)}
            </Info>
            <Info label='Time Zone'>{organization.timezone}</Info>
            <Info label='Status'>{capitalize(organization.status)}</Info>
            <Info label='Default User Type'>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center'
                }}>
                <IconPickerItem
                  icon={organization.defaultUserType.icon}
                  size={24}
                  color={organization.defaultUserType.color}
                />
                {organization.defaultUserType.name}
              </div>
            </Info>
            <Info label='Created On'>
              {new Date(organization.createdAt).toLocaleDateString()}
            </Info>
            <Info label='Emergency Phone'>
              {organization.emergencyContactPhone || 'N/A'}
            </Info>
          </Columns>
          {editing && (
            <EditOrganizationDetails
              organization={organization}
              onFinishEditing={() => reloadData() && setEditing(false)}
            />
          )}
        </div>
      )}
      <div className='box'>
        <h2 className='subtitle'>Users</h2>
        <UsersList organizationId={id} />
      </div>
      <div className='box'>
        <h2 className='subtitle'>User Groups</h2>
        <UserGroupsList organizationId={id} />
      </div>
      <div className='box'>
        <h2 className='subtitle'>User Roles</h2>
        <UserRolesList organizationId={id} />
      </div>
      <div className='box'>
        <h2 className='subtitle'>Dispatch Bindings</h2>
        <DispatchBindingsList organizationId={id} />
      </div>
    </div>
  )
}
