import React, { useContext } from 'react'
import { NotificationsContext } from '../../hooks/notifications'

function NotificationItem({ component, className, onHide }) {
  return (
    <div className={className}>
      <button className='delete' onClick={onHide}></button>
      {component}
    </div>
  )
}

export default function ({
  containerClassName,
  containerStyle,
  itemClassName
}) {
  const { notifications, hideNotification } = useContext(NotificationsContext)

  if (!notifications) {
    return
  }

  return (
    <div className={containerClassName} style={containerStyle}>
      {Object.entries(notifications)
        .filter(([_, notification]) => notification)
        .reverse()
        .map(([id, { component, className }]) => (
          <NotificationItem
            key={id}
            component={component}
            className={`${itemClassName} ${className}`}
            onHide={() => hideNotification(id)}
          />
        ))}
    </div>
  )
}
