import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useApi, useForm, useError } from '../../hooks'
import { flattenArrays } from '../../hooks/form'
import DropdownField from '../Form/DropdownField'
import TextField from '../Form/TextField'
import Button from '../Button'
import ContextMenu from '../ContextMenu'
import Columns from '../Columns'

export const RESOURCE_TYPES = [
  'agent',
  'dataExport',
  'incident',
  'incident.acknowledge',
  'incident.dispatch',
  'organization',
  'site',
  'user',
  'system.usertype'
].sort()

export default function ({ organizationId, roleId }) {
  const navigate = useNavigate()
  const [client] = useApi()
  const setError = useError()
  let [getFieldProps, formData, formError] = useForm()
  const [attributesCount, setAttributesCount] = useState(1)

  async function handleSubmit(e) {
    formData = flattenArrays(formData)
    const { resource, action, possession, attributes } = formData

    e.preventDefault()

    try {
      await client(
        `admin/organizations/${organizationId}/user-roles/${roleId}/create-grant`,
        {
          resource,
          action,
          possession,
          attributes
        }
      )

      navigate(`/user-roles/${roleId}?organizationId=${organizationId}`)
    } catch (err) {
      if (err.message === 'validation_error') {
        formError(err.props)
      } else {
        setError(err)
      }
    }
  }

  let attributeProps = []
  for (let i = 0; i < attributesCount; i++) {
    const name = `attributes[${i}]`

    attributeProps.push(
      <TextField
        key={name}
        name={name}
        required={true}
        {...getFieldProps()}
        defaultValue='*'
      />
    )
  }

  return (
    <div className='box'>
      <form onSubmit={handleSubmit}>
        <Columns>
          <DropdownField
            name='resource'
            label='Resource'
            required={true}
            {...getFieldProps()}>
            <option />
            {RESOURCE_TYPES.map((resource) => (
              <option key={resource} value={resource}>
                {resource}
              </option>
            ))}
          </DropdownField>
          <DropdownField
            name='action'
            label='Action'
            required={true}
            {...getFieldProps()}>
            <option />
            <option value='create'>Create</option>
            <option value='read'>Read</option>
            <option value='update'>Update</option>
            <option value='delete'>Delete</option>
          </DropdownField>
          <DropdownField
            name='possession'
            label='Possession'
            required={true}
            {...getFieldProps()}>
            <option />
            <option value='any'>Any</option>
            <option value='own'>Own</option>
          </DropdownField>
        </Columns>

        <label className='label'>Attributes</label>
        <Button
          type='button'
          className='is-secondary'
          onClick={() => setAttributesCount(attributesCount + 1)}
          icon='fa fa-plus'>
          Add attribute
        </Button>

        <div style={{ marginTop: '1em' }}>{attributeProps}</div>

        <br />

        <ContextMenu>
          <Button type='submit'>Create</Button>
        </ContextMenu>
      </form>
    </div>
  )
}
