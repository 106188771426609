import React, { useCallback } from 'react'
import UserDetails from '../../../UserDetails'
import { formatAddress } from '../../../LocationRenderer'

function UserDispatch({ user, incident, openDetails, centerMapOnUser }) {
  const displayName =
    user.personalData?.displayName?.value || `User #${user.id}`
  const address = incident.locations.find((loc) => loc.format === 'us-address')
  const location = incident.locations.find((loc) => loc.format === 'lat-long')

  return (
    <div className='card incident-card'>
      <header className='card-header'>
        <p className='card-header-title'>{displayName}</p>
      </header>
      <div className='card-content'>
        <div className='content'>
          <span>
            Dispatched to incident <strong>{incident.name}</strong>
          </span>
          <br />
          {address && <i>{formatAddress(address)}</i>}
        </div>
      </div>
      <footer className='card-footer'>
        {location && (
          <button
            className='button is-info'
            onClick={() => centerMapOnUser(user)}>
            <i className='fas fa-map-marker-alt' />
          </button>
        )}
        <button className='button' onClick={() => openDetails(user)}>
          Details
        </button>
      </footer>
    </div>
  )
}

export default function ({
  incidents,
  deviceLocations,
  setMapCenter,
  openSecondaryPanel
}) {
  const dispatches = Object.values(
    Object.values(incidents)
      .filter((incident) => incident.status === 'in-progress')
      .reduce((acc, incident) => {
        for (const [userId, user] of Object.entries(incident.dispatch)) {
          if (!acc[userId]) {
            acc[userId] = {
              id: userId,
              ...user,
              incidents: {}
            }
          }

          acc[userId].incidents[incident.id] = incident
        }

        return acc
      }, {})
  )

  const openDetails = useCallback(
    (user) => openSecondaryPanel(<UserDetails id={user.id} />, user.email),
    [openSecondaryPanel]
  )

  const centerMapOnUser = useCallback(
    (user) => {
      const device = Object.entries(deviceLocations).find(
        ([
          deviceId,
          {
            user: { id }
          }
        ]) => user.id === id
      )

      if (!device) {
        return
      }

      setMapCenter({ lng: device[1].loc[0], lat: device[1].loc[1] })
    },
    [setMapCenter, deviceLocations]
  )

  return (
    <div style={{ display: 'flex', flexDirection: 'column', margin: '0.5em' }}>
      {dispatches.map((user) =>
        Object.values(user.incidents).map((incident) => (
          <UserDispatch
            key={`${user.id}:${incident.id}`}
            user={user}
            incident={incident}
            openDetails={openDetails}
            centerMapOnUser={centerMapOnUser}
          />
        ))
      )}
    </div>
  )
}
