import React from 'react'
import Info from '../Info'
import ExternalLink from '../ExternalLink'
import { US_STATES } from '../LocationForm'

export function formatAddress({ street, city, state, zip }) {
  return (
    <div>
      <div>{street}</div>
      <div>
        {city}, {US_STATES[state]} {zip}
      </div>
    </div>
  )
}

export default function ({ location }) {
  if (location.format === 'lat-long') {
    const { latitude, longitude } = location

    return (
      <Info label='Location'>
        {latitude.toString().slice(0, 10)}, {longitude.toString().slice(0, 10)}
        <br />
        <ExternalLink
          href={`https://maps.google.com/maps/search/${latitude},${longitude}`}
        />
        {/*<GoogleMaps
          containerStyle={{ width: '400px', height: '400px' }}
          center={{ lat: latitude, lng: longitude }}>
          <Marker position={{ lat: latitude, lng: longitude }} />
        </GoogleMaps>*/}
      </Info>
    )
  } else if (location.format === 'us-address') {
    const { state, zip, city, street } = location

    return (
      <Info label='Address'>{formatAddress({ street, city, state, zip })}</Info>
    )
  } else {
    return <div>Invalid location format.</div>
  }
}
