import { useState, useEffect } from 'react'

export function useError() {
  const [error, setError] = useState()
  if (error) {
    throw error
  }

  return setError
}

export function useBrowserLocation() {
  const [location, setLocation] = useState()

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      ({ coords: { latitude, longitude } }) =>
        setLocation([latitude, longitude])
    )
  }, [setLocation])

  return location
}
