import React, { useState, useCallback } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { useApi, useApiToken, useApiRequest, useError } from '../../hooks'
import { openResource } from '../../api'

import DataTable, { Row, RowData } from '../DataTable'
import Columns from '../Columns'
import Info from '../Info'
import DropdownMenu, { DropdownItem } from '../DropdownMenu'
import ContextMenu from '../ContextMenu'
import EditSiteDetails from '../EditSiteDetails'
import LocationRenderer from '../LocationRenderer'
import Spinner from '../Spinner'

function capitalize(s) {
  return `${s.slice(0, 1).toUpperCase()}${s.slice(1)}`
}

function SiteInfoList({ siteId, info, reloadSite }) {
  const [client] = useApi()
  const token = useApiToken()
  const setError = useError()

  async function deleteSiteInfo(id) {
    try {
      if (window.confirm('Are you sure you wish to delete this item?')) {
        await client(`admin/sites/${siteId}/info/delete`, { id })
        reloadSite()
      }
    } catch (err) {
      setError(err)
    }
  }

  return (
    <div>
      <DataTable
        columns={['Name', 'Type', 'Resource Type', 'Actions']}
        className='is-fullwidth is-hoverable'>
        {info.map((item) => (
          <Row key={item.id}>
            <RowData>{item.name}</RowData>
            <RowData>{capitalize(item.type)}</RowData>
            <RowData>{item.resource.mimeType}</RowData>
            <RowData>
              <DropdownMenu label='Actions' small={true}>
                <DropdownItem
                  onClick={() => openResource(item.resource.url, token)}>
                  View
                </DropdownItem>
                <DropdownItem onClick={() => deleteSiteInfo(item.id)}>
                  Delete
                </DropdownItem>
              </DropdownMenu>
            </RowData>
          </Row>
        ))}
      </DataTable>
    </div>
  )
}

function OrganizationBindingsList({
  siteId,
  organizationBindings,
  reloadSite
}) {
  const [client] = useApi()
  const navigate = useNavigate()
  const setError = useError()

  const deleteOrganizationBinding = useCallback(
    async (organizationId) => {
      try {
        if (
          window.confirm(
            'Are you sure you wish to delete this organization binding?'
          )
        ) {
          await client(`admin/sites/${siteId}/organization-bindings/delete`, {
            organizationId
          })
          reloadSite()
        }
      } catch (err) {
        setError(err)
      }
    },
    [client, setError, reloadSite, siteId]
  )

  organizationBindings = organizationBindings.sort(
    (a, b) => a.organization.id - b.organization.id
  )

  return (
    <div>
      <DataTable
        columns={['ID', 'Name', 'Actions']}
        className='is-fullwidth is-hoverable'>
        {organizationBindings
          .filter((x) => x)
          .map(({ organization: { id, name } }) => (
            <Row key={id}>
              <RowData>{id}</RowData>
              <RowData onClick={() => navigate(`/organizations/${id}`)}>
                {name}
              </RowData>
              <RowData>
                <DropdownMenu label='Actions' small={true}>
                  <DropdownItem onClick={() => deleteOrganizationBinding(id)}>
                    Delete
                  </DropdownItem>
                </DropdownMenu>
              </RowData>
            </Row>
          ))}
      </DataTable>
    </div>
  )
}

export default function ({ id }) {
  const navigate = useNavigate()
  const [site, reloadSite] = useApiRequest(`admin/sites/${id}`)
  const [editing, setEditing] = useState()
  const [client] = useApi()
  const setError = useError()

  async function deleteSite() {
    try {
      if (window.confirm('Are you sure you wish to delete this site?')) {
        await client(`admin/sites/${id}/delete`, {})
        navigate('/sites/list')
      }
    } catch (err) {
      setError(err)
    }
  }

  if (!site) {
    return <Spinner />
  }

  return (
    <div>
      <ContextMenu
        renderTitle={() => site && <h1 className='title'>{site.name}</h1>}>
        <DropdownMenu label='Actions'>
          <DropdownItem
            onClick={() => navigate(`/sites/info/create?siteId=${site.id}`)}>
            Add new site information
          </DropdownItem>
          <DropdownItem
            onClick={() =>
              navigate(`/sites/organization-binding/create?siteId=${site.id}`)
            }>
            Add an organization binding
          </DropdownItem>
          <DropdownItem onClick={() => setEditing(true)}>
            Edit site details
          </DropdownItem>
          <DropdownItem onClick={() => deleteSite()}>Delete site</DropdownItem>
        </DropdownMenu>
      </ContextMenu>
      <div className='box'>
        <h2 className='subtitle'>Site Details</h2>
        <Columns>
          <Info label='ID'>{site.id}</Info>
          <Info label='Name'>{site.name}</Info>
          <Info label='Organization'>
            <Link to={`/organizations/${site.organization.id}`}>
              {site.organization.name}
            </Link>
          </Info>
        </Columns>
        <Columns>
          {site.locations.map((location, index) => (
            <LocationRenderer key={index} location={location} />
          ))}
        </Columns>
        {editing && (
          <div>
            <br />
            <EditSiteDetails
              site={site}
              onFinishEditing={() => reloadSite() && setEditing(false)}
            />
          </div>
        )}
      </div>
      <div className='box'>
        <h2 className='subtitle'>Site Information</h2>
        <SiteInfoList
          siteId={site.id}
          info={site.info}
          reloadSite={reloadSite}
        />
      </div>
      <div className='box'>
        <h2 className='subtitle'>Organization Bindings</h2>
        <OrganizationBindingsList
          siteId={site.id}
          organizationBindings={site.organizationBindings}
          reloadSite={reloadSite}
        />
      </div>
    </div>
  )
}
