import React, { useState } from 'react'
import {
  useApi,
  useApiRequest,
  useApiToken,
  useError,
  useForm
} from '../../hooks'
import { httpClient } from '../../api'

import TextField from '../Form/TextField'
import FileField from '../Form/FileField'
import Button from '../Button'
import ProgressBar from '../ProgressBar'
import Columns from '../Columns'
import Spinner from '../Spinner'

export default function ({ incidentId, onAttachmentCreated }) {
  const [client] = useApi()
  const accessToken = useApiToken()
  const [incident] = useApiRequest(`admin/incidents/${incidentId}`, null)
  const [uploadProgress, setUploadProgress] = useState(null)
  const [loading, setLoading] = useState(false)
  const setError = useError()

  const [getFieldProps, formData, formError] = useForm()

  if (!incident) {
    return <Spinner />
  }

  async function handleSubmit(e) {
    e.preventDefault()

    const { name, file } = formData

    if (!file) {
      formError({ file: 'Please select a file.' })
      return
    }

    setLoading(true)

    const {
      data: { sha256 }
    } = await httpClient.put('v1/resources/upload', file, {
      onUploadProgress: (e) => setUploadProgress(e.loaded / e.total),
      headers: {
        authorization: `Bearer ${accessToken}`,
        'content-type': file.type
      },
      json: true
    })

    try {
      const attachment = await client(
        `admin/incidents/${incidentId}/attachment/create`,
        {
          name,
          sha256
        }
      )

      onAttachmentCreated && onAttachmentCreated(attachment)
    } catch (err) {
      setLoading(false)

      if (err.message === 'validation_error') {
        formError(err.props)
      } else {
        setError(err)
      }
    }
  }

  return (
    <div className='box'>
      <form onSubmit={handleSubmit}>
        <Columns slice={2}>
          <TextField label='Incident' value={incident.name} readOnly={true} />
          <TextField
            label='Name'
            name='name'
            placeholder='Name'
            required={true}
            {...getFieldProps()}
          />
          <FileField label='File' name='file' {...getFieldProps()} />
        </Columns>
        {uploadProgress && (
          <ProgressBar
            value={uploadProgress}
            className='is-info'
            style={{ marginTop: '2em' }}
          />
        )}
        <div className='level'>
          <div className='level-left' />
          <div className='level-right'>
            <div className='level-item'>
              <Button
                type='submit'
                className={loading ? 'is-loading' : ''}
                enabled={`${!loading}`}>
                Submit attachment
              </Button>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}
