import React from 'react'
import { useParams } from 'react-router-dom'
import UserDetails from '../../components/UserDetails'

export default function () {
  const { id } = useParams()
  return (
    <div className='container'>
      <UserDetails id={id} />
    </div>
  )
}
