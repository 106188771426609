import React, { useCallback } from 'react'

import './styles.scss'

export default function ({
  children,
  className,
  icon,
  loading,
  disabled,
  onClick,
  ...rest
}) {
  const handleClick = useCallback(
    (e) => {
      if (!disabled) {
        onClick && onClick(e)
      }
      e.preventDefault()
    },
    [onClick, disabled]
  )

  return (
    <button
      onClick={onClick && handleClick}
      className={`button ${className ? className : 'is-primary'} ${
        loading ? 'is-loading' : ''
      } ${disabled ? 'is-disabled' : ''}`}
      {...rest}>
      {icon && (
        <span className='icon'>
          <i className={icon}></i>
        </span>
      )}
      {children && children.length && <span>{children}</span>}
    </button>
  )
}
