import React from 'react'

import CreateIncident from '../../../components/CreateIncident'

export default function () {
  return (
    <div className='container'>
      <h1 className='title'>Create a new incident</h1>
      <CreateIncident />
    </div>
  )
}
