import React from 'react'

import Profile from '../../components/Profile'

export default function () {
  return (
    <div className='container'>
      <Profile />
    </div>
  )
}
