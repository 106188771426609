import React from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'

import Profile from './profile'
import Organizations from './organizations'
import OrganizationDetails from './organizations/details'
import CreateOrganization from './organizations/create'
import Incidents from './incidents'
import IncidentDetails from './incidents/details'
import CreateIncident from './incidents/create'
import CreateIncidentAttachment from './incidents/attachment/create'
import AddDispatchOrganization from './incidents/add-dispatch-organization'
import CCTVEventDetails from './incidents/cctv-event'
import UserDetails from './users'
import CreateUser from './users/create'
import CreateUserRoleBinding from './users/create-user-role-binding'
import CreateUserGroupBinding from './users/create-user-group-binding'
import SetUserType from './users/set-user-type'
import CreateUserRole from './user-roles/create'
import CreateUserRoleGrant from './user-role-grants/create'
import UserRoleDetails from './user-roles/details'
import CopyUserRole from './user-roles/copy'
import CreateUserGroup from './user-groups/create'
import Sites from './sites'
import SiteDetails from './sites/details'
import CreateSite from './sites/create'
import CreateSiteInfo from './sites/info/create'
import CreateSiteOrganizationBinding from './sites/organization-bindings/create'
import Agents from './agents'
import AgentDetails from './agents/details'
import CreateAgent from './agents/create'
import System from './system'
import CreateUserType from './system/user-types/create'
import UserTypeDetails from './system/user-types/details'
import Dispatch from './dispatch'
import CreateDispatchBinding from './dispatch-bindings/create'
import DataExportJobsList from './data-export'
import DataRetentionEvents from './data-retention-events'
import Chat from './chat'

export default function () {
  return (
    <Routes>
      <Route exact path='/profile' element={<Profile />} />
      <Route exact path='/organizations/list' element={<Organizations />} />
      <Route
        exact
        path='/organizations/create'
        element={<CreateOrganization />}
      />
      <Route path='/organizations/:id' element={<OrganizationDetails />} />
      <Route exact path='/users/create' element={<CreateUser />} />
      <Route exact path='/user-roles/create' element={<CreateUserRole />} />
      <Route exact path='/user-roles/:id' element={<UserRoleDetails />} />
      <Route exact path='/user-roles/copy/:id' element={<CopyUserRole />} />
      <Route
        exact
        path='/user-role-grants/:id'
        element={<CreateUserRoleGrant />}
      />
      <Route
        exact
        path='/users/:id/create-role-binding'
        element={<CreateUserRoleBinding />}
      />
      <Route
        exact
        path='/users/:id/create-group-binding'
        element={<CreateUserGroupBinding />}
      />
      <Route exact path='/user-groups/create' element={<CreateUserGroup />} />
      <Route exact path='/users/:id/set-user-type' element={<SetUserType />} />
      <Route path='/users/:id' element={<UserDetails />} />
      <Route exact path='/incidents/list' element={<Incidents />} />
      <Route exact index path='/' element={<Navigate to={'/dispatch'} />} />
      <Route
        path='/incidents/:id/cctv-events/:eventId'
        element={<CCTVEventDetails />}
      />
      <Route exact path='/incidents/create' element={<CreateIncident />} />
      <Route
        exact
        path='/incidents/attachment/create'
        element={<CreateIncidentAttachment />}
      />
      <Route
        exact
        path='/incidents/add-dispatch-organization'
        element={<AddDispatchOrganization />}
      />
      <Route path='/incidents/:id' element={<IncidentDetails />} />
      <Route exact path='/sites/list' element={<Sites />} />
      <Route exact path='/sites/create' element={<CreateSite />} />
      <Route exact path='/sites/info/create' element={<CreateSiteInfo />} />
      <Route
        exact
        path='/sites/organization-binding/create'
        element={<CreateSiteOrganizationBinding />}
      />
      <Route path='/sites/:id' element={<SiteDetails />} />
      <Route exact path='/agents/list' element={<Agents />} />
      <Route exact path='/agents/create' element={<CreateAgent />} />
      <Route path='/agents/:id' element={<AgentDetails />} />
      <Route exact path='/system' element={<System />} />
      <Route
        exact
        path='/system/user-types/create'
        element={<CreateUserType />}
      />
      <Route path='/system/user-types/:id' element={<UserTypeDetails />} />
      <Route path='/dispatch' element={<Dispatch />} />
      <Route
        path='/dispatch-bindings/create'
        element={<CreateDispatchBinding />}
      />
      <Route path='/data-export/jobs' element={<DataExportJobsList />} />
      <Route path='/data-retention-events' element={<DataRetentionEvents />} />
      <Route path='/chat' element={<Chat />} />
      <Route element={<div>Route not found</div>} />
    </Routes>
  )
}
