import React from 'react'

import CreateAgent from '../../../components/CreateAgent'

export default function () {
  return (
    <div className='container'>
      <h1 className='title'>Create a new agent</h1>
      <CreateAgent />
    </div>
  )
}
