/* eslint jsx-a11y/anchor-is-valid: 0 */

import React from 'react'
import DropdownMenu, { DropdownItem } from '../DropdownMenu'

import './styles.scss'

const itemsPerPageOpts = [10, 20, 50, 100]

export default function Pagination({
  currentPage,
  totalCount,
  itemsPerPage,
  setPage,
  setItemsPerPage,
  disabled
}) {
  const numPages = Math.ceil(totalCount / itemsPerPage)
  const pageButtons = []

  function addPageButton(pageIndex) {
    if (pageIndex < 0 || pageIndex >= numPages) {
      return
    }

    pageButtons.push(
      <li key={pageButtons.length}>
        <button
          className={`button pagination-link ${
            pageIndex === currentPage ? 'is-current' : ''
          }`}
          aria-label={`Go to page ${pageIndex + 1}`}
          onClick={() =>
            !disabled && currentPage !== pageIndex && setPage(pageIndex)
          }
          disabled={disabled}>
          {pageIndex + 1}
        </button>
      </li>
    )
  }

  function addBreak() {
    pageButtons.push(
      <li key={pageButtons.length}>
        <span className='pagination-ellipsis'>&hellip;</span>
      </li>
    )
  }

  if (currentPage > 3) {
    addPageButton(0)

    if (currentPage !== 4) {
      addBreak()
    }
  }

  for (let i = currentPage - 3; i <= currentPage + 3; i++) {
    addPageButton(i)
  }

  if (currentPage < numPages - 4) {
    if (currentPage !== numPages - 5) {
      addBreak()
    }
    addPageButton(numPages - 1)
  }

  return (
    <nav
      className='pagination is-small'
      role='navigation'
      aria-label='pagination'>
      <DropdownMenu label={`${itemsPerPage}`} style={{ order: 3 }} small>
        {itemsPerPageOpts.map((opt) => (
          <DropdownItem key={opt} onClick={() => setItemsPerPage(opt)}>
            {opt}
          </DropdownItem>
        ))}
      </DropdownMenu>
      <button
        className='button pagination-previous'
        onClick={() =>
          !disabled && currentPage !== 0 && setPage(currentPage - 1)
        }
        disabled={disabled || currentPage === 0}>
        <i className='fa fa-arrow-left' />
      </button>
      <button
        className='button pagination-next'
        onClick={() =>
          !disabled && currentPage < numPages - 1 && setPage(currentPage + 1)
        }
        disabled={disabled || currentPage >= numPages - 1}>
        <i className='fa fa-arrow-right' />
      </button>
      <ul className='pagination-list'>
        {pageButtons}
        <li>
          <span className='pagination-ellipsis'>
            Displaying items {currentPage * itemsPerPage}-
            {Math.min(totalCount, (currentPage + 1) * itemsPerPage)} from a
            total of {totalCount}
          </span>
        </li>
      </ul>
    </nav>
  )
}
