import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useApi, useForm, useError, usePagination } from '../../hooks'
import DropdownField from '../Form/DropdownField'
import TextField from '../Form/TextField'
import Button from '../Button'
import ContextMenu from '../ContextMenu'
import Columns from '../Columns'
import Spinner from '../Spinner'

export default function ({ user }) {
  const navigate = useNavigate()
  const [client] = useApi()

  let [userGroups] = usePagination({
    endpoint: `admin/organizations/${user.organization.id}/user-groups`,
    itemsPerPage: 100,
    sortBy: 'id',
    sortOrder: 'asc'
  })

  if (user && userGroups) {
    userGroups = userGroups.filter(
      (role) =>
        !user.groupBindings.find((binding) => binding.group.id === role.id)
    )
  }

  const setError = useError()

  const [getFieldProps, formData, formError] = useForm()

  if (!user) {
    return <Spinner />
  }

  async function handleSubmit(e) {
    e.preventDefault()

    try {
      await client(
        `admin/organizations/${user.organization.id}/user-groups/create-binding`,
        {
          userId: user.id,
          groupId: formData.groupId
        }
      )

      navigate(`/users/${user.id}`)
    } catch (err) {
      if (err.message === 'validation_error') {
        formError(err.props)
      } else {
        setError(err)
      }
    }
  }

  return (
    <div className='box'>
      <form onSubmit={handleSubmit}>
        <Columns>
          <TextField
            label='Organization'
            value={user.organization.name}
            readOnly={true}
            required={true}
          />
          <TextField
            label='User'
            value={user.email}
            readOnly={true}
            required={true}
          />
        </Columns>
        <DropdownField
          name='groupId'
          label='User group'
          required={true}
          {...getFieldProps()}>
          {userGroups && <option />}
          {userGroups &&
            userGroups.map((group) => (
              <option key={group.id} value={group.id}>
                {group.name}
              </option>
            ))}
          {!userGroups && <option>Loading...</option>}
        </DropdownField>
        <ContextMenu>
          <Button type='submit'>Add</Button>
        </ContextMenu>
      </form>
    </div>
  )
}
