import React from 'react'

import CreateSite from '../../../components/CreateSite'

export default function () {
  return (
    <div className='container'>
      <h1 className='title'>Create a new site</h1>
      <CreateSite />
    </div>
  )
}
