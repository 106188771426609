import React, { useState, useEffect, useRef } from 'react'
import { TwitterPicker } from 'react-color'

import Control from '../Control'

export default React.forwardRef(
  (
    {
      label,
      onChange,
      renderAddons,
      getValidationError,
      defaultValue,
      colors,
      ...props
    },
    ref
  ) => {
    const error = getValidationError && getValidationError(props.name)
    const [active, setActive] = useState(false)
    const [color, setColor] = useState(defaultValue || '#000000')

    const cachedColor = useRef(color)

    useEffect(() => {
      if (cachedColor.current === color) {
        return
      }

      cachedColor.current = color
      onChange(color)
    }, [onChange, color])

    return (
      <Control
        label={label}
        renderAddons={renderAddons}
        className={`${error ? 'has-icons-right' : ''}`}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div
            style={{
              width: '36px',
              height: '36px',
              background: color,
              borderRadius: '4px',
              marginRight: '0.5rem',
              cursor: 'pointer'
            }}
            onClick={() => setActive(true)}
          />
          <input
            className={`input ${error ? 'is-danger' : ''} `}
            type='text'
            ref={ref}
            value={color}
            readOnly={true}
            onClick={() => setActive(true)}
            {...props}
          />
        </div>
        <div style={{ visibility: active ? 'visible' : 'hidden' }}>
          <TwitterPicker
            colors={colors}
            color={color}
            onChange={(e) => {
              setColor(e.hex)
              setActive(false)
            }}
          />
        </div>
        {error && (
          <span className='icon is-small is-right'>
            <i className='fas fa-exclamation-triangle has-text-danger' />
          </span>
        )}
        {error && <p className='help is-danger'>{error}</p>}
      </Control>
    )
  }
)
