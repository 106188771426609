import React, { useCallback } from 'react'

import { useApi, useApiRequest, useForm, useError } from '../../hooks'
import DropdownField from '../Form/DropdownField'
import TextField from '../Form/TextField'
import Button from '../Button'
import ContextMenu from '../ContextMenu'
import Spinner from '../Spinner'

const ONE_DAY = 3600.0 * 24.0

const DATA_RETENTION_OPTIONS = [ONE_DAY * 7, ONE_DAY * 14, ONE_DAY * 21]

export function formatRetentionLifetime(lifetime) {
  if (lifetime <= ONE_DAY) {
    return `${Math.floor(lifetime / 3600.0)} hours`
  }

  return `${Math.floor(lifetime / ONE_DAY)} days`
}

export default function ({ organization, onFinishEditing }) {
  const [client] = useApi()
  const setError = useError()
  let [response] = useApiRequest('admin/user-types?limit=100')
  const userTypes = response?.items
  const [timezones] = useApiRequest('v2/organizations/time-zones')

  const [getFieldProps, formData, formError] = useForm({
    dependencies: [response]
  })

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault()

      const {
        name,
        defaultUserTypeId,
        dataRetentionLifetime,
        timezone,
        status,
        emergencyContactPhone
      } = formData

      try {
        await client(`v2/organizations/${organization.id}`, {
          name,
          defaultUserTypeId,
          dataRetentionLifetime,
          timezone,
          status,
          emergencyContactPhone:
            emergencyContactPhone.length !== 0 ? emergencyContactPhone : null
        })
        onFinishEditing()
      } catch (err) {
        if (err.message === 'validation_error') {
          formError(err.props)
        } else {
          setError(err)
        }
      }
    },
    [formData, client, onFinishEditing, formError, setError, organization]
  )

  if (!userTypes || !timezones) {
    return <Spinner />
  }

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        name='name'
        label='Name'
        defaultValue={organization.name}
        {...getFieldProps()}
      />
      <DropdownField
        name='status'
        label='Status'
        defaultValue={organization?.status}
        required={true}
        {...getFieldProps()}>
        <option value='active'>Active</option>
        <option value='inactive'>Inactive</option>
      </DropdownField>
      <DropdownField
        name='defaultUserTypeId'
        label='Default User Type'
        required={true}
        defaultValue={organization?.defaultUserType.id || 1}
        {...getFieldProps()}>
        {userTypes.map((userType) => (
          <option key={userType.id} value={userType.id}>
            {userType.name}
          </option>
        ))}
      </DropdownField>
      <DropdownField
        name='dataRetentionLifetime'
        label='Data Retention Lifetime'
        required={true}
        defaultValue={organization?.dataRetentionLifetime}
        {...getFieldProps()}>
        {DATA_RETENTION_OPTIONS.map((lifetime, index) => (
          <option key={index} value={lifetime}>
            {formatRetentionLifetime(lifetime)}
          </option>
        ))}
      </DropdownField>
      <DropdownField
        name='timezone'
        label='Time Zone'
        defaultValue={organization?.timezone}
        required={true}
        {...getFieldProps()}>
        {timezones.map((tz, index) => (
          <option key={index} value={tz.name}>
            {tz.name} ({tz.alternativeName})
          </option>
        ))}
      </DropdownField>
      <TextField
        name='emergencyContactPhone'
        label='Emergency Phone'
        defaultValue={organization.emergencyContactPhone}
        {...getFieldProps()}
      />
      <ContextMenu>
        <Button type='submit'>Update</Button>
      </ContextMenu>
    </form>
  )
}
