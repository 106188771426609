import React from 'react'
import { useParams } from 'react-router-dom'
import EditUserType from '../../../../components/EditUserType'

export default function () {
  const { id } = useParams()
  return (
    <div className='container'>
      <EditUserType id={id} />
    </div>
  )
}
