import React from 'react'

import DropdownField from '../Form/DropdownField'
import TextField from '../Form/TextField'
import Columns from '../Columns'

export const US_STATES = {
  AL: 'Alabama',
  AK: 'Alaska',
  AS: 'American Samoa',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District Of Columbia',
  FM: 'Federated States Of Micronesia',
  FL: 'Florida',
  GA: 'Georgia',
  GU: 'Guam',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MH: 'Marshall Islands',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  MP: 'Northern Mariana Islands',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PW: 'Palau',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VI: 'Virgin Islands',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming'
}

export function parseLocation(data) {
  const { format } = data
  let location = { format }

  if (format === 'lat-long') {
    location.latitude = parseFloat(data.latitude)
    location.longitude = parseFloat(data.longitude)
  } else if (format === 'us-address') {
    location.state = data.state
    location.zip = data.zip
    location.city = data.city
    location.street = data.street
  } else {
    throw new Error('invalid_format')
  }

  return location
}

export function LatLongForm({ getFieldProps, defaultValue }) {
  defaultValue = defaultValue || {}

  return (
    <Columns>
      <TextField
        label='Latitude'
        name='latitude'
        type='text'
        required={true}
        defaultValue={defaultValue.latitude}
        {...getFieldProps()}
      />
      <TextField
        label='Longitude'
        name='longitude'
        type='text'
        required={true}
        defaultValue={defaultValue.longitude}
        {...getFieldProps()}
      />
    </Columns>
  )
}

export function AddressForm({ getFieldProps, defaultValue }) {
  defaultValue = defaultValue || {}

  return (
    <div>
      <Columns>
        <TextField
          label='Street'
          name='street'
          defaultValue={defaultValue.street}
          {...getFieldProps()}
        />
        <TextField
          label='City'
          name='city'
          defaultValue={defaultValue.city}
          {...getFieldProps()}
        />
      </Columns>
      <Columns>
        <DropdownField
          label='State'
          name='state'
          value={defaultValue.state}
          {...getFieldProps()}>
          <option />
          {Object.entries(US_STATES).map(([code, name]) => (
            <option key={code} value={code}>
              {name}
            </option>
          ))}
        </DropdownField>
        <TextField
          label='ZIP'
          name='zip'
          defaultValue={defaultValue.zip}
          {...getFieldProps()}
        />
      </Columns>
    </div>
  )
}

export default function ({ getFieldProps, formData, defaultValue }) {
  defaultValue = defaultValue || {}

  return (
    <div>
      <DropdownField
        label='Location format'
        name='format'
        required={true}
        defaultValue={defaultValue.format}
        {...getFieldProps()}>
        <option />
        <option value='lat-long'>Latitude and longitude</option>
        <option value='us-address'>Address</option>
      </DropdownField>
      {formData && formData.format === 'lat-long' && (
        <LatLongForm
          getFieldProps={getFieldProps}
          defaultValue={defaultValue}
        />
      )}
      {formData && formData.format === 'us-address' && (
        <AddressForm
          getFieldProps={getFieldProps}
          defaultValue={defaultValue}
        />
      )}
    </div>
  )
}
