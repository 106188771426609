import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import CreateIncidentAttachment from '../../../../components/CreateIncidentAttachment'

export default function () {
  const navigate = useNavigate()
  const query = new URLSearchParams(useLocation().search)
  const incidentId = parseInt(query.get('incidentId'))

  return (
    <div className='container'>
      <h1 className='title'>Create an incident attachment</h1>
      <CreateIncidentAttachment
        incidentId={incidentId}
        onAttachmentCreated={() => navigate(`/incidents/${incidentId}`)}
      />
    </div>
  )
}
