/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useReducer, useState, useEffect, useCallback } from 'react'

export function useForm(opts) {
  opts = opts || {}

  const [errors, pushError] = useReducer(
    (errors, formError) => ({ ...errors, ...formError }),
    {}
  )
  const [state, setState] = useState()
  const refs = []

  const getValidationError = useCallback(
    (name) => {
      return errors[name]
    },
    [errors]
  )

  const getFieldProps = useCallback(() => {
    const ref = React.createRef()
    refs.push(ref)

    function onChange() {
      let { name, value, checked, files, type } = ref.current
      if (type === 'checkbox') {
        value = checked
      } else if (type === 'file') {
        value = files[0]
      }

      setState((s) => ({
        ...s,
        [name]: value
      }))

      opts.onFormValueChanged && opts.onFormValueChanged(name, value)
    }

    return { ref, getValidationError, onChange }
  }, [opts, setState, getValidationError])

  useEffect(() => {
    if (state !== undefined || refs.length === 0) {
      return
    }

    setState(
      Object.fromEntries(
        refs.map(({ current: { type, name, value, checked, files } }) => {
          if (type === 'checkbox') {
            return [name, checked]
          } else if (type === 'file') {
            return [name, files[0]]
          } else {
            return [name, value]
          }
        })
      )
    )
  }, [state, opts.dependencies])

  const pushState = useCallback((newState) => {
    if (!newState) {
      for (const ref of refs) {
        if (ref.current) {
          ref.current.value = ''
        }
      }

      setState()
      return
    }

    if (typeof newState === 'function') {
      setState((s) => {
        const s1 = newState(s)
        for (const ref of refs) {
          if (ref.current) {
            ref.current.value = s1[ref.current.name]
          }
        }
        return s1
      })
    } else {
      for (const ref of refs) {
        if (ref.current) {
          ref.current.value = newState[ref.current.name]
        }
      }

      setState(newState)
    }
  }, [])

  return [getFieldProps, state, pushError, pushState]
}

export function flattenArrays(formData) {
  for (const arrayName of [
    ...new Set(
      Object.keys(formData)
        .filter((key) => key.indexOf('[') !== -1)
        .map((key) => key.slice(0, key.indexOf('[')))
    )
  ]) {
    formData[arrayName] = []

    for (let i = 0; ; i++) {
      const prop = `${arrayName}[${i}]`
      if (!formData.hasOwnProperty(prop)) {
        break
      }

      formData[arrayName].push(formData[prop])
      delete formData[prop]
    }
  }

  return formData
}
