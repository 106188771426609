import React from 'react'
import { useParams } from 'react-router-dom'
import AgentDetails from '../../../components/AgentDetails'

export default function () {
  const { id } = useParams()
  return (
    <div className='container'>
      <AgentDetails id={id} />
    </div>
  )
}
