import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useApi, useError, useApiRequest, useForm } from '../../hooks'

import Button from '../Button'
import TextField from '../Form/TextField'
import DropdownField from '../Form/DropdownField'
import Columns from '../Columns'
import ContextMenu from '../ContextMenu'
import Spinner from '../Spinner'

export default function ({ id, organizationId }) {
  const navigate = useNavigate()
  const [client] = useApi()
  const [organization] = useApiRequest(`admin/organizations/${organizationId}`)
  const [userRole] = useApiRequest(
    `admin/organizations/${organizationId}/user-roles/${id}`
  )
  const [organizations] = useApiRequest(
    'admin/organizations?limit=250&sortBy=name&sortOrder=asc'
  )
  const setError = useError()

  const [getFieldProps, formData, formError] = useForm()

  if (!organization || !userRole || !organizations) {
    return <Spinner />
  }

  async function handleSubmit(e) {
    e.preventDefault()

    const { targetOrganizationId } = formData

    try {
      const newUserRole = await client(
        `v2/user-roles/${organizationId}/${id}/copy`,
        {
          targetOrganizationId
        }
      )

      navigate(
        `/user-roles/${newUserRole.id}?organizationId=${targetOrganizationId}`
      )
    } catch (err) {
      if (err.message === 'validation_error') {
        formError(err.props)
      } else {
        setError(err)
      }
    }
  }

  return (
    <div>
      <ContextMenu
        renderTitle={() => (
          <h1 className='title'>Copy role "{userRole.name}"</h1>
        )}></ContextMenu>
      <div className='box'>
        <h2 className='subtitle'>Details</h2>
        <form onSubmit={handleSubmit}>
          <Columns>
            <TextField
              label='Source Organization'
              value={organization.name}
              readOnly={true}
            />
            <TextField
              label='Role Name'
              value={userRole.name}
              readOnly={true}
            />
          </Columns>
          <Columns>
            <DropdownField
              name='targetOrganizationId'
              label='Target Organization'
              {...getFieldProps()}>
              <option />

              {organizations.items
                .filter(({ id }) => id !== organizationId)
                .map(({ id, name }) => (
                  <option value={id} key={id}>
                    {name}
                  </option>
                ))}
            </DropdownField>
          </Columns>
          <ContextMenu>
            <Button type='submit'>Copy</Button>
          </ContextMenu>
        </form>
      </div>
    </div>
  )
}
