import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

export function useDraggable(onDrop) {
  const ref = useRef()
  const [dragging, setDragging] = useState()
  const [dragAnchor, setDragAnchor] = useState()

  const onDragStart = useCallback(
    (e) => {
      const el = ref.current
      if (!el) {
        return
      }

      const rect = el.getBoundingClientRect()
      const x = e.clientX - rect.left
      const y = e.clientY - rect.top

      setDragAnchor([x, y])
      setDragging(true)
    },
    [setDragging, setDragAnchor]
  )

  const onDragEnd = useCallback(
    (e) => {
      setDragging(false)

      onDrop && onDrop(e)

      const el = ref.current
      if (el) {
        el.style.removeProperty('transform')
      }
    },
    [setDragging, onDrop]
  )

  useEffect(() => {
    if (!dragging) {
      if (ref.current) {
        ref.current.style.setProperty('transform', 'none')
      }
      return
    }

    function onMouseMove(e) {
      if (ref.current) {
        const x = e.offsetX + dragAnchor[0]
        const y = e.offsetY + dragAnchor[1]

        ref.current.style.setProperty('transform', `translate(${x}px, ${y}px)`)
      }
    }

    window.document.addEventListener('mousemove', onMouseMove)
    const el = ref.current

    return () => {
      window.document.removeEventListener('mousemove', onMouseMove)
      if (el) {
        el.style.setProperty('transform', 'none')
      }
    }
  }, [dragging, dragAnchor])

  const props = useMemo(() => {
    return { ref, onDragStart, onDragEnd, draggable: true }
  }, [onDragStart, onDragEnd])

  return [props]
}
