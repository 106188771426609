import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useApi, useError, useForm } from '../../hooks'

import TextField from '../Form/TextField'
import ColorField from '../Form/ColorField'
import IconField from '../Form/IconField'
import Button from '../Button'
import ContextMenu from '../ContextMenu'

export const USER_GROUP_FLAGS = {}

export function parseUserGroupFormData(formData) {
  const { name, icon, color } = formData
  let flags = 0

  return {
    name,
    icon,
    color,
    flags
  }
}

export function UserGroupForm({ defaultValue, getFieldProps }) {
  defaultValue = defaultValue || {}

  return (
    <div>
      <TextField
        label='Name'
        name='name'
        placeholder='Name'
        defaultValue={defaultValue.name}
        required={true}
        {...getFieldProps()}
      />
      <IconField
        label='Icon'
        name='icon'
        iconColor={defaultValue.color || '#000000'}
        defaultValue={defaultValue.icon}
        required={true}
        {...getFieldProps()}
      />
      <ColorField
        label='Color'
        name='color'
        required={true}
        defaultValue={defaultValue.color}
        {...getFieldProps()}
      />
      {/*<label className='label has-text-info'>Settings</label>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <CheckboxField
          label='Dispatchable'
          name='dispatchable'
          defaultChecked={defaultValue.dispatchable}
          {...getFieldProps()}
        />
        <CheckboxField
          label='Force background location'
          name='backgroundLocation'
          defaultChecked={defaultValue.backgroundLocation}
          {...getFieldProps()}
        />
        <CheckboxField
          label='Security leader'
          name='securityLeader'
          defaultChecked={defaultValue.securityLeader}
          {...getFieldProps()}
        />
        <CheckboxField
          label='Citizen panic button'
          name='citizenPanicButton'
          defaultChecked={defaultValue.citizenPanicButton}
          {...getFieldProps()}
        />
    </div>*/}
    </div>
  )
}

export default function ({ organizationId }) {
  const navigate = useNavigate()
  const [client] = useApi()
  const setError = useError()

  const [getFieldProps, formData, formError] = useForm()

  async function handleSubmit(e) {
    e.preventDefault()

    try {
      const userGroup = await client(
        `admin/organizations/${organizationId}/user-groups/create`,
        parseUserGroupFormData(formData)
      )
      navigate(`/user-groups/${userGroup.id}`)
    } catch (err) {
      if (err.message === 'validation_error') {
        formError(err.props)
      } else {
        setError(err)
      }
    }
  }

  return (
    <div className='box'>
      <form onSubmit={handleSubmit}>
        <UserGroupForm getFieldProps={getFieldProps} />
        <br />
        <ContextMenu>
          <Button type='submit'>Create</Button>
        </ContextMenu>
      </form>
    </div>
  )
}
