import React, { useRef, useEffect, useState } from 'react'
import TextField from '../Form/TextField'
import { useApi } from '../../hooks'

import styles from './styles.module.scss'

function UserItem({ user, isSelected, setSelected }) {
  return (
    <div
      className={`${styles['user']} ${isSelected ? styles.selected : ''}`}
      onClick={() => setSelected(user.id)}>
      <div className={styles.name}>
        {user.personalData?.displayName?.value ||
          user.email ||
          `User #${user.id}`}
      </div>
      <div
        className={`${styles.checkmark} ${isSelected ? styles.selected : ''}`}>
        <i className='fa fa-check' />
      </div>
    </div>
  )
}

export default function ({ defaultSelectedIds, onChange }) {
  const [, setFilter] = useState()
  const [client] = useApi()
  const [users, setUsers] = useState([])
  const [selectedIds, setSelectedIds] = useState({})

  useEffect(() => {
    if (!defaultSelectedIds) {
      return
    }

    setSelectedIds(
      defaultSelectedIds.reduce(
        (acc, userId) => ({ ...acc, [userId]: true }),
        {}
      )
    )
  }, [defaultSelectedIds])

  useEffect(() => {
    onChange &&
      onChange(
        Object.entries(selectedIds)
          .filter(([, isSelected]) => isSelected)
          .map(([id]) => parseInt(id))
      )
  }, [selectedIds, onChange])

  const userListRef = useRef()

  useEffect(() => {
    if (!userListRef.current) {
      return
    }

    setUsers([])

    let offset = 0
    let loading = false
    let hasMore = true

    async function loadUsers() {
      if (loading || !hasMore) {
        return
      }

      loading = true
      try {
        const { items, count } = await client(
          `/chat/user-list?offset=${offset}&limit=10`
        )
        offset += items.length
        if (items.length !== 0) {
          setUsers((s) => [...s, ...items])
        }
        hasMore = offset < count
      } catch (err) {
        console.error(err)
      }
      loading = false
    }

    loadUsers()

    const userList = userListRef.current

    const scrollListener = () => {
      if (userList.offsetHeight + userList.scrollTop >= userList.scrollHeight) {
        if (!loading && hasMore) {
          loadUsers()
        }
      }
    }

    userList.addEventListener('scroll', scrollListener)

    return () => {
      userList.removeEventListener('scroll', scrollListener)
    }
  }, [setUsers, client])

  return (
    <div>
      <TextField name='filter' label='Search for users' onChange={setFilter} />

      <div className={styles['user-list']} ref={userListRef}>
        {users.map((user) => (
          <UserItem
            key={user.id}
            user={user}
            isSelected={!!selectedIds[user.id]}
            setSelected={() =>
              setSelectedIds({
                ...selectedIds,
                [user.id]: selectedIds[user.id] ? false : true
              })
            }
          />
        ))}
      </div>
    </div>
  )
}
