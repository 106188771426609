import React, { useState } from 'react'
import { useApi, useApiRequest, useError, useForm } from '../../../../hooks'

import TextField from '../../../Form/TextField'
import TextAreaField from '../../../Form/TextAreaField'
import ColorField from '../../../Form/ColorField'
import Button from '../../../Button'
import Columns from '../../../Columns'
import Spinner from '../../../Spinner'

const INCIDENT_MAP_MARKER_COLORS = ['#eb144c', '#ff6900', '#00d084']

export default function ({ incidentId, onMarkerCreated, mapSelection }) {
  const [client] = useApi()
  const [incident] = useApiRequest(`admin/incidents/${incidentId}`, null)
  const [loading, setLoading] = useState(false)
  const setError = useError()

  const [getFieldProps, formData, formError] = useForm()

  if (!incident) {
    return <Spinner />
  }

  async function handleSubmit(e) {
    e.preventDefault()

    const { label, description, color } = formData

    setLoading(true)

    try {
      const marker = await client(
        `admin/incidents/${incidentId}/map-marker/create`,
        {
          latitude: mapSelection.loc[1],
          longitude: mapSelection.loc[0],
          label: label.toUpperCase(),
          description,
          color,
          icon: 'n/a'
        }
      )

      onMarkerCreated && onMarkerCreated(marker)
    } catch (err) {
      setLoading(false)

      if (err.message === 'validation_error') {
        formError(err.props)
      } else {
        setError(err)
      }
    }
  }

  return (
    <div className='box'>
      <form onSubmit={handleSubmit}>
        <Columns slice={2}>
          <TextField label='Incident' value={incident.name} readOnly={true} />
          <TextField
            label='Latitude'
            value={mapSelection.loc[1]}
            readOnly={true}
          />
          <TextField
            label='Longitude'
            value={mapSelection.loc[0]}
            readOnly={true}
          />
          <TextField
            label='Label'
            name='label'
            placeholder='Label'
            maxLength={4}
            required={true}
            {...getFieldProps()}
          />
          <TextAreaField
            label='Description'
            name='description'
            placeholder='Description'
            required={true}
            {...getFieldProps()}
          />
          <ColorField
            label='Color'
            name='color'
            required={true}
            defaultValue={'#eb144c'}
            colors={INCIDENT_MAP_MARKER_COLORS}
            {...getFieldProps()}
          />
        </Columns>
        <div className='level'>
          <div className='level-left' />
          <div className='level-right'>
            <div className='level-item'>
              <Button
                type='submit'
                className={loading ? 'is-loading' : ''}
                enabled={`${!loading}`}>
                Create marker
              </Button>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}
