import React from 'react'
import { useLocation } from 'react-router-dom'

import CreateSiteInfo from '../../../../components/CreateSiteInfo'

export default function () {
  const query = new URLSearchParams(useLocation().search)
  const siteId = parseInt(query.get('siteId'))

  return (
    <div className='container'>
      <h1 className='title'>Add new site information</h1>
      <CreateSiteInfo siteId={siteId} />
    </div>
  )
}
