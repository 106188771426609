import React from 'react'
import { useLocation } from 'react-router-dom'

import DataRetentionEvents from '../../components/DataRetentionEvents'

export default function () {
  const query = new URLSearchParams(useLocation().search)
  const organizationId = parseInt(query.get('organizationId'))

  return (
    <div className='container'>
      <h1 className='title'>Data retention events</h1>
      <DataRetentionEvents organizationId={organizationId} />
    </div>
  )
}
