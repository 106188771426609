/* eslint jsx-a11y/anchor-is-valid: 0 */

import React from 'react'
import Pagination from '../Pagination'
import Spinner from '../Spinner'

import './styles.scss'

export default function ({
  columns,
  rows,
  currentPage,
  totalCount,
  loading,
  setPage,
  onRowClick,
  opts,
  setOpts,
  className
}) {
  return (
    <div>
      <div style={{ width: '100%', overflowX: 'auto' }}>
        <table className={`table ${className}`}>
          <thead>
            <tr>
              {columns.map(({ name, sortKey }) => (
                <th
                  key={name}
                  className='has-text-info'
                  style={{ cursor: sortKey ? 'pointer' : 'text' }}
                  onClick={() =>
                    sortKey &&
                    setOpts({
                      ...opts,
                      sortBy: sortKey,
                      sortOrder:
                        opts.sortBy !== sortKey
                          ? 'asc'
                          : opts.sortOrder === 'asc'
                          ? 'desc'
                          : 'asc'
                    })
                  }>
                  {name}
                  {opts.sortBy === sortKey ? (
                    <a className=''>
                      <i
                        className={`fa ${
                          opts.sortOrder === 'asc'
                            ? 'fa-sort-up'
                            : 'fa-sort-down'
                        } sort-arrow`}
                      />
                    </a>
                  ) : (
                    ''
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className='is-family-monospace'>
            {rows &&
              rows.map((item, index) => (
                <tr
                  key={index}
                  style={{ cursor: onRowClick ? 'pointer' : null }}
                  onClick={onRowClick && (() => onRowClick(item))}>
                  {columns.map((column, index) => (
                    <td key={index}>{column.render(item)}</td>
                  ))}
                </tr>
              ))}
            {(!rows || rows.length === 0) && (
              <tr>
                <td colSpan='100'>
                  {!loading && 'No data'}
                  {loading && <Spinner />}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <Pagination
        currentPage={currentPage}
        totalCount={totalCount}
        itemsPerPage={opts.itemsPerPage}
        setPage={setPage}
        setItemsPerPage={(itemsPerPage) =>
          setOpts({
            ...opts,
            itemsPerPage
          })
        }
        disabled={loading}
      />
    </div>
  )
}
