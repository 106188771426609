import React from 'react'

export default React.forwardRef(
  ({ label, renderAddons, getValidationError, ...props }, ref) => {
    const error = getValidationError && getValidationError(props.name)

    return (
      <>
        <label className='checkbox'>
          <input type='checkbox' {...props} ref={ref} /> {label}
        </label>
        {error && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span className='icon is-small is-right'>
              <i className='fas fa-exclamation-triangle has-text-danger' />
            </span>
            <p className='help is-danger' style={{ marginLeft: '0.25rem' }}>
              {error}
            </p>
          </div>
        )}
      </>
    )
  }
)
