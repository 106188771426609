import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useApi, useApiRequest, useError, useForm } from '../../hooks'

import TextField from '../Form/TextField'
import DropdownField from '../Form/DropdownField'
import Button from '../Button'
import Spinner from '../Spinner'

export default function ({ siteId }) {
  const navigate = useNavigate()
  const [client] = useApi()
  const [site] = useApiRequest(`admin/sites/${siteId}`, null)
  const [response] = useApiRequest(
    'admin/organizations?limit=100&sortBy=name&sortOrder=asc',
    null
  )
  const setError = useError()

  const [getFieldProps, formData, formError] = useForm()

  if (!site || !response) {
    return <Spinner />
  }

  const { items: organizations } = response

  async function handleSubmit(e) {
    e.preventDefault()

    const { organizationId } = formData

    try {
      await client(`admin/sites/${siteId}/organization-bindings/create`, {
        organizationId
      })

      navigate(`/sites/${siteId}`)
    } catch (err) {
      if (err.message === 'validation_error') {
        formError(err.props)
      } else {
        setError(err)
      }
    }
  }

  return (
    <div className='box'>
      <form onSubmit={handleSubmit}>
        <TextField label='Site' value={site.name} readOnly={true} />
        <DropdownField
          name='organizationId'
          label='Organization'
          required={true}
          {...getFieldProps()}>
          <option />

          {organizations.map(({ id, name }) => (
            <option value={id} key={id}>
              {name}
            </option>
          ))}
        </DropdownField>
        <div className='level'>
          <div className='level-left' />
          <div className='level-right'>
            <div className='level-item'>
              <Button type='submit'>Submit</Button>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}
