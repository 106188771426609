import React from 'react'
import { useLocation } from 'react-router-dom'

import CreateSiteOrganizationBinding from '../../../../components/CreateSiteOrganizationBinding'

export default function () {
  const query = new URLSearchParams(useLocation().search)
  const siteId = parseInt(query.get('siteId'))

  return (
    <div className='container'>
      <h1 className='title'>Create a new site-organization binding</h1>
      <CreateSiteOrganizationBinding siteId={siteId} />
    </div>
  )
}
