import React from 'react'

import './styles.scss'

export function PanelSection({
  title,
  children,
  className,
  bodyStyle,
  ref,
  ...props
}) {
  return (
    <div
      ref={ref}
      className={`dispatch-panel-section ${className ? className : ''}`}
      {...props}>
      <div className='dispatch-panel-section-header'>{title}</div>
      <div className='dispatch-panel-section-body' style={bodyStyle}>
        {children}
      </div>
    </div>
  )
}

export default function ({ title, style, children, onHideClick }) {
  return (
    <div className='dispatch-panel' style={style}>
      {title && (
        <div className='dispatch-panel-title'>
          <h2 className='subtitle'>{title}</h2>
          <div className='dispatch-panel-hide'>
            <button className='button' onClick={onHideClick}>
              <i className='far fa-times-circle' />
            </button>
          </div>
        </div>
      )}
      <div className='dispatch-panel-body'>{children}</div>
    </div>
  )
}
