import React, { useCallback, useEffect, useState, useMemo } from 'react'
import {
  useApi,
  useForm,
  useNotifications,
  useFlashBackground
} from '../../../../hooks'
import { PanelSection } from '../../DispatchPanel'
import { AddressForm, LatLongForm } from '../../../LocationForm'
import Button from '../../../Button'
import TextAreaField from '../../../Form/TextAreaField'
import CheckboxField from '../../../Form/CheckboxField'
import Spinner from '../../../Spinner'
import { formatAddress } from '../../../LocationRenderer'
import { iconClassName } from '../../DispatchMap/icons'
import { USER_TYPE_FLAGS } from '../../../CreateUserType'

import './styles.scss'

function mapGeocodeAddress(geocodeResult) {
  const { address_components: components } = geocodeResult

  const streetComponent = components.find((c) => c.types[0] === 'route')
  const numberComponent = components.find((c) => c.types[0] === 'street_number')
  let cityComponent = components.find((c) => c.types[0] === 'locality')
  if (!cityComponent) {
    cityComponent = components.find(
      (c) => c.types[0] === 'administrative_area_level_2'
    )
  }

  const stateComponent = components.find(
    (c) => c.types[0] === 'administrative_area_level_1'
  )
  const zipComponent = components.find((c) => c.types[0] === 'postal_code')

  let street = ''
  if (numberComponent) {
    street += numberComponent.long_name + ' '
  }

  if (streetComponent) {
    street += streetComponent.long_name
  }

  return {
    street,
    city: (cityComponent && cityComponent.long_name) || '',
    state: (stateComponent && stateComponent.short_name) || '',
    zip: (zipComponent && zipComponent.long_name) || ''
  }
}

async function reverseGeocode(latitude, longitude) {
  const geocoder = new window.google.maps.Geocoder()
  const result = await new Promise((resolve, reject) => {
    geocoder.geocode(
      { location: { lng: longitude, lat: latitude } },
      (results, status) => {
        if (status !== 'OK' || results.length === 0) {
          reject(status)
          return
        }

        resolve(results[0])
      }
    )
  })

  const address = mapGeocodeAddress(result)
  return { address }
}

const ORGS_PREFIX = 'organization:'

function incidentNameFromDescription(description) {
  description = description.trim()

  const maxLength = 40
  if (description.length <= maxLength) {
    return description
  }

  let name = ''
  for (const word of description.split(' ')) {
    if (name.length + word.length > maxLength) {
      break
    }

    name += word + ' '
  }

  return name.slice(0, maxLength).trim()
}

export default function ({
  mapSelection,
  userTypes,
  dispatchBindings,
  profile,
  onIncidentCreated,
  addressSearchBoxRef,
  flashAddressSearchBox
}) {
  const [getFieldProps, formData, formError, setFormData] = useForm()
  const [editing, setEditing] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [client] = useApi()
  const addNotification = useNotifications()
  const [error, setError] = useState(null)
  const [flashLocBgRef, flashLocBg] = useFlashBackground()

  const setLookup = useCallback(() => {
    const el = addressSearchBoxRef.current
    if (el) {
      el.focus()
      flashAddressSearchBox()
    }
  }, [addressSearchBoxRef, flashAddressSearchBox])

  const userTypesById = useMemo(() => {
    return userTypes.reduce(
      (acc, userType) => ({
        ...acc,
        [userType.id]: userType
      }),
      {}
    )
  }, [userTypes])

  const dispatchOrgs = useMemo(() => {
    const dispatchableUserTypes = userTypes.filter(
      ({ flags }) => !!(flags & USER_TYPE_FLAGS.DISPATCHABLE)
    )

    const orgs = [
      profile.organization,
      ...dispatchBindings.map(
        ({ dispatchOrganization }) => dispatchOrganization
      )
    ]

    return orgs
      .filter((x) => x)
      .filter(({ defaultUserType }) =>
        dispatchableUserTypes.find(({ id }) => id === defaultUserType.id)
      )
      .reduce((acc, organization) => {
        const { defaultUserType } = organization

        if (!acc[defaultUserType.id]) {
          acc[defaultUserType.id] = []
        }

        acc[defaultUserType.id].push(organization)
        return acc
      }, {})
  }, [userTypes, dispatchBindings, profile])

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault()
      setError(null)

      let { description, latitude, longitude, street, city, state, zip } =
        formData

      if (!latitude || !longitude) {
        flashLocBg()
        return
      }

      latitude = parseFloat(latitude)
      if (Number.isNaN(latitude)) {
        formError({ latitude: 'Invalid value.' })
        return
      }

      longitude = parseFloat(longitude)
      if (Number.isNaN(longitude)) {
        formError({ longitude: 'Invalid value. ' })
        return
      }

      const dispatchOrganizationIds = Object.entries(formData)
        .filter(([key, value]) => key.startsWith(ORGS_PREFIX) && value)
        .map(([key]) => parseInt(key.slice(ORGS_PREFIX.length)))

      if (dispatchOrganizationIds.length === 0) {
        setError({ dispatchOrganizations: true })
        return
      }

      const dispatchOrganizations = dispatchOrganizationIds.map((id) => ({
        id,
        offDuty: formData[`offduty:${ORGS_PREFIX}${id}`] || false
      }))

      setEditing(false)
      setSubmitting(true)

      const locations = []

      if (latitude && longitude) {
        locations.push({
          format: 'lat-long',
          latitude,
          longitude
        })
      }

      const hasAddress = street || city || state || zip
      if (hasAddress) {
        locations.push({
          format: 'us-address',
          street,
          city,
          state,
          zip
        })
      }

      try {
        const incident = await client('admin/incidents/create', {
          name: incidentNameFromDescription(description),
          description,
          status: 'in-progress',
          locations,
          dispatchOrganizations
        })

        addNotification(
          <div>
            <div>New incident launched.</div>
            <div>
              <strong>{incident.name}</strong>
            </div>
            {hasAddress && <div>{formatAddress(formData)}</div>}
          </div>,
          { className: 'is-success' }
        )

        onIncidentCreated(incident)
      } catch (err) {
        setSubmitting(false)

        if (!editing) {
          setEditing(true)
        }

        if (err.message === 'validation_error') {
          err.props.description = err.props.name
          formError(err.props)
        } else {
          setError(err)
        }
      }
    },
    [
      client,
      editing,
      formData,
      formError,
      setError,
      addNotification,
      onIncidentCreated,
      flashLocBg
    ]
  )

  const clearLocation = useCallback(() => {
    setFormData(null)
    setEditing(false)
  }, [setFormData, setEditing])

  useEffect(() => {
    if (!mapSelection) {
      return
    }

    const [longitude, latitude] = mapSelection.loc

    reverseGeocode(latitude, longitude)
      .then(({ address }) =>
        setFormData((formData) => ({
          ...formData,
          ...address,
          latitude,
          longitude
        }))
      )
      .catch((err) => {
        console.error(err)

        setFormData((formData) => ({
          ...formData,
          latitude,
          longitude
        }))
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapSelection])

  return (
    <form style={{ width: '100%' }} onSubmit={handleSubmit}>
      <PanelSection title='1. Location'>
        {(!formData || !formData.latitude) && !editing && (
          <div ref={flashLocBgRef} className='location-select'>
            <div className='location-option' onClick={() => setLookup()}>
              <i className='fa fa-search' />
              <div>Lookup</div>
            </div>
            <div className='location-option'>
              <i className='fa fa-map-marker-alt' />
              <div>Click on map</div>
            </div>
            <div className='location-option' onClick={() => setEditing(true)}>
              <i className='fa fa-keyboard' />
              <div>Custom</div>
            </div>
          </div>
        )}
        {formData && formData.latitude && mapSelection && !editing && (
          <div>
            <div className='location-address'>{formatAddress(formData)}</div>
            <div className='location-latlng'>
              {formData.latitude.toFixed(8)}, {formData.longitude.toFixed(8)}{' '}
              (Lat., Lon.)
            </div>

            {!submitting && (
              <div className='location-menu'>
                <button
                  type='button'
                  className='button is-text'
                  onClick={() => setEditing(true)}
                  style={{
                    color: '#7787D4',
                    fontWeight: 400
                  }}>
                  Edit
                </button>
                <button
                  type='button'
                  className='button is-text'
                  onClick={() => clearLocation()}
                  style={{
                    color: '#7787D4',
                    fontWeight: 400
                  }}>
                  Clear location
                </button>
                <div
                  style={{
                    fontSize: '0.65rem',
                    display: 'flex',
                    alignItems: 'center',
                    color: '#7787D4',
                    fontWeight: 400
                  }}>
                  Or click the map to move the marker.
                </div>
              </div>
            )}
          </div>
        )}
        {editing && (
          <>
            <LatLongForm
              getFieldProps={getFieldProps}
              defaultValue={formData}
            />
            <AddressForm
              getFieldProps={getFieldProps}
              defaultValue={formData}
            />
            <button
              type='button'
              className='button is-text'
              onClick={() => clearLocation()}
              style={{
                color: '#7787D4',
                fontWeight: 400
              }}>
              Clear location
            </button>
          </>
        )}
      </PanelSection>
      <PanelSection title='2. Description'>
        <div className='field'>
          <label className='label has-text-info'>Name</label>
          <div className='control'>
            <div className='field'>
              <div className='control' style={{ fontSize: '0.9em' }}>
                {formData && incidentNameFromDescription(formData.description)}
              </div>
            </div>
          </div>
        </div>
        <TextAreaField
          name='description'
          label='Description'
          required={true}
          readOnly={submitting}
          {...getFieldProps()}
        />
      </PanelSection>
      <PanelSection title='3. Send to' className='dispatch-type-select'>
        {Object.entries(dispatchOrgs).map(([userTypeId, organizations]) => (
          <div className='dispatch-type-select-item' key={userTypeId}>
            <div className='dispatch-type-select-item-header'>
              <div className='dispatch-type-select-item-header-icon'>
                <i
                  className={iconClassName(userTypesById[userTypeId].icon)}
                  style={{ color: userTypesById[userTypeId].color }}
                />
              </div>
              <span>{userTypesById[userTypeId].name}</span>
            </div>
            <div className='dispatch-type-select-item-body'>
              {organizations.map(({ id, name }) => (
                <div key={id}>
                  <CheckboxField
                    name={`${ORGS_PREFIX}${id}`}
                    label={name}
                    disabled={submitting}
                    defaultChecked={id === profile.organization.id}
                    {...getFieldProps()}
                  />
                  {formData && formData[`${ORGS_PREFIX}${id}`] && (
                    <CheckboxField
                      name={`offduty:${ORGS_PREFIX}${id}`}
                      label='Include Off-Duty'
                      disabled={submitting}
                      defaultChecked={false}
                      {...getFieldProps()}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
        ))}
      </PanelSection>
      <div style={{ marginTop: '1em' }}>
        {error && (
          <div className='notification is-warning'>
            {error.dispatchType && 'Please select at least one dispatch type.'}
            {error.dispatchOrganizations &&
              'Please select at least one dispatch organization.'}
          </div>
        )}
        <Button
          type='submit'
          className='is-primary'
          style={{ float: 'right', marginRight: '1em' }}
          disabled={submitting}>
          {!submitting && 'Launch incident'}
          {submitting && (
            <span>
              <Spinner /> Creating
            </span>
          )}
        </Button>
      </div>
    </form>
  )
}
