import React, { useState } from 'react'

import StreamChat from '../../components/StreamChat'

import './styles.scss'

export default function Chat() {
  const [customActiveChannel, setCustomActiveChannel] = useState()

  return (
    <div className='chat-fullscreen'>
      <StreamChat
        customActiveChannel={customActiveChannel}
        setCustomActiveChannel={setCustomActiveChannel}
      />
    </div>
  )
}
