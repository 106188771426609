import React from 'react'
import PaginatedTable from '../PaginatedTable'
import DateTime from '../DateTime'
import { usePagination } from '../../hooks'

export default function ({ organizationId }) {
  const [rows, currentPage, totalCount, loading, setPage, setOpts, opts] =
    usePagination({
      endpoint: `admin/organizations/${organizationId}/data-retention-events`,
      itemsPerPage: 10,
      sortBy: 'id',
      sortOrder: 'asc',
      persistent: true,
      uid: `data-retention-events-${organizationId}`
    })

  const columns = [
    {
      name: 'ID',
      sortKey: 'id',
      render: (item) => item.id
    },
    {
      name: 'Timestamp',
      sortKey: 'createdAt',
      render: (item) => <DateTime value={item.createdAt} />
    },
    {
      name: 'Instance Type',
      sortKey: 'instanceType',
      render: (item) => item.instanceType
    },
    {
      name: 'Instance ID',
      sortKey: 'instanceId',
      render: (item) => item.instanceId
    }
  ]

  return (
    <div className='box'>
      <PaginatedTable
        className='is-fullwidth is-hoverable'
        columns={columns}
        rows={rows}
        currentPage={currentPage}
        totalCount={totalCount}
        loading={loading}
        setPage={setPage}
        opts={opts}
        setOpts={setOpts}
      />
    </div>
  )
}
