import React from 'react'
import { useNavigate } from 'react-router-dom'
import { usePermissions } from '../../hooks'

import ContextMenu from '../../components/ContextMenu'
import DropdownMenu, { DropdownItem } from '../../components/DropdownMenu'
import OrganizationsList from '../../components/OrganizationsList'

export default function () {
  const navigate = useNavigate()
  const createPermission = usePermissions('site', 'create')

  return (
    <div className='container'>
      <ContextMenu renderTitle={() => <h1 className='title'>Organizations</h1>}>
        <DropdownMenu label='Actions'>
          {createPermission && createPermission.granted && (
            <DropdownItem onClick={() => navigate('/organizations/create')}>
              Create a new organization
            </DropdownItem>
          )}
        </DropdownMenu>
      </ContextMenu>
      <OrganizationsList />
    </div>
  )
}
