import React, { useState, useRef, useCallback, useContext } from 'react'

export const NotificationsContext = React.createContext()

const NOTIFICATION_TIMEOUT = 10 * 1000

export function NotificationsProvider({ children }) {
  const [state, setState] = useState({})

  const nextId = useRef(0)

  const hideNotification = useCallback(
    (id) => {
      setState((s) => ({
        ...s,
        [id]: undefined
      }))
    },
    [setState]
  )

  const addNotification = useCallback(
    (component, opts) => {
      const id = nextId.current++

      setState((s) => ({
        ...s,
        [id]: { component, ...opts }
      }))

      setTimeout(() => hideNotification(id), NOTIFICATION_TIMEOUT)
    },
    [setState, hideNotification]
  )

  return (
    <NotificationsContext.Provider
      value={{ notifications: state, addNotification, hideNotification }}>
      {children}
    </NotificationsContext.Provider>
  )
}

export function useNotifications() {
  const { addNotification } = useContext(NotificationsContext)
  return addNotification
}
