import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useApi, useForm, useApiRequest, useError } from '../../hooks'
import DropdownField from '../Form/DropdownField'
import TextField from '../Form/TextField'
import Button from '../Button'
import ContextMenu from '../ContextMenu'
import Columns from '../Columns'
import Spinner from '../Spinner'

export default function ({ userId }) {
  const navigate = useNavigate()
  const [client] = useApi()
  const [user] = useApiRequest(`admin/users/${userId}`)
  let [response] = useApiRequest('admin/user-types?limit=100', null, [user])

  let userTypes
  if (response) {
    userTypes = response.items
  }

  const setError = useError()

  const [getFieldProps, formData, formError] = useForm({
    dependencies: [response]
  })

  if (!user) {
    return <Spinner />
  }

  async function handleSubmit(e) {
    e.preventDefault()

    try {
      await client(`admin/users/${user.id}/update-user-type`, {
        userTypeId: formData.userTypeId
      })

      navigate(`/users/${userId}`)
    } catch (err) {
      if (err.message === 'validation_error') {
        formError(err.props)
      } else {
        setError(err)
      }
    }
  }

  if (!userTypes) {
    return <Spinner />
  }

  return (
    <div className='box'>
      <form onSubmit={handleSubmit}>
        <Columns>
          <TextField
            label='Organization'
            value={user.organization.name}
            readOnly={true}
            required={true}
          />
          <TextField
            label='User'
            value={user.email}
            readOnly={true}
            required={true}
          />
        </Columns>
        <DropdownField
          name='userTypeId'
          label='User Type'
          required={true}
          defaultValue={user?.userType.id || 1}
          {...getFieldProps()}>
          {userTypes && <option />}
          {userTypes.map((userType) => (
            <option key={userType.id} value={userType.id}>
              {userType.name}
            </option>
          ))}
        </DropdownField>
        <ContextMenu>
          <Button type='submit'>Assign</Button>
        </ContextMenu>
      </form>
    </div>
  )
}
