import React from 'react'
import { useParams } from 'react-router-dom'
import SetUserType from '../../../components/SetUserType'

export default function () {
  const { id } = useParams()
  return (
    <div className='container'>
      <h1 className='title'>Change user type</h1>
      <SetUserType userId={id} />
    </div>
  )
}
