import React from 'react'
import { useLocation } from 'react-router-dom'

import CreateDispatchBinding from '../../../components/CreateDispatchBinding'

export default function () {
  const query = new URLSearchParams(useLocation().search)
  const organizationId = parseInt(query.get('organizationId'))

  return (
    <div className='container'>
      <h1 className='title'>Create a dispatch binding</h1>
      <CreateDispatchBinding organizationId={organizationId} />
    </div>
  )
}
