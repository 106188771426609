import React from 'react'
import StreamChat from '../../../StreamChat'

import './styles.scss'

export default function ({ customActiveChannel, setCustomActiveChannel }) {
  return (
    <div className='chat-container'>
      <StreamChat
        customActiveChannel={customActiveChannel}
        setCustomActiveChannel={setCustomActiveChannel}
      />
    </div>
  )
}
