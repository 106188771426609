/* eslint jsx-a11y/anchor-is-valid: 0 */

import React, { useState, useMemo, useCallback } from 'react'
import { useLocation, Link } from 'react-router-dom'
import { useApiRequest, useApi, useNotifications } from '../../hooks'
import { baseURL } from '../../api'
import { IconPickerItem } from '../Form/IconField'
import ToggleButton from '../ToggleButton'
// import Slider from '../Slider'
import { useMapSettings } from '../Dispatch/DispatchMap'

import logo from '../../logo.png'
import mapLayerRoads from './ic_default-2x.png'
import mapLayerSatellite from './ic_satellite-2x.png'
import mapLayerTerrain from './ic_terrain-2x.png'
import mapLayerTraffic from './ic_traffic-2x.png'

import './styles.scss'

function NavLink({ to, external, match, style, children, ...props }) {
  const pathname = useLocation().pathname
  const LinkClass = external ? 'a' : Link

  return (
    <LinkClass
      href={external && to}
      to={to}
      className={`navbar-item ${pathname.startsWith(match) ? 'is-active' : ''}`}
      {...props}>
      {children}
    </LinkClass>
  )
}

export default function () {
  const [isActive, setActive] = useState(false)
  const isDispatch = useLocation().pathname === '/dispatch'
  const [profile] = useApiRequest('admin/profile')
  const isAdmin = profile && profile.organization.id === 1
  const [chatNotifications, setChatNotifications] = useState(false)
  const [client] = useApi()
  const addNotification = useNotifications()

  const displayName = useMemo(
    () =>
      profile &&
      profile.personalData.find((p) => p.type.typeName === 'displayName'),
    [profile]
  )

  const [availability, reloadAvailability] = useApiRequest(
    'client/availability'
  )
  const [availabilityLoading, setAvailabilityLoading] = useState(false)

  const setAvailability = useCallback(
    async (status) => {
      setAvailabilityLoading(true)
      try {
        await client('client/availability', { status })
      } catch (err) {
        console.error(err)
      }
      await reloadAvailability()
      setAvailabilityLoading(false)
    },
    [setAvailabilityLoading, client, reloadAvailability]
  )

  const [mapSettings, setMapSettings] = useMapSettings()
  const [activeSubMenu, setActiveSubMenu] = useState()

  const setDefaultMapLayer = useCallback(
    (defaultMapType) => {
      setMapSettings({
        ...mapSettings,
        defaultMapType
      })
    },
    [setMapSettings, mapSettings]
  )

  const setShowTrafficLayer = useCallback(
    (showTrafficLayer) => {
      setMapSettings({
        ...mapSettings,
        showTrafficLayer
      })
    },
    [setMapSettings, mapSettings]
  )

  const setMapViewAsDefault = useCallback(() => {
    setMapSettings({
      ...mapSettings,
      defaultMapCenter: undefined,
      defaultMapZoom: undefined
    })

    addNotification(
      <div>
        <div>Current map view set as default.</div>
      </div>,
      { className: 'is-success' }
    )
  }, [setMapSettings, mapSettings, addNotification])

  return (
    <nav
      className={`navbar ${isDispatch ? 'is-link' : 'has-shadow'}`}
      role='navigation'
      aria-label='main navigation'>
      <div className='navbar-brand'>
        <div
          role='button'
          className='navbar-burger burger'
          onClick={() => setActive(!isActive)}
          aria-label='menu'
          aria-expanded='false'
          data-target='navbar'>
          <span aria-hidden='true'></span>
          <span aria-hidden='true'></span>
          <span aria-hidden='true'></span>
        </div>
        <span
          className={`navbar-item ${
            isDispatch ? 'has-text-white' : 'has-text-info'
          }`}
          style={{ pointerEvents: 'none', fontWeight: 800 }}>
          Live Alarm
        </span>
      </div>

      <div id='navbar' className='navbar-menu'>
        <div className='navbar-start'>
          <NavLink to='/organizations/list' match='/organizations'>
            Organizations
          </NavLink>
          <NavLink to='/sites/list' match='/sites'>
            Sites
          </NavLink>
          <NavLink to='/incidents/list' match='/incidents'>
            Incidents
          </NavLink>
          <NavLink to='/dispatch' match='/dispatch'>
            Ongoing
          </NavLink>
          {isAdmin && (
            <NavLink to='/system' match='/system'>
              System
            </NavLink>
          )}
        </div>

        <div className='navbar-end'>
          <NavLink external to={`${baseURL}/account?clientId=admin-app`}>
            <span className='icon'>
              <i className='fas fa-user-circle' />
            </span>
            <span>My Account</span>
          </NavLink>
          <NavLink
            external
            to={`${baseURL}/logout?redirect_url=${window.location.href}`}>
            <span className='icon'>
              <i className='fas fa-sign-out-alt' />
            </span>
            <span>Log out</span>
          </NavLink>
        </div>
      </div>

      <div className={`nav-menu ${isActive ? 'is-active' : ''}`}>
        <div className='nav-menu-close'>
          <button className='button' onClick={() => setActive(false)}>
            <i className='far fa-times-circle' />
          </button>
        </div>
        <div className='nav-item'>
          <img src={logo} className='nav-logo' alt='' />
          <div className='nav-brand'>
            <div className='nav-title'>Live Alarm</div>
            <div className='nav-subtitle'>by Lifeline Applications</div>
          </div>
        </div>
        {!activeSubMenu && (
          <>
            {profile && (
              <div className='nav-item'>
                <div>
                  <div style={{ fontWeight: 600 }}>My Info:</div>
                  <div>Username: {profile.email}</div>
                  {displayName && <div>Display name: {displayName.value}</div>}
                  <div className='nav-user-icon'>
                    User icon:{' '}
                    <IconPickerItem
                      icon={profile.userType.icon}
                      size={16}
                      color={profile.userType.color}
                    />
                  </div>
                  <div>Organization: {profile.organization.name}</div>
                  <div>User ID: {profile.id}</div>
                </div>
              </div>
            )}
            <div className='nav-item'>
              <div style={{ width: '100%' }}>
                <div style={{ fontWeight: 600 }}>My Settings:</div>
                <div className='nav-setting'>
                  <div className='nav-setting-icon'>
                    <i className='fa fa-user-check' />
                  </div>
                  <div className='nav-setting-label'>Duty Status</div>
                  <div className='nav-setting-value'>
                    <ToggleButton
                      value={availability && availability.status === 'on-duty'}
                      setValue={(value) =>
                        setAvailability(value ? 'on-duty' : 'off-duty')
                      }
                      loading={!availability || availabilityLoading}
                    />
                  </div>
                </div>
                <div className='nav-setting'>
                  <div className='nav-setting-icon'>
                    <i className='fa fa-comments' />
                  </div>
                  <div className='nav-setting-label'>Chat Notifications</div>
                  <div className='nav-setting-value'>
                    <ToggleButton
                      value={chatNotifications}
                      setValue={setChatNotifications}
                    />
                  </div>
                </div>
                <div className='nav-setting'>
                  <div className='nav-setting-icon'>
                    <i className='fa fa-address-card' />
                  </div>
                  <div className='nav-setting-label'>Show last name on map</div>
                  <div className='nav-setting-value'>
                    <ToggleButton
                      value={mapSettings.showLastName}
                      setValue={(showLastName) =>
                        setMapSettings({ ...mapSettings, showLastName })
                      }
                    />
                  </div>
                </div>
                <div
                  className='nav-setting clickable'
                  onClick={() => setMapViewAsDefault()}>
                  <div className='nav-setting-icon'>
                    <i className='fa fa-eye' />
                  </div>
                  <div className='nav-setting-label'>
                    Set map view as default
                  </div>
                  <div className='nav-setting-value'></div>
                </div>
                <div
                  className='nav-setting clickable'
                  onClick={() => setActiveSubMenu('map-layers')}>
                  <div className='nav-setting-icon'>
                    <i className='fa fa-layer-group' />
                  </div>
                  <div className='nav-setting-label'>Map Layers</div>
                  <div className='nav-setting-value'></div>
                </div>
                {/*<div className='nav-setting'>
              <div className='nav-setting-icon'>
                <i className='fa fa-map-marked' />
              </div>
              <div className='nav-setting-label'>Map Settings</div>
              <div className='nav-setting-value'></div>
                  </div>
                <div className='nav-setting'>
                  <div className='nav-setting-icon'>
                    <i className='fa fa-map-marker-alt' />
                  </div>
                  <div className='nav-setting-label'>Map Icons</div>
                  <div className='nav-setting-value'>
                    <Slider
                      min={1}
                      max={10}
                      onChange={(value) =>
                        setMapSettings({ ...mapSettings, iconSize: value })
                      }
                      defaultValue={iconSize}
                    />
                  </div>
                    </div>*/}
                <div
                  className='nav-setting clickable'
                  onClick={() =>
                    window.open('mailto:help@livealarm.app', '_blank').focus()
                  }>
                  <div className='nav-setting-icon'>
                    <i className='fa fa-envelope' />
                  </div>
                  <div className='nav-setting-label'>Need help? Email us.</div>
                  <div className='nav-setting-value'></div>
                </div>
                <div
                  className='nav-setting clickable'
                  onClick={() =>
                    window
                      .open('https://www.surveymonkey.com/r/VNJRTLY', '_blank')
                      .focus()
                  }>
                  <div className='nav-setting-icon'>
                    <i className='fa fa-comment-alt' />
                  </div>
                  <div className='nav-setting-label'>Submit feedback</div>
                  <div className='nav-setting-value'></div>
                </div>
                <div
                  className='nav-setting clickable'
                  onClick={() =>
                    window.location.replace(
                      `${baseURL}account?clientId=admin-app`
                    )
                  }>
                  <div className='nav-setting-icon'>
                    <i className='fa fa-user-cog' />
                  </div>
                  <div className='nav-setting-label'>Account Management</div>
                  <div className='nav-setting-value'></div>
                </div>
                <div
                  className='nav-setting clickable'
                  onClick={() =>
                    window.location.replace(
                      `${baseURL}/logout?redirect_url=${window.location.href}`
                    )
                  }>
                  <div className='nav-setting-icon'>
                    <i className='fa fa-sign-out-alt' />
                  </div>
                  <div className='nav-setting-label'>Log Out</div>
                  <div className='nav-setting-value'></div>
                </div>
              </div>
            </div>
          </>
        )}
        {activeSubMenu === 'map-layers' && (
          <div style={{ marginTop: '1rem' }}>
            <div>
              <i className='fa fa-layer-group' /> Map Layers
            </div>
            <div>
              <a href='#' onClick={() => setActiveSubMenu()}>
                Back
              </a>
            </div>
            <div className='map-layer-buttons'>
              <div
                className={`map-layer-button ${
                  mapSettings.defaultMapType === 'roads' && 'active'
                }`}
                onClick={() => setDefaultMapLayer('roads')}>
                <div
                  className='image'
                  style={{ backgroundImage: `url(${mapLayerRoads})` }}
                />
                <div>Default</div>
              </div>
              <div
                className={`map-layer-button ${
                  mapSettings.defaultMapType === 'satellite' && 'active'
                }`}
                onClick={() => setDefaultMapLayer('satellite')}>
                <div
                  className='image'
                  style={{ backgroundImage: `url(${mapLayerSatellite})` }}
                />
                <div>Satellite</div>
              </div>

              <div
                className={`map-layer-button ${
                  mapSettings.defaultMapType === 'terrain' && 'active'
                }`}
                onClick={() => setDefaultMapLayer('terrain')}>
                <div
                  className='image'
                  style={{ backgroundImage: `url(${mapLayerTerrain})` }}
                />
                <div>Terrain</div>
              </div>
            </div>
            <div className='map-layer-buttons'>
              <div
                className={`map-layer-button ${
                  mapSettings.showTrafficLayer && 'active'
                }`}
                onClick={() =>
                  setShowTrafficLayer(!mapSettings.showTrafficLayer)
                }>
                <div
                  className='image'
                  style={{ backgroundImage: `url(${mapLayerTraffic})` }}
                />
                <div>Traffic</div>
              </div>
            </div>
          </div>
        )}
      </div>
    </nav>
  )
}
