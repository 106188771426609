import React from 'react'
import { GoogleMap } from '@react-google-maps/api'

export { Marker } from '@react-google-maps/api'

const MAP_OPTIONS = {
  clickableIcons: false,
  disableDefaultUI: true
}

export default function ({ containerStyle, center, zoom, children }) {
  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      options={MAP_OPTIONS}
      center={center}
      zoom={zoom}>
      {children}
    </GoogleMap>
  )
}
