import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useApi, useForm, useError, usePagination } from '../../hooks'
import DropdownField from '../Form/DropdownField'
import TextField from '../Form/TextField'
import Button from '../Button'
import ContextMenu from '../ContextMenu'
import Columns from '../Columns'
import Spinner from '../Spinner'

export default function ({ user }) {
  const navigate = useNavigate()
  const [client] = useApi()

  let [userRoles] = usePagination({
    endpoint: `admin/organizations/${user.organization.id}/user-roles`,
    itemsPerPage: 100,
    sortBy: 'id',
    sortOrder: 'asc'
  })

  if (user && userRoles) {
    userRoles = userRoles.filter(
      (role) =>
        !user.roleBindings.find((binding) => binding.role.id === role.id)
    )
  }

  const setError = useError()

  const [getFieldProps, formData, formError] = useForm()

  if (!user) {
    return <Spinner />
  }

  async function handleSubmit(e) {
    e.preventDefault()

    try {
      await client(
        `admin/organizations/${user.organization.id}/user-role-bindings/create`,
        {
          userId: user.id,
          roleId: formData.roleId
        }
      )

      navigate(`/users/${user.id}`)
    } catch (err) {
      if (err.message === 'validation_error') {
        formError(err.props)
      } else {
        setError(err)
      }
    }
  }

  return (
    <div className='box'>
      <form onSubmit={handleSubmit}>
        <Columns>
          <TextField
            label='Organization'
            value={user.organization.name}
            readOnly={true}
            required={true}
          />
          <TextField
            label='User'
            value={user.email}
            readOnly={true}
            required={true}
          />
        </Columns>
        <DropdownField
          name='roleId'
          label='User role'
          required={true}
          {...getFieldProps()}>
          {userRoles && <option />}
          {userRoles &&
            userRoles.map((role) => (
              <option key={role.id} value={role.id}>
                {role.name}
              </option>
            ))}
          {!userRoles && <option>Loading...</option>}
        </DropdownField>
        <ContextMenu>
          <Button type='submit'>Assign</Button>
        </ContextMenu>
      </form>
    </div>
  )
}
