const unicodeMap = {}

export function iconClassName(icon) {
  const iconName = icon
    .slice(2)
    .match(/[A-Z][a-z]+/g)
    .map((c) => c.toLowerCase())
    .join('-')

  return `fas fa-${iconName}`
}

export function iconToUnicode(icon) {
  if (unicodeMap[icon]) {
    return unicodeMap[icon]
  }

  const el = document.createElement('i')
  el.className = iconClassName(icon)
  document.body.appendChild(el)

  const c = window.getComputedStyle(el, ':before').content.replace(/'|"/g, '')
  el.remove()

  unicodeMap[icon] = c
  return c
}
