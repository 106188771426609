import React, { useCallback, useContext, useReducer } from 'react'

import { useEventStream, useApiRequest } from '../../../hooks'
import Spinner from '../../Spinner'

export const DispatchDataContext = React.createContext({})

export function useDispatchData() {
  return useContext(DispatchDataContext)
}

const REINIT_STREAM_EVENT = 'reinit'
const DEVICE_INFO_EVENT = 'info'
const DEVICE_LOCATION_EVENT = 'loc'

function reduceLocations(state, { type, data }) {
  const { id, loc, user } = data || {}
  switch (type) {
    case REINIT_STREAM_EVENT:
      return {}
    case DEVICE_INFO_EVENT:
      return { ...state, [id]: { loc, user } }
    case DEVICE_LOCATION_EVENT:
      if (!state[id] || !state[id].user) {
        return { ...state }
      }

      return { ...state, [id]: { loc, user: state[id].user } }
    default:
      return { ...state }
  }
}

function useDeviceLocations() {
  const [deviceLocations, dispatch] = useReducer(reduceLocations, {})
  const reinitStream = useCallback(
    () => dispatch({ type: REINIT_STREAM_EVENT }),
    [dispatch]
  )
  useEventStream('locations', dispatch, reinitStream)
  return deviceLocations
}

const EXISTING_INCIDENTS_EVENT = 'exist'
const INCIDENT_CREATED_EVENT = 'new'
const INCIDENT_STATUS_CHANGED_EVENT = 'stat'
const DISPATCH_ACCEPTED_EVENT = 'accept'
const DISPATCH_CANCELED_EVENT = 'cancel'
const INCIDENT_ACKNOWLEDGED_EVENT = 'ack'
const INCIDENT_MARKER_CREATED_EVENT = 'mark'
const INCIDENT_MARKER_REMOVED_EVENT = 'unmark'

function reduceIncidents(state, { type, data }) {
  switch (type) {
    case REINIT_STREAM_EVENT:
      return {}
    case EXISTING_INCIDENTS_EVENT:
      return {
        ...state,
        ...data.items.reduce(
          (acc, incident) => ({
            ...acc,
            [incident.id]: {
              ...incident,
              dispatch: {}
            }
          }),
          {}
        )
      }
    case INCIDENT_CREATED_EVENT:
      return {
        ...state,
        [data.id]: {
          ...data,
          dispatch: {}
        }
      }
    case INCIDENT_STATUS_CHANGED_EVENT:
      return {
        ...state,
        [data.id]: {
          ...state[data.id]?.dispatch,
          ...data
        }
      }
    case DISPATCH_ACCEPTED_EVENT:
      return {
        ...state,
        [data.id]: {
          ...state[data.id],
          dispatch: {
            ...state[data.id]?.dispatch,
            [data.user.id]: {
              ...data.user,
              createdAt: data.createdAt
            }
          }
        }
      }
    case DISPATCH_CANCELED_EVENT:
      return {
        ...state,
        [data.id]: {
          ...state[data.id],
          dispatch: {
            ...state[data.id]?.dispatch,
            [data.user.id]: undefined
          }
        }
      }
    case INCIDENT_ACKNOWLEDGED_EVENT:
      return {
        ...state,
        [data.id]: {
          ...state[data.id],
          acknowledgements: [
            ...state[data.id].acknowledgements,
            {
              user: data.user,
              createdAt: data.createdAt
            }
          ]
        }
      }
    case INCIDENT_MARKER_CREATED_EVENT:
      return {
        ...state,
        [data.id]: {
          ...state[data.id],
          mapMarkers: [...(state[data.id]?.mapMarkers || []), data.mapMarker]
        }
      }
    case INCIDENT_MARKER_REMOVED_EVENT:
      return {
        ...state,
        [data.id]: {
          ...state[data.id],
          mapMarkers:
            state[data.id] &&
            state[data.id].mapMarkers.filter(
              ({ id }) => id !== data.mapMarker.id
            )
        }
      }
    default:
      return { ...state }
  }
}

function useIncidentsList() {
  const [incidents, dispatch] = useReducer(reduceIncidents, {})
  const reinitStream = useCallback(
    () => dispatch({ type: REINIT_STREAM_EVENT }),
    [dispatch]
  )
  useEventStream('incidents', dispatch, reinitStream)
  return incidents
}

const USER_AVAILABILITY_EVENT = 'user'

function reduceAvailability(state, { type, data }) {
  switch (type) {
    case REINIT_STREAM_EVENT:
      return {}
    case USER_AVAILABILITY_EVENT:
      return { ...state, [data.user.id]: data.user }
    default:
      return { ...state }
  }
}

function useAvailability() {
  const [availableUnits, dispatch] = useReducer(reduceAvailability, {})
  const reinitStream = useCallback(
    () => dispatch({ type: REINIT_STREAM_EVENT }),
    [dispatch]
  )
  useEventStream('availability', dispatch, reinitStream)
  return availableUnits
}

export default function ({ children }) {
  const deviceLocations = useDeviceLocations()
  const incidents = useIncidentsList()
  const availableUnits = useAvailability()

  const [userTypes] = useApiRequest(
    'admin/user-types?limit=250&sortBy=name&sortOrder=asc'
  )

  const [profile] = useApiRequest('admin/profile')
  const [dispatchBindings] = useApiRequest(
    `admin/organizations/${
      profile && profile.organization.id
    }/dispatch-bindings`,
    null,
    [profile]
  )

  if (!userTypes || !dispatchBindings || !profile) {
    return <Spinner />
  }

  const props = {
    deviceLocations,
    incidents,
    availableUnits,
    userTypes,
    dispatchBindings,
    profile
  }

  return (
    <DispatchDataContext.Provider value={props}>
      {React.cloneElement(children, props)}
    </DispatchDataContext.Provider>
  )
}
