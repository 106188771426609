import React from 'react'
import { useNavigate } from 'react-router-dom'
import { usePagination } from '../../hooks'
import PaginatedTable from '../PaginatedTable'
import DateTime from '../DateTime'

function capitalize(s) {
  return s[0].toUpperCase() + s.slice(1)
}

const columns = [
  {
    name: 'ID',
    sortKey: 'id',
    render: (item) => item.id
  },
  {
    name: 'Name',
    sortKey: 'name',
    render: (item) => item.name
  },
  {
    name: 'Status',
    sortKey: 'status',
    render: (item) => capitalize(item.status)
  },
  {
    name: 'Organization',
    render: (item) => (item.organization && item.organization.name) || 'N/A'
  },
  {
    name: 'Timestamp',
    sortKey: 'createdAt',
    render: (item) => <DateTime value={item.createdAt} />
  }
]

export default function () {
  const navigate = useNavigate()

  const [rows, currentPage, totalCount, loading, setPage, setOpts, opts] =
    usePagination({
      endpoint: 'admin/incidents',
      itemsPerPage: 10,
      sortBy: 'id',
      sortOrder: 'desc',
      persistent: true,
      uid: 'incidents-list'
    })

  return (
    <div>
      <div className='box'>
        <PaginatedTable
          className='is-fullwidth is-hoverable'
          columns={columns}
          rows={rows}
          currentPage={currentPage}
          totalCount={totalCount}
          loading={loading}
          setPage={setPage}
          opts={opts}
          setOpts={setOpts}
          onRowClick={(item) => navigate(`/incidents/${item.id}`)}
        />
      </div>
    </div>
  )
}
