import React from 'react'
import PaginatedTable from '../PaginatedTable'
import { useNavigate } from 'react-router-dom'
import { usePagination } from '../../hooks'

export default function () {
  const navigate = useNavigate()

  const [rows, currentPage, totalCount, loading, setPage, setOpts, opts] =
    usePagination({
      endpoint: 'admin/agents',
      itemsPerPage: 10,
      sortBy: 'id',
      sortOrder: 'asc',
      persistent: true,
      uid: 'agents-list'
    })

  const columns = [
    {
      name: 'ID',
      sortKey: 'id',
      render: (item) => item.id
    },
    {
      name: 'Name',
      sortKey: 'name',
      render: (item) => item.name
    },
    {
      name: 'Site',
      sortKey: 'site.name',
      render: (item) => item.site.name
    }
  ]

  return (
    <div className='box'>
      <PaginatedTable
        className='is-fullwidth is-hoverable'
        columns={columns}
        rows={rows}
        currentPage={currentPage}
        totalCount={totalCount}
        loading={loading}
        setPage={setPage}
        opts={opts}
        setOpts={setOpts}
        onRowClick={(item) => navigate(`/agents/${item.id}`)}
      />
    </div>
  )
}
