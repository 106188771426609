import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { useApiRequest } from '../../../hooks'
import AddDispatchOrganization from '../../../components/AddDispatchOrganization'
import Spinner from '../../../components/Spinner'

export default function () {
  const navigate = useNavigate()
  const query = new URLSearchParams(useLocation().search)
  const incidentId = parseInt(query.get('incidentId'))

  const [profile] = useApiRequest('admin/profile')
  const [dispatchBindings] = useApiRequest(
    `admin/organizations/${
      profile && profile.organization.id
    }/dispatch-bindings`,
    null,
    [profile]
  )

  if (!profile || !dispatchBindings) {
    return <Spinner />
  }

  return (
    <div className='container'>
      <h1 className='title'>Add responders</h1>
      <AddDispatchOrganization
        incidentId={incidentId}
        dispatchBindings={dispatchBindings}
        onDispatchOrganizationCreated={() =>
          navigate(`/incidents/${incidentId}`)
        }
      />
    </div>
  )
}
