import React from 'react'

import styles from './styles.module.css'

export default function ToggleButton({
  style,
  value,
  setValue,
  loading,
  onLabel,
  offLabel
}) {
  onLabel = onLabel || 'On'
  offLabel = offLabel || 'Off'

  return (
    <div
      style={style}
      className={`${styles.switch} ${value && styles.active} ${
        loading && styles.loading
      }`}
      onClick={() => !loading && setValue && setValue(!value)}>
      <div className={styles.label}>{value ? onLabel : offLabel}</div>
      <div className={styles.circle} />
    </div>
  )
}
