import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { StreamChat } from 'stream-chat'
import {
  Chat,
  Channel,
  ChannelList,
  ChannelListMessenger,
  ChannelHeader,
  LoadingIndicator,
  MessageInput,
  MessageList,
  Thread,
  TypingIndicator,
  Window,
  useChannelStateContext
} from 'stream-chat-react'

import 'stream-chat-react/dist/css/v2/index.css'

import ModalDialog from '../ModalDialog'
import TextField from '../Form/TextField'
import Button from '../Button'
import ContextMenu from '../ContextMenu'
import ChatUsersList from '../ChatUsersList'
import DateTime from '../DateTime'

import { useApi, useApiToken, useApiRequest, useForm } from '../../hooks'
import { httpClient } from '../../api'

import './styles.scss'

function ChatAttachment({ media, title, sentByUser, url, timestamp }) {
  return (
    <div className='chat-attachment'>
      <div className='media'>
        <a href={url} target='_blank' rel='noreferrer'>
          {media || <i className='fa fa-file' />}
        </a>
      </div>
      <div className='info'>
        <div>
          <p className='title'>
            <a href={url} target='_blank' rel='noreferrer'>
              {title}
            </a>
          </p>
        </div>
        <div>
          <p className='subtitle is-6'>Sent by {sentByUser.name}</p>
        </div>
        <div>
          <p className='subtitle is-6'>
            <DateTime value={timestamp} />
          </p>
        </div>
      </div>
    </div>
  )
}

function ImageAttachment({ message, attachment }) {
  return (
    <ChatAttachment
      media={
        <img
          alt={attachment.fallback}
          className='chat-img'
          src={attachment.image_url}
        />
      }
      title={attachment.title || attachment.fallback}
      sentByUser={message.user}
      url={attachment.image_url}
      timestamp={message.created_at}
    />
  )
}

function VideoAttachment({ message, attachment }) {
  return (
    <ChatAttachment
      media={
        <video className='chat-video'>
          <source src={attachment.asset_url} type={attachment.mime_type} />
        </video>
      }
      title={attachment.title || attachment.fallback}
      sentByUser={message.user}
      url={attachment.image_url}
      timestamp={message.created_at}
    />
  )
}

function OtherAttachment({ message, attachment }) {
  return (
    <ChatAttachment
      title={attachment.title || attachment.fallback}
      sentByUser={message.user}
      url={attachment.asset_url}
      timestamp={message.created_at}
    />
  )
}

function AttachmentsList() {
  const { channel } = useChannelStateContext()

  const attachments = useMemo(
    () =>
      channel.state.messages
        .filter(({ attachments }) => attachments.length !== 0)
        .map((message) => [
          ...message.attachments.map((attachment) => ({ message, attachment }))
        ])
        .flat(),
    [channel.state.messages]
  )

  return (
    <div className='chat-attachments'>
      {attachments.length === 0 && (
        <div style={{ marginTop: '1rem' }}>No attachments added yet.</div>
      )}
      {attachments
        .map(({ message, attachment }, index) => {
          switch (attachment.type) {
            case 'image':
              return (
                <ImageAttachment
                  key={index}
                  message={message}
                  attachment={attachment}
                />
              )
            case 'video':
              return (
                <VideoAttachment
                  key={index}
                  message={message}
                  attachment={attachment}
                />
              )
            default:
              return (
                <OtherAttachment
                  key={index}
                  message={message}
                  attachment={attachment}
                />
              )
          }
        })
        .filter((x) => x)}
    </div>
  )
}

function CustomChannelHeader({ title, onEditChannel, onViewAttachments }) {
  const { channel } = useChannelStateContext()

  return (
    <>
      <ChannelHeader />
      <div className='chat-header-addons'>
        <TypingIndicator />
        <div className='chat-edit-button' onClick={onViewAttachments}>
          <i className='fa fa-paperclip' />
        </div>
        {!channel.id.startsWith('incident_') && (
          <div className='chat-edit-button' onClick={onEditChannel}>
            <i className='fa fa-edit' />
          </div>
        )}
      </div>
    </>
  )
}

function ChannelListContainer(props) {
  return (
    <div style={{ overflowY: 'auto', height: '100%' }}>
      <ChannelListMessenger {...props} />
    </div>
  )
}

function CreateChannelForm({ onClose }) {
  const [getFieldProps, formData] = useForm()
  const [client] = useApi()
  const [selectedUserIds, setSelectedUserIds] = useState([])

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault()

      const { name } = formData

      try {
        const { channelId } = await client('chat/create-channel', {
          name,
          userIds: selectedUserIds
        })

        onClose(channelId)
      } catch (err) {
        console.error(err)
        onClose()
      }
    },
    [formData, onClose, client, selectedUserIds]
  )

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        label='Chat Room Subject'
        name='name'
        placeholder='Chat Room Subject'
        required={true}
        {...getFieldProps()}
      />
      <ChatUsersList onChange={setSelectedUserIds} />
      <ContextMenu>
        <Button type='submit'>Create</Button>
      </ContextMenu>
    </form>
  )
}

function EditChannelForm({ onClose }) {
  const [client] = useApi()
  const [selectedUserIds, setSelectedUserIds] = useState([])
  const { channel } = useChannelStateContext()

  const existingUserIds = useMemo(() => {
    return Object.values(channel.state.members).map(({ user_id }) =>
      parseInt(user_id.split('_')[1])
    )
  }, [channel.state.members])

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault()

      try {
        const userIdsToAdd = selectedUserIds.filter(
          (userId) => !existingUserIds.includes(userId)
        )

        if (userIdsToAdd.length !== 0) {
          await client('chat/add-users-to-channel', {
            channelId: channel.id,
            userIds: userIdsToAdd
          })
        }

        const userIdsToRemove = existingUserIds.filter(
          (userId) => !selectedUserIds.includes(userId)
        )
        if (userIdsToRemove.length !== 0) {
          await client('chat/remove-users-from-channel', {
            channelId: channel.id,
            userIds: userIdsToRemove
          })
        }

        onClose()
      } catch (err) {
        console.error(err)
        onClose()
      }
    },
    [onClose, channel, client, selectedUserIds, existingUserIds]
  )

  const deleteChannel = useCallback(async () => {
    if (!window.confirm('Are you sure you wish to delete this chat channel?')) {
      return
    }

    await client('chat/delete-channel', {
      channelId: channel.id
    })
    onClose()
  }, [channel, client, onClose])

  return (
    <form onSubmit={handleSubmit}>
      <ChatUsersList
        defaultSelectedIds={existingUserIds || []}
        onChange={setSelectedUserIds}
      />
      <ContextMenu>
        <Button type='button' onClick={deleteChannel} className='is-danger'>
          Delete channel
        </Button>
        <Button type='submit'>Save</Button>
      </ContextMenu>
    </form>
  )
}

export default function ({ customActiveChannel, setCustomActiveChannel }) {
  const [client] = useApi()
  const accessToken = useApiToken()
  const [profile] = useApiRequest('admin/profile')

  const [chatClient, setChatClient] = useState()
  const [filters, setFilters] = useState()
  const [createChannelModalActive, setCreateChannelModalActive] =
    useState(false)
  const [editChannelModalActive, setEditChannelModalActive] = useState(false)
  const [showAttachments, setShowAttachments] = useState(false)

  useEffect(() => {
    if (!profile) {
      return
    }

    let streamClient

    async function initClient() {
      const { apiKey, streamUserId, token } = await client('chat/get-token', {})

      streamClient = StreamChat.getInstance(apiKey)
      streamClient.setBaseURL('https://chat.stream-io-api.com')

      try {
        await streamClient.connectUser(
          {
            id: streamUserId,
            name: profile.personalData.find(
              ({ type: { typeName } }) => typeName === 'displayName'
            )?.value
          },
          token
        )

        setFilters({
          type: 'messaging',
          frozen: false,
          members: { $in: [streamUserId] }
        })
        setChatClient(streamClient)
      } catch (err) {
        console.error(err)
      }
    }

    initClient()
  }, [client, profile])

  const uploadFile = useCallback(
    async (file, channel) => {
      const {
        data: { sha256 }
      } = await httpClient.put('v1/resources/upload', file, {
        // onUploadProgress: (e) => setUploadProgress(e.loaded / e.total),
        headers: {
          authorization: `Bearer ${accessToken}`,
          'content-type': file.type
        },
        json: true,
        timeout: 0
      })

      const { publicUrl } = await client('chat/create-attachment', {
        channelId: channel.id,
        sha256,
        filename: file.name
      })

      return {
        file: publicUrl
      }
    },
    [client, accessToken]
  )

  if (!chatClient || !profile) {
    return <LoadingIndicator />
  }

  return (
    <Chat client={chatClient} theme='messaging light'>
      {createChannelModalActive && (
        <ModalDialog
          title='Create a new chat channel'
          active={true}
          onClose={() => setCreateChannelModalActive(false)}>
          <CreateChannelForm
            onClose={(channelId) => {
              if (channelId) {
                setCustomActiveChannel(channelId)
              }

              setCreateChannelModalActive(false)
            }}
          />
        </ModalDialog>
      )}

      <ChannelList
        List={ChannelListContainer}
        filters={filters}
        customActiveChannel={customActiveChannel}
      />
      <div className='chat-addons'>
        <div
          className='create-chat-channel'
          onClick={() => setCreateChannelModalActive(true)}>
          <i className='fa fa-plus' />
        </div>
        <a className='chat-external' href='/chat' target='_blank'>
          <i className='fa fa-external-link-alt' />
        </a>
      </div>
      <Channel>
        <Window>
          {editChannelModalActive && (
            <ModalDialog
              active={true}
              onClose={() => setEditChannelModalActive(false)}>
              <EditChannelForm
                onClose={() => setEditChannelModalActive(false)}
              />
            </ModalDialog>
          )}
          <CustomChannelHeader
            onEditChannel={setEditChannelModalActive}
            onViewAttachments={() => setShowAttachments(!showAttachments)}
          />
          {!showAttachments && (
            <>
              <MessageList />
              <MessageInput
                doFileUploadRequest={uploadFile}
                doImageUploadRequest={uploadFile}
              />
            </>
          )}
          {showAttachments && <AttachmentsList />}
        </Window>
        <Thread />
      </Channel>
    </Chat>
  )
}
