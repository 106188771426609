import React from 'react'

import CreateUserType from '../../../../components/CreateUserType'

export default function () {
  return (
    <div className='container'>
      <h1 className='title'>Create a new user type</h1>
      <CreateUserType />
    </div>
  )
}
