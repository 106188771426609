import React, { useCallback, useState } from 'react'
import PaginatedTable from '../PaginatedTable'
import DateTime from '../DateTime'
import ModalDialog from '../ModalDialog'
import { usePagination, useApi } from '../../hooks'

function capitalize(s) {
  return s[0].toUpperCase() + s.slice(1)
}

export default function () {
  const [client] = useApi()
  const [downloadError, setDownloadError] = useState(null)

  const downloadArchive = useCallback(
    async (id) => {
      try {
        const { downloadUrl } = await client(`admin/data-export/download/${id}`)

        window.open(downloadUrl, '_blank')
      } catch (err) {
        setDownloadError(err)
      }
    },
    [client, setDownloadError]
  )

  const downloadGpgKey = useCallback(
    async (keyFingerprint) => {
      const { armoredKey } = await client(
        `admin/data-export/gpg-key/${keyFingerprint}`
      )

      const url = URL.createObjectURL(
        new Blob([armoredKey], { type: 'text/plain' })
      )
      let a = window.document.createElement('a')
      a.href = url
      a.download = `livealarm_${keyFingerprint.slice(0, 8).toUpperCase()}.asc`
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    },
    [client]
  )

  const [rows, currentPage, totalCount, loading, setPage, setOpts, opts] =
    usePagination({
      endpoint: 'admin/data-export/jobs',
      itemsPerPage: 10,
      sortBy: 'id',
      sortOrder: 'desc',
      persistent: true,
      uid: 'data-export-jobs-list'
    })

  const columns = [
    {
      name: 'ID',
      sortKey: 'id',
      render: (item) => item.id
    },
    {
      name: 'Status',
      sortKey: 'status',
      render: (item) => capitalize(item.status)
    },
    {
      name: 'Incident',
      sortKey: 'incidentId',
      render: (item) => (item.incidentId ? `#${item.incidentId}` : 'ALL')
    },
    {
      name: 'Created At',
      sortKey: 'createdAt',
      render: (item) => <DateTime value={item.createdAt} />
    },
    {
      name: 'Completed At',
      sortKey: 'updatedAt',
      render: (item) =>
        ((item.status === 'ready' || item.status === 'downloaded') && (
          <DateTime value={item.updatedAt} />
        )) ||
        '-'
    },
    {
      name: 'Download',
      render: (item) => (
        <>
          {item.status === 'ready' && (
            <button
              className='button is-text'
              onClick={() => downloadArchive(item.id)}>
              Archive
            </button>
          )}
          {(item.status === 'ready' || item.status === 'downloaded') && (
            <button
              className='button is-text'
              onClick={() => downloadGpgKey(item.keyFingerprint)}>
              GPG Key
            </button>
          )}
        </>
      )
    }
  ]

  return (
    <div className='box'>
      <ModalDialog
        active={downloadError !== null}
        onClose={() => setDownloadError(null)}>
        <p>Download failed, reason: {downloadError && downloadError.message}</p>
      </ModalDialog>
      <PaginatedTable
        className='is-fullwidth is-hoverable'
        columns={columns}
        rows={rows}
        currentPage={currentPage}
        totalCount={totalCount}
        loading={loading}
        setPage={setPage}
        opts={opts}
        setOpts={setOpts}
      />
    </div>
  )
}
