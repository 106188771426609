import React from 'react'
import { useNavigate } from 'react-router-dom'

import ContextMenu from '../../components/ContextMenu'
import DropdownMenu, { DropdownItem } from '../../components/DropdownMenu'
import AgentsList from '../../components/AgentsList'

export default function () {
  const navigate = useNavigate()

  return (
    <div className='container'>
      <ContextMenu renderTitle={() => <h1 className='title'>Agents</h1>}>
        <DropdownMenu label='Actions'>
          <DropdownItem onClick={() => navigate('/agents/create')}>
            Create a new agent
          </DropdownItem>
        </DropdownMenu>
      </ContextMenu>
      <AgentsList />
    </div>
  )
}
