import React from 'react'
import { useNavigate } from 'react-router-dom'
import PaginatedTable from '../PaginatedTable'
import { usePagination } from '../../hooks'
import { IconPickerItem } from '../Form/IconField'

export default function ({ organizationId }) {
  const navigate = useNavigate()

  const [rows, currentPage, totalCount, loading, setPage, setOpts, opts] =
    usePagination({
      endpoint: `admin/organizations/${organizationId}/user-groups`,
      itemsPerPage: 10,
      sortBy: 'id',
      sortOrder: 'asc',
      persistent: true,
      uid: `user-groups-list-${organizationId}`
    })

  const columns = [
    {
      name: 'ID',
      sortKey: 'id',
      render: (item) => item.id
    },
    {
      name: 'Name',
      sortKey: 'name',
      render: (item) => item.name
    },
    {
      name: 'Icon',
      render: (item) => (
        <IconPickerItem icon={item.icon} size={24} color={item.color} />
      )
    }
  ]

  return (
    <PaginatedTable
      className='is-fullwidth is-hoverable'
      columns={columns}
      rows={rows}
      currentPage={currentPage}
      totalCount={totalCount}
      loading={loading}
      setPage={setPage}
      opts={opts}
      setOpts={setOpts}
      onRowClick={(item) =>
        navigate(`/user-groups/${item.id}?organizationId=${organizationId}`)
      }
    />
  )
}
