/* eslint jsx-a11y/anchor-is-valid: 0 */

import React, { useCallback, useEffect, useMemo } from 'react'
import DateTime from '../../../DateTime'
import { useApi } from '../../../../hooks'
import IncidentDetailsPanel from '../IncidentDetails'
import UserDetails from '../../../UserDetails'
import CreateIncidentAttachment from '../../../CreateIncidentAttachment'
import AddDispatchOrganization from '../../../AddDispatchOrganization'
import CreateIncidentMapMarker from '../CreateIncidentMapMarker'
import Button from '../../../Button'

import './styles.scss'

function Incident({
  incident,
  selectIncident,
  isSelected,
  openDetails,
  openUserDetails,
  profile
}) {
  const { name, createdAt, user, acknowledgements } = incident
  const acknowledgedBy = acknowledgements[0]?.user
  const currentDispatch = useMemo(() => {
    return Object.values(incident.dispatch)
      .filter((x) => x)
      .find(({ id }) => id === profile.id)
  }, [incident, profile])

  return (
    <div
      className={`card incident-card ${isSelected && 'active'}`}
      onClick={() => selectIncident(incident)}>
      <div className='card-content'>
        <div
          className='content'
          style={{ display: 'flex', flexDirection: 'row' }}>
          <div
            style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <span>{name}</span>
            {user && (
              <>
                <span>
                  Created by{' '}
                  <strong>
                    <a onClick={() => openUserDetails(user)}>
                      {user.personalData?.displayName?.value ||
                        `User #${user.id}`}
                    </a>
                  </strong>
                </span>
              </>
            )}
            {acknowledgedBy && (
              <>
                <span>
                  Acknowledged by{' '}
                  <strong>
                    <a onClick={() => openUserDetails(acknowledgedBy)}>
                      {acknowledgedBy.personalData?.displayName?.value ||
                        `User #${acknowledgedBy.id}`}
                    </a>
                  </strong>
                </span>
              </>
            )}
            <span>
              <DateTime value={createdAt} />
            </span>
            {currentDispatch && (
              <span style={{ color: '#3FC083' }}>Accepted</span>
            )}
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Button className='is-link' onClick={() => openDetails(incident)}>
              DETAILS
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default function ({
  incidents,
  setActivePanel,
  openSecondaryPanel,
  setMapCenter,
  setMapZoom,
  mapSelection,
  dispatchBindings,
  profile,
  setCustomActiveChannel,
  selectedIncident,
  setSelectedIncident,
  setDirectionsDestination,
  userTypes
}) {
  const [client] = useApi()

  const showAllOnMap = useCallback(() => {
    setSelectedIncident(null)
    setMapCenter(null)
  }, [setSelectedIncident, setMapCenter])

  useEffect(() => {
    if (selectedIncident) {
      const location = selectedIncident.locations.find(
        (loc) => loc.format === 'lat-long'
      )
      if (location) {
        setMapCenter({
          lng: location.longitude,
          lat: location.latitude
        })
      }
    }
  }, [selectedIncident, setMapCenter])

  const sorted = Object.values(incidents)
    .filter((incident) => incident.status === 'in-progress')
    .map((incident) => ({
      ...incident,
      createdAt: new Date(incident.createdAt)
    }))
    .sort((a, b) => b.createdAt - a.createdAt)

  const openDetails = useCallback(
    (incident) =>
      openSecondaryPanel(
        <IncidentDetailsPanel
          incident={incident}
          openSecondaryPanel={openSecondaryPanel}
          mapSelection={mapSelection}
          dispatchBindings={dispatchBindings}
          profile={profile}
          setCustomActiveChannel={setCustomActiveChannel}
          setActivePanel={setActivePanel}
          setDirectionsDestination={setDirectionsDestination}
          userTypes={userTypes}
        />,
        incident.name
      ),
    [
      openSecondaryPanel,
      mapSelection,
      dispatchBindings,
      profile,
      setCustomActiveChannel,
      setActivePanel,
      setDirectionsDestination,
      userTypes
    ]
  )

  const openUserDetails = useCallback(
    (user) =>
      openSecondaryPanel(
        <UserDetails id={user.id} />,
        user.personalData?.displayName?.value || `User #${user.id}`
      ),
    [openSecondaryPanel]
  )

  const addAttachment = useCallback(
    (incidentId) =>
      openSecondaryPanel(
        <CreateIncidentAttachment
          incidentId={incidentId}
          onAttachmentCreated={() =>
            openSecondaryPanel(
              <IncidentDetailsPanel
                incident={incidents[incidentId]}
                openSecondaryPanel={openSecondaryPanel}
                mapSelection={mapSelection}
                dispatchBindings={dispatchBindings}
              />
            )
          }
        />,
        'Create an attachment'
      ),
    [openSecondaryPanel, incidents, dispatchBindings, mapSelection]
  )

  const addMarker = useCallback(
    (incidentId) =>
      openSecondaryPanel(
        <CreateIncidentMapMarker
          incidentId={incidentId}
          mapSelection={mapSelection}
          onMarkerCreated={() => openSecondaryPanel(null)}
        />,
        'Create a map marker'
      ),
    [openSecondaryPanel, mapSelection]
  )

  const addDispatchOrganization = useCallback(
    (incidentId) =>
      openSecondaryPanel(
        <AddDispatchOrganization
          incidentId={incidentId}
          dispatchBindings={dispatchBindings}
          onDispatchOrganizationCreated={() => openSecondaryPanel(null)}
        />,
        'Add responders'
      ),
    [openSecondaryPanel, dispatchBindings]
  )

  const cancelIncident = useCallback(
    async (incidentId) => {
      try {
        if (window.confirm('Are you sure you wish to cancel this incident?')) {
          await client(`admin/incidents/${incidentId}/status`, {
            status: 'ended'
          })
        }
      } catch (err) {
        console.error(err)
      }
    },
    [client]
  )

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        margin: '0.5em',
        width: '100%',
        position: 'relative'
      }}>
      <div
        className={`card incident-card ${
          selectedIncident === null && 'active'
        }`}
        onClick={() => showAllOnMap()}>
        <div className='card-content'>
          <div className='content'>
            <Button
              className='is-link is-fullwidth'
              onClick={() => showAllOnMap()}>
              SHOW ALL ON MAP
            </Button>
          </div>
        </div>
      </div>
      {sorted.map((incident) => (
        <Incident
          key={incident.id}
          incident={incident}
          isSelected={selectedIncident?.id === incident.id}
          selectIncident={setSelectedIncident}
          openDetails={openDetails}
          openUserDetails={openUserDetails}
          addAttachment={addAttachment}
          addMarker={addMarker}
          addDispatchOrganization={addDispatchOrganization}
          cancelIncident={cancelIncident}
          setMapCenter={setMapCenter}
          mapSelection={mapSelection}
          profile={profile}
        />
      ))}
      {sorted.length !== 0 && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            flexDirection: 'column',
            paddingBottom: '2rem'
          }}>
          <div style={{ fontStyle: 'italic', fontWeight: 600 }}>
            Click on an incident to select it.
          </div>
        </div>
      )}
      <div className='add-incident-btn'>
        <Button
          onClick={() => setActivePanel('new-incident')}
          icon='fa fa-plus'
          className='is-link'
          style={{
            width: '62px',
            height: '62px',
            fontSize: '2rem'
          }}></Button>
      </div>
    </div>
  )
}
