import React from 'react'

export default function ({
  label,
  children,
  renderAddons,
  className,
  ...props
}) {
  return (
    <div className='field' {...props}>
      <label className='label has-text-info'>{label}</label>
      <div className={`control ${className || ''}`}>
        <div className={`field ${renderAddons ? 'has-addons' : ''}`}>
          <div className='control is-expanded'>{children}</div>
          <div className='control'>{renderAddons && renderAddons()}</div>
        </div>
      </div>
    </div>
  )
}
