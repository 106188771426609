import React from 'react'
import { useParams } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

import EditUserRole from '../../../components/EditUserRole'

export default function () {
  const { id } = useParams()
  const query = new URLSearchParams(useLocation().search)
  const organizationId = parseInt(query.get('organizationId'))

  return (
    <div className='container'>
      <EditUserRole id={parseInt(id)} organizationId={organizationId} />
    </div>
  )
}
