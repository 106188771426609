import React from 'react'
// import { useNavigate } from 'react-router-dom'

import ContextMenu from '../../components/ContextMenu'
// import DropdownMenu, { DropdownItem } from '../../components/DropdownMenu'
import IncidentsList from '../../components/IncidentsList'

export default function () {
  // const navigate = useNavigate()

  return (
    <div className='container'>
      <ContextMenu renderTitle={() => <h1 className='title'>Incidents</h1>}>
        {/*<DropdownMenu label='Actions'>
          <DropdownItem onClick={() => navigate('/incidents/create')}>
            Create a new incident
          </DropdownItem>
  </DropdownMenu>*/}
      </ContextMenu>
      <IncidentsList />
    </div>
  )
}
