import React from 'react'

export default function ({ children, slice = 3 }) {
  if (!Array.isArray(children)) {
    return children
  }

  if (children.length === 1) {
    return children[0]
  }

  children = children.filter((c) => c).flat()

  let columns = []

  for (let i = 0; i < children.length; i += slice) {
    columns.push(
      <div className='columns' key={i}>
        {children.slice(i, i + slice).map((child, index) => (
          <div className='column' key={i * slice + index}>
            {child}
          </div>
        ))}
      </div>
    )
  }

  return columns
}
