import React from 'react'

export default function ({ label, children, className, ...rest }) {
  return (
    <div>
      <label className='has-text-weight-bold has-text-info'>{label}</label>
      <div className={`is-family-monospace ${className || ''}`} {...rest}>
        {children}
      </div>
    </div>
  )
}
