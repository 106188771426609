import React from 'react'

import Dispatch from '../../components/Dispatch'
import DispatchDataWrapper from '../../components/Dispatch/DispatchDataWrapper'

export default function () {
  return (
    <DispatchDataWrapper>
      <Dispatch />
    </DispatchDataWrapper>
  )
}
