import React from 'react'

import { useApi, useForm, useError } from '../../hooks'
import DropdownField from '../Form/DropdownField'
import Button from '../Button'
import ContextMenu from '../ContextMenu'

export const STATUS_TYPES = ['pending', 'in-progress', 'ended']

function capitalize(s) {
  return `${s.slice(0, 1).toUpperCase()}${s.slice(1)}`
}

export default function ({ incident, onFinishEditing }) {
  const [client] = useApi()
  const setError = useError()

  const [getFieldProps, formData, formError] = useForm()

  async function handleSubmit(e) {
    e.preventDefault()

    try {
      await client(`admin/incidents/${incident.id}/status`, formData)
      onFinishEditing()
    } catch (err) {
      if (err.message === 'validation_error') {
        formError(err.props)
      } else {
        setError(err)
      }
    }
  }

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <DropdownField
          name='status'
          label='Status'
          defaultValue={incident.status}
          required={true}
          {...getFieldProps()}>
          {STATUS_TYPES.map((type) => (
            <option value={type} key={type}>
              {capitalize(type)}
            </option>
          ))}
        </DropdownField>
        <ContextMenu>
          <Button type='submit'>Update</Button>
        </ContextMenu>
      </form>
    </div>
  )
}
