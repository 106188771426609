import React, { useState } from 'react'

import Control from '../Control'

export default React.forwardRef(
  ({ label, onChange, getValidationError, ...props }, ref) => {
    const error = getValidationError && getValidationError(props.name)
    const [file, setFile] = useState()

    function handleChange(e) {
      const value = e.target.files[0]
      setFile(value)
      onChange(e)
    }

    return (
      <Control label={label}>
        <div
          className={`file has-name is-fullwidth ${error ? 'is-danger' : ''}`}>
          <label className='file-label'>
            <input
              className='file-input'
              type='file'
              {...props}
              onChange={handleChange}
              ref={ref}
            />
            <span className='file-cta'>
              <span className='file-icon'>
                <i className='fas fa-upload' />
              </span>
              <span className='file-label'>Select file</span>
            </span>
            <span className='file-name'>{file && file.name}</span>
          </label>
        </div>
        {error && <p className='help is-danger'>{error}</p>}
      </Control>
    )
  }
)
